import React, { useEffect, useState } from "react";
import * as IoIcons from "react-icons/io";
import * as HiIcons from "react-icons/hi";
// import demoimg from "../../assets/haider.jpg";
import "../UserDashboard.css";
import car from "../../../assets/car.png";
import search from "../../../assets/search.png";
import { useHistory } from "react-router-dom";
import PreLoader from "../../PreLoader/PreLoader";
import Language from "../../Language/Language";
import api from "../../../library/api";
import {
  clients_url,
  driver_url,
  company_url,
  partner_url,
  contractor_url,
} from "../../../library/Url";

const ContractorDashboard = () => {
  const [data, setdata] = useState("");
  const [imgurl, setimgurl] = useState("");
  const history = useHistory();
  const [language, setlanguage] = useState("");
  const [pageContent, setPageContent] = useState({});

  /* HOOKS */
  useEffect(() => {
    const lang = localStorage.getItem("language");
    setlanguage(lang);
  }, []);

  useEffect(() => {
    if (language === "En") {
      setPageContent({
        btn_1: "Place New Order+",
        btn_2: "Register Own Vehicle",
        lab_not_company:
          "Please Connect With Any Company Or Register Your Own Vehicle First To Get Orders",
        lab_order: "My Orders",
        btn_1_order: "Completed",
        btn_2_order: "Active",
        btn_3_order: "Offers",
        btn_4_order: "Cancelled",
        lab_welcome: "Welcome Back",
        search_order: "Search Orders",
        track_vehicle: "Track Vehicles",
      });
    } else if (language === "Ur") {
      setPageContent({
        btn_1: "نیا آرڈر کریں+",
        btn_2: "اپنی گاڑی رجسٹر کروائیں",
        lab_not_company:
          "براہ کرم کسی بھی کمپنی سے جڑیں یا آرڈر حاصل کرنے کے لیے پہلے اپنی گاڑی رجسٹر کریں",
        lab_order: "میرے آرڈر",
        btn_1_order: "مکمل",
        btn_2_order: "فعال",
        btn_3_order: "نئے آرڈر",
        btn_4_order: "منسوخ",
        lab_welcome: "خوش آمدید",
        search_order: "آرڈرز تلاش کریں",
        track_vehicle: "گاڑیوں کی ٹریکنگ",
      });
    }
  }, [language]);
  // State For Button Classes
  const [btnclass1, setbtnClass1] = useState("unselected-btn-dash");
  const [btnclass2, setbtnClass2] = useState("unselected-btn-dash");
  const [btnclass3, setbtnClass3] = useState("unselected-btn-dash");

  useEffect(() => {
    api.get("userinfo").then((response) => {
      setdata(response.data.data.user);
      if (response.data.data.user.user_is === "client") {
        setimgurl(clients_url);
      } else if (response.data.data.user.user_is === "company") {
        setimgurl(company_url);
      } else if (response.data.data.user.user_is === "contractor") {
        setimgurl(contractor_url);
      } else if (response.data.data.user.user_is === "driver") {
        setimgurl(driver_url);
      } else if (response.data.data.user.user_is === "partner") {
        setimgurl(partner_url);
      }
    });
  }, []);

  const handleBtnClick1 = () => {
    setbtnClass1("selected-btn-dash");
    setbtnClass2("unselected-btn-dash");
    setbtnClass3("unselected-btn-dash");
  };
  const handleBtnClick2 = () => {
    setbtnClass2("selected-btn-dash");
    setbtnClass1("unselected-btn-dash");
    setbtnClass3("unselected-btn-dash");
  };
  const handleBtnClick3 = () => {
    setbtnClass3("selected-btn-dash");
    setbtnClass2("unselected-btn-dash");
    setbtnClass1("unselected-btn-dash");
  };

  return (
    <>
      {data === "" ? (
        <PreLoader text="Loading..." />
      ) : (
        <div
          className="container"
          style={{ position: "relative", minHeight: "100vh" }}
        >
          <div className="row main-div-dashboard">
            <span className="lan-dropdown" style={{ zIndex: "30" }}>
              <Language value={setlanguage} curr={language} />
            </span>
            <span className="back-btn">
              <IoIcons.IoIosArrowRoundBack
                fill="#fff"
                style={{ width: "89px", height: "60px" }}
                // onClick={() => alert("Clicked back")}
              />
            </span>
            <HiIcons.HiDotsVertical
              fill="#fff"
              style={{ height: "27px", width: "70px", zIndex: "20" }}
              className="menu-btn"
              onClick={() => history.push("/user/setting")}
            />
            <div className="col-12">
              <div className="row">
                <div className="col-12 text-center">
                  <img
                    src={`${imgurl}${data.picture}`}
                    alt=""
                    className="dash-img mt-3"
                  />
                  <div className="">
                    <span className="h6 font-weight-bold text-white">
                      {data.name}
                    </span>{" "}
                    <br />
                    <br />
                    <button
                      className="place-order-btn"
                      onClick={() => history.push("/user/placeorder")}
                      style={{ marginTop: "-40px" }}
                    >
                      {pageContent.btn_1}
                    </button>
                    <button
                      className="place-order-btn"
                      onClick={() => history.push("/register/truck")}
                    >
                      {pageContent.btn_2}
                    </button>
                    {/* My Orders Start */}
                    <div className="card order-btn-container">
                      {language === "Ur" ? (
                        <span
                          className="order-div-content text-right"
                          style={{ marginLeft: "60%" }}
                        >
                          {pageContent.lab_order}
                        </span>
                      ) : (
                        <span className="order-div-content">
                          {pageContent.lab_order}
                        </span>
                      )}
                      <br />
                      <div className="row">
                        <div className="col-12">
                          <div
                            className="ml-2 mt-4"
                            style={{ marginBottom: "10px" }}
                          >
                            <button
                              className={btnclass1}
                              onClick={handleBtnClick1}
                            >
                              {pageContent.btn_1_order}
                            </button>
                            <button
                              className={btnclass2}
                              onClick={handleBtnClick2}
                            >
                              {pageContent.btn_2_order}
                            </button>
                            <button
                              className={btnclass3}
                              onClick={handleBtnClick3}
                            >
                              {pageContent.btn_3_order}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* My Order Ends */}
                    {/* Order Details Start */}
                    <div className="card order-details-container">
                      <div className="row mt-2">
                        <div className="col-7">
                          <span className="order-detail-div-content ml-2">
                            Fmc Pvt Ltd{" "}
                            <span style={{ color: "#31b004" }}>/ Company</span>
                          </span>
                        </div>
                        <div className="col-5">
                          <span className="order-id">#89382938</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <span className="shipping-detail">
                            Shipment from Islamabad to Karachi Port
                          </span>
                        </div>
                      </div>
                      <div className="order-line"></div>
                      <div className="row">
                        <div className="col-5 ml-3">
                          <span className="requirement-veh">
                            Vehicles Requested: 8
                          </span>
                        </div>
                        <div className="col-6">
                          <span className="time-veh">
                            26 July 8:00 am - 12:00 pm
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* Order Details End */}
                    <div className="card order-details-container">
                      <div className="row mt-2">
                        <div className="col-7">
                          <span className="order-detail-div-content ml-2">
                            Fmc Pvt Ltd{" "}
                            <span style={{ color: "#31b004" }}>/ Company</span>
                          </span>
                        </div>
                        <div className="col-5">
                          <span className="order-id">#89382938</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <span className="shipping-detail">
                            Shipment from Islamabad to Karachi Port
                          </span>
                        </div>
                      </div>
                      <div className="order-line"></div>
                      <div className="row">
                        <div className="col-5 ml-3">
                          <span className="requirement-veh">
                            Vehicles Requested: 8
                          </span>
                        </div>
                        <div className="col-6">
                          <span className="time-veh">
                            26 July 8:00 am - 12:00 pm
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="card order-details-container">
                      <div className="row mt-2">
                        <div className="col-7">
                          <span className="order-detail-div-content ml-2">
                            Fmc Pvt Ltd{" "}
                            <span style={{ color: "#31b004" }}>/ Company</span>
                          </span>
                        </div>
                        <div className="col-5">
                          <span className="order-id">#89382938</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <span className="shipping-detail">
                            Shipment from Islamabad to Karachi Port
                          </span>
                        </div>
                      </div>
                      <div className="order-line"></div>
                      <div className="row">
                        <div className="col-5 ml-3">
                          <span className="requirement-veh">
                            Vehicles Requested: 8
                          </span>
                        </div>
                        <div className="col-6">
                          <span className="time-veh">
                            26 July 8:00 am - 12:00 pm
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* End Orders */}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ height: "100px", border: "2px solid white" }}></div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card bottom-search-car">
                <div className="row mt-3">
                  <div
                    className="col-7"
                    style={{ marginLeft: "8px" }}
                    onClick={() => {
                      history.push("/user/offer");
                    }}
                  >
                    <img
                      src={search}
                      alt=""
                      style={{ height: "27px", width: "28px" }}
                    />
                    <span style={{ fontSize: "13px", marginLeft: "10px" }}>
                      {pageContent.search_order}
                    </span>
                  </div>
                  <div
                    className="col-5"
                    style={{ textAlign: "end", marginLeft: "-18px" }}
                    onClick={() => history.push("/user/tracking/")}
                  >
                    <img
                      src={car}
                      alt=""
                      style={{ height: "27px", width: "28px" }}
                    />
                    <span
                      style={{
                        fontSize: "13px",
                        marginLeft: "8px",
                        marginRight: "8px",
                      }}
                    >
                      {pageContent.track_vehicle}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContractorDashboard;
