import React, { useState, useEffect } from "react";
import car from "../../../assets/car.png";
import search from "../../../assets/search.png";
import * as IoIcons from "react-icons/io";
import * as HiIcons from "react-icons/hi";
import "./Offers.css";
import { useHistory } from "react-router-dom";
import Language from "../../Language/Language";
import api from "../../../library/api";
import PreLoader from "../../PreLoader/PreLoader";

const Offers = (props) => {
  const history = useHistory();
  const [language, setlanguage] = useState("");
  const [pageContent, setPageContent] = useState({});
  const [data, setdata] = useState([]);

  /* HOOKS */
  useEffect(() => {
    const lang = localStorage.getItem("language");
    setlanguage(lang);

    if(props.location?.state?.requested_vehicle !== undefined){
      api.get("order/get", {params : {requested_vehicle : props.location.state.requested_vehicle}}).then((res) => setdata(res.data));
    }
    else{
      api.get("order/get").then((res) => setdata(res.data));
    }
  }, []);

  useEffect(() => {
    if (language === "En") {
      setPageContent({
        opend_offer: "Opened Offer",
        selected_location: "Selected Location",
        search_new_order: "Search for new order",
        search_order: "Search Orders",
        track_vehicle: "Track Vehicles",
      });
    } else if (language === "Ur") {
      setPageContent({
        opend_offer: "مواقع",
        selected_location: "مقام منتخب کریں",
        search_new_order: "نیا آرڈر تلاش کریں",
        search_order: "آرڈرز تلاش کریں",
        track_vehicle: "گاڑیوں کی ٹریکنگ",
      });
    }
  }, [language]);
  return (
    <>
      {data === "" ? (
        <PreLoader />
      ) : (
        <div className="container">
          <div className="row main-div-dashboard-offer">
            <span className="lan-dropdown" style={{ zIndex: "30" }}>
              <Language value={setlanguage} curr={language} />
            </span>
            <div className="col-12" style={{ zIndex: "20" }}>
              <span className="back-btn">
                <IoIcons.IoIosArrowRoundBack
                  fill="#fff"
                  style={{ width: "89px", height: "60px", zIndex: "10" }}
                  onClick={() => history.push("/user/dashboard/")}
                />
              </span>
              <HiIcons.HiDotsVertical
                fill="#fff"
                style={{ height: "27px", width: "70px" }}
                className="menu-btn"
              />
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-12 text-center mt-4">
                  <div className="mt-5 d-flex text-center">
                    <input
                      type="text"
                      className="text-field-search"
                      placeholder={pageContent.search_new_order}
                    ></input>
                    <span className="search-order"></span>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                {/* <div className="col-6 text-center">
                  <span className="text-white font-weight-bold">
                    {pageContent.opend_offer}
                  </span>
                </div>
                <div className="col-6 text-center">
                  <span className="text-white font-weight-bold">
                    {pageContent.selected_location}
                  </span>
                </div> */}
              </div>
            </div>
          </div>

          {data.map((dat, index) => {
            return (
              <div
                className="card order-details-container-offer"
                onClick={() => {
                  localStorage.setItem("Order_Id", dat.id);
                  history.push("/user/bid");
                }}
                key={index}
              >
                <div className="row mt-2">
                  <div className="col-7">
                    <span className="order-detail-div-content-offer ml-3">
                      {dat.title}
                    </span>
                  </div>
                  <div className="col-5 text-right pr-5">
                    <span className="order-id-offer ">{dat.id}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-8">
                    <p className="shipping-detail-offer text-start pl-2">
                      {dat.description}
                    </p>
                  </div>
                  <div className="col-4">
                    <span className="readmore">Read more...</span>
                  </div>
                </div>
                <div className="order-line"></div>
                <div className="row">
                  <div className="col-6 pl-5">
                    <span className="requirement-veh">
                      Vehicles Requested: {dat.requested_vehicle}
                    </span>
                  </div>
                  <div className="col-6 pl-5">
                    <span className="time-veh">
                      <b>From Time : </b>
                      {dat.time_from}
                      <br />
                      <b>To Time : </b>
                      {dat.time_to}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
          <div style={{ height: "100px", border: "2px solid white" }}></div>

          <div className="row">
            <div className="col-11">
              <div className="card bottom-search-car">
                <div className="row mt-3">
                  <div className="col-7" style={{ marginLeft: "8px" }}>
                    <img
                      src={search}
                      alt=""
                      style={{ height: "27px", width: "28px" }}
                    />
                    <span style={{ fontSize: "13px", marginLeft: "10px" }}>
                      {pageContent.search_order}
                    </span>
                  </div>
                  <div
                    className="col-5"
                    style={{ textAlign: "end", marginLeft: "-18px" }}
                    onClick={() => history.push("/user/tracking/")}
                  >
                    <img
                      src={car}
                      alt=""
                      style={{ height: "27px", width: "28px" }}
                    />
                    <span
                      style={{
                        fontSize: "13px",
                        marginLeft: "8px",
                        marginRight: "8px",
                      }}
                    >
                      {pageContent.track_vehicle}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Offers;
