import React, { useEffect, useState } from "react";
import * as IoIcons from "react-icons/io";
import * as HiIcons from "react-icons/hi";
import * as TiIcons from "react-icons/ti";
import * as ImIcons from "react-icons/im";
// import demoimg from "../../assets/haider.jpg";
import "../UserDashboard.css";
import car from "../../../assets/car.png";
import search from "../../../assets/search.png";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import PreLoader from "../../PreLoader/PreLoader";
import Language from "../../Language/Language";
import api from "../../../library/api";
import {
  clients_url,
  driver_url,
  company_url,
  partner_url,
  contractor_url,
} from "../../../library/Url";

const DriverDashboard = () => {
  const [data, setdata] = useState("");
  const [imgurl, setimgurl] = useState("");
  const history = useHistory();
  const [language, setlanguage] = useState("");
  const [pageContent, setPageContent] = useState({});
  const [count, setCount] = useState("");
  const [company_order, setCompany_order] = useState("");
  const [bid, setBids] = useState("");
  const [start, SetSatrt] = useState(true);
  // State For Button Classes
  const [btnclass1, setbtnClass1] = useState("unselected-btn-dash");
  const [btnclass2, setbtnClass2] = useState("unselected-btn-dash");
  const [btnclass3, setbtnClass3] = useState("unselected-btn-dash");
  /* HOOKS */
  useEffect(() => {
    const lang = localStorage.getItem("language");
    setlanguage(lang);
    api
      .post("driver/company-requests")
      .then((response) => {
        setCompany_order(response.data);
      })
      .catch((err) => console.log(err));
  }, [count]);

  useEffect(() => {
    if (language === "En") {
      setPageContent({
        btn_1: "Connect With Company",
        btn_2: "Register Own Vehicle",
        btn_3: "If you have more than one vehicle please register as company",
        lab_not_company: "No Current Request",
        lab_order: "My Orders",
        btn_1_order: "Completed",
        btn_2_order: "Cancelled",
        btn_3_order: "New Orders",
        lab_welcome: "Welcome Back",
        search_order: "Search Orders",
        track_vehicle: "Track Vehicles",
      });
    } else if (language === "Ur") {
      setPageContent({
        btn_1: "کمپنی کے ساتھ جڑیں",
        btn_2: "اپنی گاڑی رجسٹر کروائیں",
        lab_not_company:
          "براہ کرم کسی بھی کمپنی سے جڑیں یا آرڈر حاصل کرنے کے لیے پہلے اپنی گاڑی رجسٹر کریں",
        lab_order: "میرے آرڈر",
        btn_1_order: "مکمل",
        btn_2_order: "منسوخ",
        btn_3_order: "نئے آرڈر",
        lab_welcome: "خوش آمدید",
        search_order: "آرڈرز تلاش کریں",
        track_vehicle: "گاڑیوں کی ٹریکنگ",
      });
    }
  }, [language]);

  useEffect(() => {
    api.get("userinfo").then((response) => {
      setdata(response.data.data.user);
      if (response.data.data.user.user_is === "client") {
        setimgurl(clients_url);
      } else if (response.data.data.user.user_is === "company") {
        setimgurl(company_url);
      } else if (response.data.data.user.user_is === "contractor") {
        setimgurl(contractor_url);
      } else if (response.data.data.user.user_is === "driver") {
        setimgurl(driver_url);
      } else if (response.data.data.user.user_is === "partner") {
        setimgurl(partner_url);
      }
      api
        .post("driver/all-accepted-orders")
        .then((response) => {
          // console.log(response.data);
          setBids(response.data);
        })
        .catch((err) => console.log(err));
    });
  }, [count]);

  const handleBtnClick1 = () => {
    setbtnClass1("selected-btn-dash");
    setbtnClass2("unselected-btn-dash");
    setbtnClass3("unselected-btn-dash");
  };
  const handleBtnClick2 = () => {
    setbtnClass2("selected-btn-dash");
    setbtnClass1("unselected-btn-dash");
    setbtnClass3("unselected-btn-dash");
  };
  const handleBtnClick3 = () => {
    setbtnClass3("selected-btn-dash");
    setbtnClass2("unselected-btn-dash");
    setbtnClass1("unselected-btn-dash");
  };

  return (
    <>
      {data === "" ? (
        <PreLoader text="Loading..." />
      ) : (
        <div
          className="container"
          style={{ position: "relative", minHeight: "100vh" }}
        >
          <div className="row main-div-dashboard">
            <span className="lan-dropdown" style={{ zIndex: "30" }}>
              <Language value={setlanguage} curr={language} />
            </span>
            {/* <span className="back-btn">
              <IoIcons.IoIosArrowRoundBack
                fill="#fff"
                style={{ width: "89px", height: "60px" }}
              // onClick={() => alert("Clicked back")}
              />
            </span> */}
            <span className="back-btn" style={{ zIndex: "100" }}>
              <IoIcons.IoIosArrowRoundBack
                fill="#fff"
                style={{ width: "89px", height: "60px", zIndex: "120" }}
                onClick={() => history.push("/login")}
              />
            </span>
            <HiIcons.HiDotsVertical
              fill="#fff"
              style={{ height: "27px", width: "70px", zIndex: "20" }}
              className="menu-btn"
              onClick={() => history.push("/user/setting")}
            />
            <div className="col-12">
              <div className="row">
                <div className="col-12 text-center">
                  <img
                    src={`${imgurl}${data.picture}`}
                    alt=""
                    className="dash-img mt-3"
                  />
                  <div className="">
                    <span className="h6 font-weight-bold text-white">
                      {data.name}
                    </span>{" "}
                    <br />
                    <br />
                    {parseInt(data.own_vehicle) === 0 &&
                    data.company_is_connected === 0 ? (
                      <>
                        <button
                          className="place-order-btn"
                          onClick={() => history.push("/connect/company")}
                          style={{ marginTop: "-40px" }}
                        >
                          {pageContent.btn_1}
                        </button>
                        <br />
                      </>
                    ) : (
                      <></>
                    )}
                    {data.vehicle_registered === null ? (
                      <button
                        className="place-order-btn"
                        onClick={() => history.push("/register/truck")}
                      >
                        {pageContent.btn_2}
                      </button>
                    ) : (
                      <span
                        style={{ color: "#fff" }}
                        // className="place-order-btn"
                        // onClick={() => history.push("/login")}
                        // disabled={true}
                      >
                        {pageContent.btn_3}
                      </span>
                    )}
                    {parseInt(data.own_vehicle) === 1 &&
                    data.company_is_connected === 0 ? (
                      <>
                        <div className="card order-btn-container">
                          {language === "Ur" ? (
                            <span
                              className="order-div-content text-right"
                              style={{ marginLeft: "60%" }}
                            >
                              {pageContent.lab_order}
                            </span>
                          ) : (
                            <span className="order-div-content">
                              {pageContent.lab_order}
                            </span>
                          )}
                          <br />
                          <div className="row">
                            <div className="col-12">
                              <div
                                className="ml-2 mt-4"
                                style={{ marginBottom: "10px" }}
                              >
                                <button
                                  className={btnclass1}
                                  onClick={handleBtnClick1}
                                >
                                  {pageContent.btn_1_order}
                                </button>
                                <button
                                  className={btnclass2}
                                  onClick={handleBtnClick2}
                                >
                                  {pageContent.btn_2_order}
                                </button>
                                <button
                                  className={btnclass3}
                                  onClick={handleBtnClick3}
                                >
                                  {pageContent.btn_3_order}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {btnclass1 === "selected-btn-dash" ? (
                          <>
                            <div className="card order-details-container">
                              <div className="row mt-2">
                                <div className="col-7">
                                  <span className="order-detail-div-content ml-2">
                                    Completed Orders
                                    <span style={{ color: "#31b004" }}>
                                      / Company
                                    </span>
                                  </span>
                                </div>
                                <div className="col-5">
                                  <span className="order-id">#89382938</span>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <span className="shipping-detail">
                                    Shipment from Islamabad to Karachi Port
                                  </span>
                                </div>
                              </div>
                              <div className="order-line"></div>
                              <div className="row">
                                <div className="col-5 ml-3">
                                  <span className="requirement-veh">
                                    Vehicles Requested: 8
                                  </span>
                                </div>
                                <div className="col-6">
                                  <span className="time-veh">
                                    26 July 8:00 am - 12:00 pm
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : btnclass2 === "selected-btn-dash" ? (
                          <>
                            <div className="card order-details-container">
                              <div className="row mt-2">
                                <div className="col-7">
                                  <span className="order-detail-div-content ml-2">
                                    Cancelled Orders
                                    <span style={{ color: "#31b004" }}>
                                      / Company
                                    </span>
                                  </span>
                                </div>
                                <div className="col-5">
                                  <span className="order-id">#89382938</span>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <span className="shipping-detail">
                                    Shipment from Islamabad to Karachi Port
                                  </span>
                                </div>
                              </div>
                              <div className="order-line"></div>
                              <div className="row">
                                <div className="col-5 ml-3">
                                  <span className="requirement-veh">
                                    Vehicles Requested: 8
                                  </span>
                                </div>
                                <div className="col-6">
                                  <span className="time-veh">
                                    26 July 8:00 am - 12:00 pm
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : btnclass3 === "selected-btn-dash" ? (
                          <>
                            {bid === "" ? (
                              <>
                                <p className="pt-4">
                                  No Bids Currently Available
                                </p>
                              </>
                            ) : (
                              bid.map((value, index) => {
                                return (
                                  <div
                                    className="card order-details-container mb-5"
                                    key={index}
                                  >
                                    <div className="row mt-2">
                                      <div
                                        className="col-12 text-left pl-3"
                                        onClick={() => {
                                          // history.push("/assign/vehicle/", {
                                          //   id: value.id,
                                          //   req_vehicle: value.requested_vehicle,
                                          // });
                                        }}
                                      >
                                        <span className="order-detail-div-content ml-4">
                                          <b>Order Title: &nbsp;</b>
                                          <span style={{ fontWeight: "300" }}>
                                            {value.title}
                                          </span>
                                        </span>{" "}
                                        <br />
                                        <span
                                          className="order-detail-div-content ml-4 text-truncate d-inline-block"
                                          style={{ width: "90%" }}
                                        >
                                          <b>Address From:&nbsp;</b>
                                          <span style={{ fontWeight: "300" }}>
                                            {
                                              JSON.parse(value.address_from)
                                                .address
                                            }
                                          </span>
                                        </span>
                                        <br />
                                        <span
                                          className="order-detail-div-content ml-4 text-truncate d-inline-block"
                                          style={{ width: "90%" }}
                                        >
                                          <b>Address To:&nbsp;</b>
                                          <span style={{ fontWeight: "300" }}>
                                            {
                                              JSON.parse(value.address_to)
                                                .address
                                            }
                                          </span>
                                        </span>
                                        <br />
                                        <span className="order-detail-div-content ml-4">
                                          <b>Required Vehicles:&nbsp;</b>
                                          <span style={{ fontWeight: "300" }}>
                                            {value.requested_vehicle}
                                          </span>
                                        </span>
                                        <br />
                                        <div className="mb-2"></div>
                                      </div>
                                    </div>
                                    {start ? (
                                      <button
                                        onClick={() => {
                                          SetSatrt(!start);
                                          const payload = {
                                            type: "start",
                                            order_id: value.id,
                                          };
                                          const lat =
                                            localStorage.getItem("lat");
                                          const lng =
                                            localStorage.getItem("lng");
                                          const location = {
                                            lat: lat,
                                            lng: lng,
                                            driver_id: data.id,
                                          };
                                          api
                                            .post(
                                              "/order/start-end-job",
                                              payload
                                            )
                                            .then((response) => {
                                              // console.log(response);
                                              api
                                                .post(
                                                  "update-location",
                                                  location
                                                )
                                                .then((res) => {
                                                  // console.log(res)
                                                })
                                                .catch((err) =>
                                                  console.log(err)
                                                );
                                            })
                                            .catch((err) => {
                                              console.log(err);
                                            });
                                        }}
                                        className="col-9 ml-5 mr-1 mb-4 mt-3 start-order btn btn-success"
                                      >
                                        Start Order
                                      </button>
                                    ) : (
                                      <button
                                        className="col-9 end-order mr-1 ml-5 mb-4 mt-3 btn btn-danger"
                                        onClick={() => {
                                          SetSatrt(!start);
                                          const payload = {
                                            type: "end",
                                            order_id: value.id,
                                          };
                                          api
                                            .post(
                                              "/order/start-end-job",
                                              payload
                                            )
                                            .then((response) => {
                                              // console.log(response);
                                            })
                                            .catch((err) => {
                                              console.log(err);
                                            });
                                        }}
                                      >
                                        End Order
                                      </button>
                                    )}
                                  </div>
                                );
                              })
                            )}
                          </>
                        ) : (
                          <>
                            {" "}
                            <br />
                            {language === "Ur" ? (
                              <b className="mt-3 h4">
                                {" "}
                                {data.name} {pageContent.lab_welcome}
                              </b>
                            ) : (
                              <b className="mt-3 h4">
                                {pageContent.lab_welcome} {data.name}
                              </b>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {company_order.length === 0 ? (
                          <div className="mt-5">
                            <h3 style={{ paddingTop: "10%" }}>
                              {pageContent.lab_not_company}
                            </h3>
                          </div>
                        ) : (
                          company_order.map((data, index) => {
                            return (
                              <div
                                className="card order-details-container text-left"
                                key={index}
                              >
                                <div className="row mt-2">
                                  <div className="col-12">
                                    <span className="order-detail-div-content ml-4">
                                      <b>Id:</b>
                                      {data.id}
                                    </span>
                                    <br />
                                    <span className="order-detail-div-content ml-3">
                                      <span
                                        className="order-detail-div-content ml-2 text-truncate d-inline-block"
                                        style={{ width: "90%" }}
                                      >
                                        From Address:
                                      </span>
                                      <br />
                                      <span
                                        className="ml-4 text-truncate d-inline-block"
                                        style={{
                                          fontWeight: "300",
                                          width: "65%",
                                        }}
                                      >
                                        {
                                          JSON.parse(data.order.address_from)
                                            .address
                                        }
                                      </span>
                                    </span>{" "}
                                    <br />
                                    <span className="order-detail-div-content ml-3">
                                      <span className="order-detail-div-content ml-2">
                                        To Address:
                                      </span>
                                      <br />
                                      <span
                                        className="ml-4 text-truncate d-inline-block"
                                        style={{
                                          fontWeight: "300",
                                          width: "65%",
                                        }}
                                      >
                                        {
                                          JSON.parse(data.order.address_to)
                                            .address
                                        }
                                      </span>
                                    </span>
                                    <span
                                      className="accept-request text-center mt-5"
                                      style={{ width: "40px", height: "40px" }}
                                      onClick={() => {
                                        const line_id = data.id;
                                        api
                                          .post(
                                            "driver/company-request-accept",
                                            { line_id }
                                          )
                                          .then((response) =>
                                            setCount(count + 1)
                                          )
                                          .catch((err) => console.log(err));
                                      }}
                                    >
                                      <TiIcons.TiTick
                                        fill="green"
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                        }}
                                      />
                                    </span>
                                    <span
                                      className="reject-request text-center mt-5"
                                      style={{ width: "40px", height: "40px" }}
                                      onClick={() => {
                                        const line_id = data.id;
                                        api
                                          .post(
                                            "driver/company-request-reject",
                                            { line_id }
                                          )
                                          .then((response) =>
                                            setCount(count + 1)
                                          )
                                          .catch((err) => console.log(err));
                                      }}
                                    >
                                      <ImIcons.ImCross
                                        fill="red"
                                        className="mt-2"
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ height: "100px", border: "2px solid white" }}></div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card bottom-search-car">
                <div className="row mt-3">
                  {parseInt(data.vehicle_registered) === 1 ? (
                    <>
                      <div
                        className="col-7"
                        style={{ marginLeft: "8px" }}
                        onClick={() => {
                          history.push("/user/offer", { requested_vehicle: 1 });
                        }}
                      >
                        <img
                          src={search}
                          alt=""
                          style={{ height: "27px", width: "28px" }}
                        />
                        <span style={{ fontSize: "13px", marginLeft: "10px" }}>
                          {pageContent.search_order}
                        </span>
                      </div>
                      <div
                        className="col-5"
                        style={{ textAlign: "end", marginLeft: "-18px" }}
                        onClick={() => history.push("/user/tracking/")}
                      >
                        <img
                          src={car}
                          alt=""
                          style={{ height: "27px", width: "28px" }}
                        />
                        <span
                          style={{
                            fontSize: "13px",
                            marginLeft: "8px",
                            marginRight: "8px",
                          }}
                        >
                          {pageContent.track_vehicle}
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="col-7"
                        style={{ marginLeft: "8px" }}
                        onClick={() => {
                          toast.warning(
                            "Please Register Your Own Vehicle First."
                          );
                        }}
                      >
                        <img
                          src={search}
                          alt=""
                          style={{ height: "27px", width: "28px" }}
                        />
                        <span style={{ fontSize: "13px", marginLeft: "10px" }}>
                          {pageContent.search_order}
                        </span>
                      </div>
                      <div
                        className="col-5"
                        style={{ textAlign: "end", marginLeft: "-18px" }}
                        onClick={() => {
                          toast.warning(
                            "Please Register Your Own Vehicle First."
                          );
                        }}
                      >
                        <img
                          src={car}
                          alt=""
                          style={{ height: "27px", width: "28px" }}
                        />
                        <span
                          style={{
                            fontSize: "13px",
                            marginLeft: "8px",
                            marginRight: "8px",
                          }}
                        >
                          {pageContent.track_vehicle}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default DriverDashboard;
