import React, { useState, useEffect } from "react";
import "./Main.css";
import * as IoIcons from "react-icons/io";
import * as HiIcons from "react-icons/hi";
import upload from "../../assets/camera.png";
import license from "../../assets/license.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import PreLoader from "../PreLoader/PreLoader";
import Language from "../Language/Language";
import api from "../../library/api";

const DriverSignUp = (props) => {
  console.log(props.location?.state?.data?.data?.user);
  // History For Routing
  const history = useHistory();
  // Preloader
  const [hidepre, setHidePre] = useState(true);
  // States
  const [companylogoPreview, setCompanyLogoPreview] = useState(license);
  const [companylogofile, setCompanylogofile] = useState(null);
  const [MultipleImageFile, setMultipleImageFile] = useState(null);
  const [MultiImgPreview, setMultipleImgPreview] = useState([license]);
  const [checked, setchecked] = useState(false);
  const [btndisabled, setbtn] = useState(true);
  const [name, setname] = useState("");
  const [vehicle_info, setvehicleinfo] = useState("");
  const [experience, setexperience] = useState("");
  const [email, setemail] = useState("");
  const [license_number, setLicenseNo] = useState("");
  const [address, setAddress] = useState("");
  const [typesOfService, setTypeOfService] = useState("");
  const [cover_letter, setCoverLetter] = useState("");
  const [language, setlanguage] = useState("");
  const [pageContent, setPageContent] = useState({});

  /* HOOKS */
  useEffect(() => {
    const lang = localStorage.getItem("language");
    setlanguage(lang);
    if (props?.location?.state?.data !== undefined) {
      setname(props.location?.state?.data?.data?.user.name);
      setvehicleinfo(props.location?.state?.data?.data?.user.own_vehicle);
      setexperience(props.location?.state?.data?.data?.user.experience);
      setemail(props.location?.state?.data?.data?.user.email);
      setLicenseNo(props.location?.state?.data?.data?.user.license_number);
      setAddress(props.location?.state?.data?.data?.user.address);
      setTypeOfService(props.location?.state?.data?.data?.user.type_of_vehicle);
      setCoverLetter(props.location?.state?.data?.data?.user.cover);
    }
  }, []);

  useEffect(() => {
    if (language === "En") {
      setPageContent({
        mainHeading: "Sendit",
        subHeading: "Driver",
        place_name: "Full Registration Name",
        label_name: "Full Registration Name",
        radio1: "I have own vehicle",
        radio2: "No,i don't have own vehicle",
        label_experience: "Over experience 10 year",
        label_email: "Email Address (Optional)",
        label_license: "License Number",
        place_experience: "Over experience 10 year",
        place_email: "Email Address",
        place_license: "License Number",
        dd_1: "Type of services you offer",
        dd_2: "Truck",
        dd_3: "Mazda",
        dd_4: "Container",
        dd_5: "Trailer",
        company_cover: "Company Cover",
        place_cover: "Contractor Cover Letter",
        label_cover: "Contractor Cover Letter",
        pic_btn: "Upload Picture",
        label_address: "Driver Address",
        label_vehicle_photo: "Upload Your License Number",
        place_address: "Driver Address",
        place_vehicle_photo: "Upload Your License Number",
        term_head: "Terms & Conditions",
        term_txt1: " Welcome to send-it app!",
        term_txt2:
          "These terms and conditions outline the rules and regulations forthe use of Company Name Is Website, located athttp://senditweb.com.These terms and conditions outline the rules and regulations forthe use of Company Name Is Website, located athttp://senditweb.com.These terms and conditions outline the rules and regulations forthe use of Company Name Is Website, located athttp://senditweb.com.These terms and conditions outline the rules and regulations forthe use of Company Name Is Website, located athttp://senditweb.com.These terms and conditions outline the rules and regulations forthe use of Company Name Is Website, located athttp://senditweb.com.These terms and conditions outline the rules and regulations forthe use of Company Name Is Website, located athttp://senditweb.com.These terms and conditions outline the rules and regulations forthe use of Company Name Is Website, located athttp://senditweb.com.",
        sub_btn: "Submit Now",
        accpet_term: "Accept Terms & Conditions",
      });
    } else if (language === "Ur") {
      setPageContent({
        mainHeading: "سینڈاِٹ",
        subHeading: "ڈرائیور",
        place_name: "مکمل رجسٹرڈ نام",
        label_name: "مکمل رجسٹرڈ نام",
        radio1: " ذاتی گاڑیاں",
        radio2: "نہیں میری کوئی  ذاتی گاڑی نہیں",
        label_experience: "تجربہ",
        label_email: "ای میل(اختیاری)",
        label_license: "لائسنس نمبر",
        place_experience: "تجربہ",
        place_email: "ای میل",
        place_license: "لائسنس نمبر",
        dd_1: " سہولیات کی قسم جو آپ آفر کرتے ہیں",
        dd_2: "ٹرک",
        dd_3: "مزدا",
        dd_4: "کنٹینر",
        dd_5: "ٹریلر",
        company_cover: "ڈرائیور کا تعارف",
        place_cover: "ڈرائیور کا کور لیٹر",
        label_cover: "ڈرائیور کا کور لیٹر",
        pic_btn: "تصویوراپلوڈکریں",
        place_address: "ڈرائیورکاپتہ",
        place_vehicle_photo: "اپنا لائسنس اپلوڈ کریں",
        label_address: "ڈرائیورکاپتہ",
        label_vehicle_photo: "اپنا لائسنس اپلوڈ کریں",
        term_head: "شرائط و ضوابط",
        term_txt1: " سینڈاِٹ ایپ میں خوش آمدید!",
        term_txt2:
          "یہ شرائط و ضوابط http://senditweb.com پر واقع کمپنی کا نام ہے ویب سائٹ کے استعمال کے لیے قواعد و ضوابط کا خاکہ پیش کرتے ہیں۔ یہ شرائط و ضوابط http://senditweb.com پر واقع کمپنی کا نام ہے ویب سائٹ کے استعمال کے لیے قواعد و ضوابط کا خاکہ پیش کرتے ہیں۔ یہ شرائط و ضوابط http://senditweb.com پر واقع کمپنی کا نام ہے ویب سائٹ کے استعمال کے لیے قواعد و ضوابط کا خاکہ پیش کرتے ہیں۔",
        sub_btn: "درج کریں",
        accpet_term: "شرائط و ضوابط سے متفق",
      });
    }
  }, [language]);

  // Functions
  const handleName = (e) => {
    setname(e.target.value);
  };
  const handleVehicle = (e) => {
    setvehicleinfo(e.target.value);
  };
  const handleExperience = (e) => {
    setexperience(e.target.value);
  };
  const handleEmail = (e) => {
    setemail(e.target.value);
  };
  const handleAddress = (e) => {
    setAddress(e.target.value);
  };
  const handleCover = (e) => {
    setCoverLetter(e.target.value);
  };
  const handleLicense = (e) => {
    setLicenseNo(e.target.value);
  };
  const handleDropDown = (e) => {
    setTypeOfService(e.target.value);
  };

  const handleCheckbox = (e) => {
    setchecked(!checked);
    if (checked) {
      setbtn(true);
    } else {
      setbtn(false);
    }
  };
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setCompanyLogoPreview(URL.createObjectURL(event.target.files[0]));
      setCompanylogofile(event.target.files);
    }
  };

  const onMultipleChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setMultipleImgPreview(URL.createObjectURL(event.target.files[0]));
      setMultipleImageFile(event.target.files);
    }
  };

  const handleClick = () => {
    // let reg = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w\w+)+$/;
    if (props?.location?.state?.data !== undefined) {
      setHidePre(false);
      const otp = localStorage.getItem("otp");
      const phone = localStorage.getItem("phone");
      const formData = new FormData();
      formData.append("name", name);
      formData.append("phone", phone);
      formData.append("own_vehicle", vehicle_info);
      formData.append("experience", experience);
      formData.append("type_of_vehicle", typesOfService);
      formData.append("cover", cover_letter);
      if (companylogofile !== null) {
        formData.append("picture", companylogofile[0]);
      }
      formData.append("address", address);
      formData.append("license_number", license_number);
      if (MultipleImageFile !== null) {
        formData.append("license_number_image", MultipleImageFile[0]);
      }
      formData.append("otp", otp);
      api
        .post("driver/update", formData)
        .then((response) => {
          toast.success("User Updated Successfully");
          setHidePre(true);
          history.push("/user/dashboard");
        })
        .catch((err) => {
          setHidePre(true);
          toast.error("Phone Number Already Exist");
        });
    } else if (props?.location?.state?.data === undefined) {
      if (name === "") {
        toast.error("Please Enter Your Name");
      } else if (vehicle_info === "") {
        toast.error("Please Select Your Vehicle Info");
      } else if (experience === "") {
        toast.error("Please Enter Your Experience");
      } else if (typesOfService === "") {
        toast.error("Please Select Your Service");
      } else if (typesOfService === "Type of vehicle you offer") {
        toast.error("Please Select Your Service");
      } else if (typesOfService === "سہولیات کی قسم جو آپ آفر کرتے ہیں") {
        toast.error("Please Select Your Service");
      } else if (companylogofile === null) {
        toast.error("Please Upload Your Profile Picture");
      } else if (cover_letter === "") {
        toast.error("Please Enter Your Cover Letter");
      } else if (address === "") {
        toast.error("Please Enter Your Address");
      } else if (MultipleImageFile === null) {
        toast.error("Please Upload Your License Picture");
      } else {
        setHidePre(false);
        const otp = localStorage.getItem("otp");
        const phone = localStorage.getItem("phone");
        const formData = new FormData();
        formData.append("name", name);
        formData.append("phone", phone);
        formData.append("own_vehicle", vehicle_info);
        formData.append("experience", experience);
        formData.append("type_of_vehicle", typesOfService);
        formData.append("cover", cover_letter);
        if (companylogofile !== null) {
          formData.append("picture", companylogofile[0]);
        }
        formData.append("address", address);
        formData.append("license_number", license_number);
        if (MultipleImageFile !== null) {
          formData.append("license_number_image", MultipleImageFile[0]);
        }
        formData.append("otp", otp);
        api
          .post("driver/register", formData)
          .then((response) => {
            localStorage.setItem("token", response.data.data.token);
            toast.success("User Registered Successfully");
            setHidePre(true);
            history.push("/user/dashboard");
          })
          .catch((err) => {
            setHidePre(true);
            toast.error("Phone Number Already Exist");
          });
      }
    }
  };
  return (
    <>
      <div className="main-div-company">
        <span className="lan-dropdown">
          <Language value={setlanguage} curr={language} />
        </span>
        <span className="back-btn">
          <IoIcons.IoIosArrowRoundBack
            fill="#fff"
            style={{ width: "89px", height: "60px" }}
            onClick={() => {
              if (props?.location?.state?.data !== undefined) {
                history.push("/user/dashboard");
              } else {
                history.push("/login");
              }
            }}
          />
        </span>
        <HiIcons.HiDotsVertical
          fill="#fff"
          style={{ height: "27px", width: "70px" }}
          className="menu-btn"
        />
        <h2 className="mainheading">{pageContent.mainHeading}</h2>
        <h4 className="subheading">{pageContent.subHeading}</h4>
      </div>
      {hidepre ? (
        <div className="company-login-div">
          {language === "Ur" ? (
            <>
              <p className="text-right pr-4 mt-3">
                <b className="mt-4">{pageContent.label_name}</b> <br />
              </p>
              <input
                type="text"
                className="company-login-input"
                placeholder={pageContent.place_name}
                value={name}
                onChange={handleName}
                dir="rtl"
              />
            </>
          ) : (
            <>
              <p className="mt-3" style={{ marginLeft: "20px" }}>
                <b className="mt-4">{pageContent.label_name}</b> <br />
              </p>
              <input
                type="text"
                className="company-login-input"
                placeholder={pageContent.place_name}
                value={name}
                onChange={handleName}
              />
            </>
          )}
          {language === "Ur" ? (
            <div
              className="text-right pr-4"
              style={{
                marginTop: "10px",
                marginBottom: "30px",
              }}
            >
              <span>
                {pageContent.radio1}
                <input
                  type="radio"
                  value="1"
                  name="have-vehicle"
                  className="radio-btn-forms"
                  onChange={handleVehicle}
                />{" "}
              </span>{" "}
              <br />
              <span style={{ marginTop: "10px" }}>
                {pageContent.radio2}
                <input
                  type="radio"
                  value="0"
                  className="radio-btn-forms"
                  name="have-vehicle"
                  onChange={handleVehicle}
                />{" "}
              </span>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "60px",
                marginTop: "10px",
                marginBottom: "30px",
              }}
            >
              <span>
                <input
                  type="radio"
                  value="1"
                  name="have-vehicle"
                  className="radio-btn-forms"
                  onChange={handleVehicle}
                />{" "}
                {pageContent.radio1}
              </span>
              <span style={{ marginTop: "10px" }}>
                <input
                  type="radio"
                  value="0"
                  className="radio-btn-forms"
                  name="have-vehicle"
                  onChange={handleVehicle}
                />{" "}
                {pageContent.radio2}
              </span>
            </div>
          )}
          {language === "Ur" ? (
            <>
              <p className="text-right pr-4 mt-3">
                <b className="mt-4">{pageContent.label_experience}</b> <br />
              </p>
              <input
                type="text"
                className="company-login-input"
                placeholder={pageContent.place_experience}
                value={experience}
                onChange={handleExperience}
                dir="rtl"
              />
              <p className="text-right pr-4 mt-3">
                <b className="mt-4">{pageContent.label_email}</b> <br />
              </p>
              <input
                type="text"
                className="company-login-input"
                placeholder={pageContent.place_email}
                value={email}
                onChange={handleEmail}
                dir="rtl"
              />
              <p className="text-right pr-4 mt-3">
                <b className="mt-4">{pageContent.label_license}</b> <br />
              </p>
              <input
                type="text"
                className="company-login-input"
                placeholder={pageContent.place_license}
                value={license_number}
                onChange={handleLicense}
                dir="rtl"
              />
              <select
                className="company-login-dropdown text-right"
                onChange={handleDropDown}
              >
                <option>{pageContent.dd_1}</option>
                <option>{pageContent.dd_2}</option>
                <option>{pageContent.dd_3}</option>
                <option>{pageContent.dd_4}</option>
                <option>{pageContent.dd_5}</option>
              </select>
            </>
          ) : (
            <>
              <p className="mt-3" style={{ marginLeft: "20px" }}>
                <b className="mt-4">{pageContent.label_experience}</b> <br />
              </p>
              <input
                type="text"
                className="company-login-input"
                placeholder={pageContent.place_experience}
                value={experience}
                onChange={handleExperience}
              />
              <p className="mt-3" style={{ marginLeft: "20px" }}>
                <b className="mt-4">{pageContent.label_email}</b> <br />
              </p>
              <input
                type="text"
                className="company-login-input"
                placeholder={pageContent.place_email}
                value={email}
                onChange={handleEmail}
              />
              <p className="mt-3" style={{ marginLeft: "20px" }}>
                <b className="mt-4">{pageContent.label_license}</b> <br />
              </p>
              <input
                type="text"
                className="company-login-input"
                placeholder={pageContent.place_license}
                value={license_number}
                onChange={handleLicense}
              />
              <select
                className="company-login-dropdown"
                onChange={handleDropDown}
              >
                <option>{pageContent.dd_1}</option>
                <option>{pageContent.dd_2}</option>
                <option>{pageContent.dd_3}</option>
                <option>{pageContent.dd_4}</option>
                <option>{pageContent.dd_5}</option>
              </select>
            </>
          )}

          {language === "Ur" ? (
            <>
              <p className="text-right pr-4 mt-3">
                <b className="mt-4">{pageContent.company_cover}</b> <br />
              </p>
              <textarea
                type="text"
                className="company-login-textarea"
                placeholder={pageContent.place_cover}
                value={cover_letter}
                onChange={handleCover}
                dir="rtl"
              />
            </>
          ) : (
            <>
              <p className="mt-3" style={{ marginLeft: "20px" }}>
                <b className="mt-4">{pageContent.company_cover}</b> <br />
              </p>
              <textarea
                type="text"
                className="company-login-textarea"
                placeholder={pageContent.place_cover}
                value={cover_letter}
                onChange={handleCover}
              />
            </>
          )}
          <div className="logo-container">
            <div className="image-preview-logo-company">
              <img
                id="target"
                src={companylogoPreview}
                className="logo-company-image"
                alt="ImagePreview"
              />
            </div>
            {language === "Ur" ? (
              <label htmlFor="filePicker" className="select-profile-btn">
                {pageContent.pic_btn}
              </label>
            ) : (
              <label htmlFor="filePicker" className="select-profile-btn">
                {pageContent.pic_btn}
              </label>
            )}
            <input
              id="filePicker"
              accept="image/png, image/gif, image/jpeg"
              style={{ visibility: "hidden" }}
              type={"file"}
              onChange={onImageChange}
            ></input>
          </div>
          {language === "Ur" ? (
            <>
              <p className="text-right pr-4 mt-3">
                <b className="mt-4">{pageContent.label_address}</b> <br />
              </p>
              <textarea
                type="text"
                className="company-login-textarea"
                placeholder={pageContent.place_address}
                value={address}
                onChange={handleAddress}
                dir="rtl"
              />
            </>
          ) : (
            <>
              <p className="mt-3" style={{ marginLeft: "20px" }}>
                <b className="mt-4">{pageContent.label_address}</b> <br />
              </p>
              <textarea
                type="text"
                className="company-login-textarea"
                placeholder={pageContent.place_address}
                value={address}
                onChange={handleAddress}
              />
            </>
          )}
          <br />
          {language === "Ur" ? (
            <p className="pr-4 text-right" style={{ color: "#828282" }}>
              {pageContent.place_vehicle_photo}
            </p>
          ) : (
            <b className="ml-4" style={{ color: "#828282" }}>
              {pageContent.place_vehicle_photo}
            </b>
          )}

          <div className="row text-center contract-image-container">
            <div className="col-1">
              <img
                id="target"
                src={MultiImgPreview}
                className="contract-company-image"
                alt="img"
              />
            </div>
            <div className="col-2">
              <label htmlFor="filePickermultiple" className="license-img">
                <img
                  src={upload}
                  alt="Preview"
                  className="upload-img-btn"
                  style={{ marginTop: "0px" }}
                />
              </label>
              <input
                id="filePickermultiple"
                accept="image/png, image/gif, image/jpeg"
                style={{ visibility: "hidden" }}
                type={"file"}
                onChange={onMultipleChange}
                className="image-input-btn"
              />
            </div>
          </div>

          {language === "Ur" ? (
            <div className="mr-3 text-right term-conditions">
              <b>{pageContent.term_head}</b>
              <p>
                {pageContent.term_txt1}
                <br />
                <br />
                {pageContent.term_txt2}
              </p>
            </div>
          ) : (
            <div className="term-conditions">
              <b>{pageContent.term_head}</b>
              <p>
                {pageContent.term_txt1}
                <br />
                <br />
                {pageContent.term_txt2}
              </p>
            </div>
          )}

          <div>
            {language === "Ur" ? (
              <div className="myTest custom-control custom-checkbox ml-4 new-label-urdu">
                <input
                  type="checkbox"
                  onChange={handleCheckbox}
                  value={checked}
                  className="custom-control-input"
                  id="customCheckUrdu1"
                />
                <label
                  className="custom-control-label"
                  htmlFor="customCheckUrdu1"
                >
                  <b className="urdu-radio-label">{pageContent.accpet_term}</b>
                </label>
              </div>
            ) : (
              <div className="myTest custom-control custom-checkbox ml-4">
                <input
                  type="checkbox"
                  onChange={handleCheckbox}
                  checked={checked}
                  className="custom-control-input"
                  id="customCheck1"
                />
                <label className="custom-control-label" htmlFor="customCheck1">
                  <b className="">{pageContent.accpet_term}</b>
                </label>
              </div>
            )}
            <br />
            <button
              type="submit"
              className="ml-4 submit-company-btn"
              disabled={btndisabled}
              onClick={handleClick}
            >
              {pageContent.sub_btn}
            </button>
          </div>
        </div>
      ) : (
        <PreLoader hidden={hidepre} text="Loading..." />
      )}
      <ToastContainer />
    </>
  );
};

export default DriverSignUp;
