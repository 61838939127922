import React from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

const MyMapComponent = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_NOT_SECRET_CODE}`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  console.log(props.customcoords.lat, "latitude");
  console.log(props.customcoords.lng, "longitude");
  return (
    <GoogleMap defaultZoom={8} defaultCenter={{ lat: 31.5204, lng: 74.3587 }}>
      <Marker position={{ lat: props.customcoords.lat, lng: props.customcoords.lng }} />
    </GoogleMap>
  );
});
export default MyMapComponent;
