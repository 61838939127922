import React, { useState, useEffect } from "react";
import * as IoIcons from "react-icons/io";
import * as HiIcons from "react-icons/hi";
import * as TiIcons from "react-icons/ti";
import * as ImIcons from "react-icons/im";
import { useHistory } from "react-router-dom";
// import PreLoader from "../../PreLoader/PreLoader";
import PreLoader from "../PreLoader/PreLoader";
import api from "../../library/api";
import {
  clients_url,
  driver_url,
  company_url,
  partner_url,
  contractor_url,
} from "../../library/Url";
import "./DriverRequest.css";

const DriverRequest = () => {
  const history = useHistory();
  const [data, setdata] = useState("");
  const [imgurl, setimgurl] = useState("");
  const [drivers, setDrivers] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    api.get("userinfo").then((response) => {
      // console.log(response);
      setdata(response.data.data.user);
      if (response.data.data.user.user_is === "client") {
        setimgurl(clients_url);
      } else if (response.data.data.user.user_is === "company") {
        setimgurl(company_url);
      } else if (response.data.data.user.user_is === "contractor") {
        setimgurl(contractor_url);
      } else if (response.data.data.user.user_is === "driver") {
        setimgurl(driver_url);
      } else if (response.data.data.user.user_is === "partner") {
        setimgurl(partner_url);
      }
    });
    api.get("/company/get-requests").then((response) => {
      setDrivers(response.data);
    });
  }, [count]);
  // console.log(drivers);
  return (
    <>
      {data === "" ? (
        <PreLoader />
      ) : (
        <>
          <div
            className="container"
            style={{ position: "relative", minHeight: "100vh" }}
          >
            <div className="row main-div-dashboard">
              <span
                className="back-btn"
                onClick={() => history.push("/user/dashboard/")}
                style={{ zIndex: "20" }}
              >
                <IoIcons.IoIosArrowRoundBack
                  fill="#fff"
                  style={{ width: "89px", height: "60px" }}
                />
              </span>
              <HiIcons.HiDotsVertical
                fill="#fff"
                style={{ height: "27px", width: "70px", zIndex: "20" }}
                className="menu-btn"
                onClick={() => history.push("/user/setting")}
              />
              <div className="col-12 text-center">
                <img
                  src={`${imgurl}${data.picture}`}
                  alt=""
                  className="dash-img mt-3"
                />
                <div className="">
                  <span className="h6 font-weight-bold text-white">
                    {data.name}
                  </span>
                  <br />
                </div>
                <h2 className="pt-5 text-center text-white">
                  Driver's Request
                </h2>
              </div>
            </div>
            {drivers.length === 0 ? (
              <>
                <div className="col-12 text-center">
                  <p>No Request Found</p>
                </div>
              </>
            ) : (
              <>
                {drivers.map((data, index) => {
                  return (
                    <div className="card order-details-container" key={index}>
                      <div className="row mt-2">
                        <div className="col-12">
                          <img
                            src={`${driver_url}${data.driver.picture}`}
                            alt=""
                            style={{
                              height: "45px",
                              width: "45px",
                              borderRadius: "50px",
                            }}
                            className="ml-2"
                          />
                          <span className="order-detail-div-content ml-2">
                            {data.driver.name}
                          </span>
                          <br />
                          <span className="order-detail-div-content ml-5">
                            <b>License Number:</b>
                            {data.driver.license_number}
                          </span>
                          <span
                            className="accept-request text-center"
                            style={{ width: "40px", height: "40px" }}
                            onClick={() => {
                              const req_id = data.id;
                              api
                                .post("/company/accept-request", { req_id })
                                .then((response) => setCount(count + 1))
                                .catch("Already Rejected");
                            }}
                          >
                            <TiIcons.TiTick
                              fill="green"
                              style={{ width: "40px", height: "40px" }}
                            />
                          </span>
                          <span
                            className="reject-request text-center"
                            style={{ width: "40px", height: "40px" }}
                            onClick={() => {
                              const req_id = data.id;
                              api
                                .post("/company/reject-request", { req_id })
                                .then((response) => setCount(count + 1))
                                .catch("Already Rejected");
                            }}
                          >
                            <ImIcons.ImCross
                              fill="red"
                              className="mt-2"
                              style={{ width: "20px", height: "20px" }}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default DriverRequest;
