import React, { useState, useEffect } from "react";
import car from "../../../assets/car.png";
import search from "../../../assets/search.png";
import * as IoIcons from "react-icons/io";
import * as HiIcons from "react-icons/hi";
import { useHistory } from "react-router-dom";
import "./Tracking.css";
import GoogleMap from './GoogleMap';
import PreLoader from "../../PreLoader/PreLoader";
import Language from "../../Language/Language";
import api from "../../../library/api";
import {
  clients,
  driver,
  company,
  partner,
  contractor,
} from "../../../library/Url";

// import { geolocated } from "react-geolocated";
// import Geocode from "react-geocode";
// Geocode.setApiKey(process.env.REACT_APP_NOT_SECRET_CODE);
// Geocode.setLanguage("en");
// Geocode.setRegion("pk");
// console.log(geolocated,"geo");

const Tracking = () => {
  /* VARIABLES */
  const history = useHistory();
  var apiCall = false;
  /* STATES */
  const [data, setdata] = useState("");
  const [language, setlanguage] = useState("");
  const [pageContent, setPageContent] = useState({});
  const [notsupported, setNotSupported] = useState(false);
  const [imgurl, setimgurl] = useState("");
  const [geoError, setGeoError] = useState(false);
  const [lat, setLat] = useState(false);
  const [lng, setLng] = useState(false);
  /* HOOKS */
  useEffect(() => {
    const lang = localStorage.getItem("language");
    setlanguage(lang);
    api.get("userinfo").then((response) => {
      setdata(response.data.data.user);
      if (response.data.data.user.user_is === "client") {
        setimgurl(clients);
      } else if (response.data.data.user.user_is === "company") {
        setimgurl(company);
      } else if (response.data.data.user.user_is === "contractor") {
        setimgurl(contractor);
      } else if (response.data.data.user.user_is === "driver") {
        setimgurl(driver);
      } else if (response.data.data.user.user_is === "partner") {
        setimgurl(partner);
      }
    });
  }, []);

  useEffect(() => {
    if (language === "En") {
      setPageContent({
        opend_offer: "Opened Offer",
        selected_location: "Selected Location",
        search_new_order: 'Search for new order',
        search_order: "Search Orders",
        track_vehicle: "Track Vehicles",
      });
    } else if (language === "Ur") {
      setPageContent({
        opend_offer: "مواقع",
        selected_location: "مقام منتخب کریں",
        search_new_order: 'نیا آرڈر تلاش کریں',
        search_order: "آرڈرز تلاش کریں",
        track_vehicle: "گاڑیاں کی ٹریکنگ",
      });
    }
  }, [language]);

  useEffect(() => {
    getLoaction();
  }, []);
  
  /* FUNCTIONS */
  const getLoaction = () => {
    console.log(navigator);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, ErrorHandling);
      if (navigator.geolocation) {
        navigator.geolocation.watchPosition(function (position) {
          // if (lat != position.coords.latitude) {
          //   alert(`lat changes from ${lat} to ${position.coords.latitude}`)
          // }
          // if (lng != position.coords.longitude) {
          //   alert(`lng changes from ${lng} to ${position.coords.longitude}`)
          // }
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
          if (!apiCall) {
            apiCall = true;
            setTimeout(() => {
              apiCall = false;
            }, 4000)
          }
        });
      }
    } else {
      setNotSupported("Geolocation is not supported by this browser.");
    }
  }
  const showPosition = (pos) => {
    // console.log(pos);
  }
  const ErrorHandling = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED: {
        setGeoError("User denied the request for Geolocation.");
        break;
      }
      case error.POSITION_UNAVAILABLE: {
        setGeoError("Location information is unavailable.");
        break;
      }
      case error.TIMEOUT: {
        setGeoError("The request to get user location timed out.");
        break;
      }
      case error.UNKNOWN_ERROR: {
        setGeoError("An unknown error occurred.");
        break;
      }
      default: { }
    }
  }
  /* COMPONENTS */
  const RenderGoogleMap = () => {
    return (
      <div className="col-12 mt-4">
        {/* <img src={map} alt="" style={{ objectFit: "fill", width: "100%" }} /> */}
        <GoogleMap customcoords={{ lat, lng }} />
      </div>
    );
  }
  /* RENDERS */
  if (notsupported) {
    return (<div>
      <h5>{notsupported}</h5>
    </div>)
  }
  if (geoError) {
    return (<div>
      <h5>{geoError}</h5>
    </div>)
  }
  return (
    <>
      {
        data === "" ? <PreLoader text="Loading..." /> :
          <div className="container">
            <div className="row main-div-dashboard-tracking">
              <span className="lan-dropdown" style={{ zIndex: "30" }}>
                <Language value={setlanguage} curr={language} />
              </span>
              <div className="col-12" style={{ zIndex: "20" }}>
                <span className="back-btn">
                  <IoIcons.IoIosArrowRoundBack
                    fill="#fff"
                    style={{ width: "89px", height: "60px", zIndex: "10" }}
                    onClick={() => history.push("/user/dashboard/")}
                  />
                </span>
                <HiIcons.HiDotsVertical
                  fill="#fff"
                  style={{ height: "27px", width: "70px" }}
                  className="menu-btn"
                />
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-12 text-center">
                    <div className="row">
                      <div className="col-12 text-center">
                        <img src={`${imgurl}${data.picture}`} alt="" className="dash-img" />
                        <div className="row">
                          <div className="col-12 text-center text-white">
                            <span style={{ fontSize: "16px" }}>
                              {data.name}
                            </span>
                            <br />
                            {/* <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                          Company Enterprises
                        </span> */}
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div
                className="col-12 text-center"
                style={{
                  backgroundColor: "#D81616",
                  height: "50px",
                  paddingTop: "10px",
                }}
              >
                <span className="back-btn">
                  <IoIcons.IoIosArrowRoundBack
                    fill="#fff"
                    style={{
                      width: "89px",
                      height: "30px",
                      zIndex: "10",
                      marginLeft: "-30%",
                    }}
                    onClick={() => history.push("/user/offer/")}
                  />
                </span>
                <span className="text-white font-weight-bold">
                  On the way to pickup, 2 mins away
                </span>
              </div>
            </div>
            <div>
              <div
                style={{ backgroundColor: "#31b004", height: "8px", width: "8px", float: 'left', marginTop: '10px', marginRight: '20px' }}
              ></div>
              <span style={{ borderBottom: "2px solid black" }}>
                Wellington 5t: East Perth WA 6004, Austr..
              </span>
              <br />
              <div
                style={{ backgroundColor: "#D81616", height: "8px", width: "8px", float: 'left', marginTop: '10px', marginRight: '20px' }}
              ></div>
              <span>
                Enchantress way : perth WA 60..{" "}
                <span style={{ color: "orange" }}>Change</span>
              </span>
            </div>
            {RenderGoogleMap()}
            <div style={{ height: "100px", border: "2px solid white" }}></div>

            <div className="row">
              <div className="col-11">
                <div className="card bottom-search-car">
                  <div className="row mt-3">
                    <div className="col-7" style={{ marginLeft: "8px" }} onClick={() => {
                      if (data.user_is !== "client") {
                        history.push("/user/offer/")
                      }
                    }}>
                      <img
                        src={search}
                        alt=""
                        style={{ height: "27px", width: "28px" }}
                      />
                      <span style={{ fontSize: "13px", marginLeft: "10px" }}>
                        {pageContent.search_order}
                      </span>
                    </div>
                    <div
                      className="col-5"
                      style={{ textAlign: "end", marginLeft: "-18px" }}
                    >
                      <img
                        src={car}
                        alt=""
                        style={{ height: "27px", width: "28px" }}
                      />

                      <span
                        style={{
                          fontSize: "13px",
                          marginLeft: "8px",
                          marginRight: "8px",
                        }}
                      >
                        {pageContent.track_vehicle}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      }
    </>
  );
};

export default Tracking;
