import React, { useState, useEffect } from "react";
import car from "../../../assets/car.png";
import search from "../../../assets/search.png";
import * as IoIcons from "react-icons/io";
import * as HiIcons from "react-icons/hi";
import { useHistory } from "react-router-dom";
// import license from "../../../assets/haider.jpg";
import * as BsIcons from "react-icons/bs";
import "./Bid.css";
import Language from "../../Language/Language";
import api from "../../../library/api";
import PreLoader from "../../PreLoader/PreLoader";
import { toast } from "react-toastify";
import {
  clients_url,
  driver_url,
  company_url,
  partner_url,
  contractor_url,
} from "../../../library/Url";
import moment from "moment";
import ReactPlayer from 'react-player';
import { order_video, order_img } from "../../../library/Url";

const Bid = () => {
  /* VARIABLES */
  const history = useHistory();
  /* STATES */
  const [language, setlanguage] = useState("");
  const [pageContent, setPageContent] = useState({});
  const [data, setdata] = useState("");
  const [bid_price, setBidPrice] = useState("");
  const [bid_timeline, setBidtimeline] = useState("");
  const [info, setInfo] = useState("");
  const [imgurl, setimgurl] = useState("");
  // console.log(data);
  /* HOOKS */
  useEffect(() => {
    const lang = localStorage.getItem("language");
    setlanguage(lang);
  }, []);

  useEffect(() => {
    if (language === "En") {
      setPageContent({
        opend_offer: "Opened Offer",
        selected_location: "Selected Location",
        call_us: "Call Us",
        lab_bid: "Your Bid Price",
        lab_deliever: "When will you deliver all this?",
        lab_req_veh: "Vehicles Requested:",
        btn_now: "Bid Now",
        search_order: "Search Orders",
        track_vehicle: "Track Vehicles",
      });
    } else if (language === "Ur") {
      setPageContent({
        opend_offer: "مواقع",
        selected_location: "مقام منتخب کریں",
        call_us: "ہمیں کا ل کریں",
        lab_bid: "آپ کی بولی کی قیمت",
        lab_deliever: "آپ کب تک سامان پہنچادیں کریں گے؟",
        lab_req_veh: "درکار گاڑیاں:",
        btn_now: "بولی لگائیں",
        search_order: "آرڈرز تلاش کریں",
        track_vehicle: "گاڑیاں کی ٹریکنگ",
      });
    }
  }, [language]);

  useEffect(() => {
    let payload = {
      order_id: localStorage.getItem("Order_Id"),
    };
    api
      .get("order/get-by-id/", { params: payload })
      .then((response) => {
        console.log(response, "RESPONSE");
        setdata(response.data[0])
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    api
      .get("userinfo")
      .then((response) => {
        setInfo(response.data.data.user);
        if (response.data.data.user.user_is === "client") {
          setimgurl(clients_url);
        } else if (response.data.data.user.user_is === "company") {
          setimgurl(company_url);
        } else if (response.data.data.user.user_is === "contractor") {
          setimgurl(contractor_url);
        } else if (response.data.data.user.user_is === "driver") {
          setimgurl(driver_url);
        } else if (response.data.data.user.user_is === "partner") {
          setimgurl(partner_url);
        }
      })
      .catch(() => {
        toast.error("User Not Found");
      });
  }, []);
  const handleBid = () => {
    if (bid_price === "") {
      toast.error("Please Enter Bid Price");
    } else if (!parseInt(bid_price)) {
      // console.log(bid_timeline);
      toast.error("Please Enter Correct Bid Price");
    } else if (bid_timeline === "") {
      toast.error("Please Enter When You Will Deliver");
    } else {
      const payload = {
        order_id: data.id,
        bid_val: bid_price,
        bid_timeline: bid_timeline,
        order_user: data.user_id,
      };
      api
        .post("bids/store", payload)
        .then((response) => toast.success("Bid Placed Successfully.")).then((response) => history.push('/user/offer'))
        .catch((err) => toast.error("You Already Bid On This Order."));
    }
  };

  return (
    <>
      {data === "" ? (
        <>
          {" "}
          <PreLoader />
        </>
      ) : (
        <>
          <div className="container">
            <div className="row main-div-dashboard-bid">
              <span className="lan-dropdown" style={{ zIndex: "200" }}>
                <Language value={setlanguage} curr={language} />
              </span>
              <div className="col-12" style={{ zIndex: "20" }}>
                <span className="back-btn">
                  <IoIcons.IoIosArrowRoundBack
                    fill="#fff"
                    style={{ width: "89px", height: "60px", zIndex: "10" }}
                    onClick={() => history.push("/user/offer/")}
                  />
                </span>
                <HiIcons.HiDotsVertical
                  fill="#fff"
                  style={{ height: "27px", width: "70px" }}
                  className="menu-btn"
                />
              </div>
              <div className="col-12" style={{ marginTop: "-80px" }}>
                <div className="row">
                  <div className="col-12 text-center">
                    <div className="row">
                      <div className="col-12 text-center">
                        <img
                          src={`${imgurl}${info.picture}`}
                          alt=""
                          className="dash-img"
                        />
                        <div className="row">
                          <div className="col-12 text-center text-white">
                            <span
                              style={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              {info.name}
                            </span>
                            <br />
                            <span
                              style={{ fontSize: "18px", fontWeight: "bold" }}
                            >
                              Order Details
                            </span>
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="card order-details-container-offer"
              style={{ marginTop: "-17%" }}
            >
              <table
                className="table table-striped mt-4 mx-auto"
                style={{ width: "90%" }}
              >
                <tbody>
                  <tr>
                    <th scope="row">Order Title</th>
                    <td>{data.title}</td>
                  </tr>
                  <tr>
                    <th scope="row">Required Vehicle</th>
                    <td>{data.requested_vehicle}</td>
                  </tr>
                  <tr>
                    <th scope="row">Minimum Price</th>
                    <td>{data.min_price}</td>
                  </tr>
                  <tr>
                    <th scope="row">Maximum Price</th>
                    <td>{data.max_price}</td>
                  </tr>
                  <tr>
                    <th scope="row">From Address</th>
                    <td>{JSON.parse(data.address_from).address}</td>
                  </tr>
                  <tr>
                    <th scope="row">To Address</th>
                    <td>{JSON.parse(data.address_to).address}</td>
                  </tr>
                  <tr>
                    <th scope="row">Item Category</th>
                    <td>{data.category}</td>
                  </tr>
                  <tr>
                    <th scope="row">Item Weight</th>
                    <td>{data.weight}</td>
                  </tr>
                  <tr>
                    <th scope="row">From Time</th>
                    <td>
                      {moment(data.time_from).format(
                        "dddd, MMMM Do YYYY, h:mm:ss a"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">To Time</th>
                    <td>
                      {moment(data.time_to).format(
                        "dddd, MMMM Do YYYY, h:mm:ss a"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Contact Number</th>
                    <td>{data.number}</td>
                  </tr>
                  <tr>
                    <th scope="row">Contact Person Number</th>
                    <td>{data.person_number}</td>
                  </tr>
                  <tr>
                    <th scope="row">Insurance Required</th>
                    <td>{data.need_insurance ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <th scope="row">Order Description</th>
                    <td>{data.description}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-12 mt-3">
              {/* {console.log(order_video, data.order_video_gallery[0].path, "DATA")} */}
              <ReactPlayer url={`${order_video}${data?.order_video_gallery[0]?.path}`} controls={true} height="250px" width="100%" />
            </div>
            <div className="row mx-0 col-12 my-4">
              {
                data.order_gallery.map((data, index) => {
                  // console.log(data.path);
                  return (
                    <div className="col-6" key={index}>
                      <img src={`${order_img}${data.path}`} style={{height:'100px', width: '100px'}} alt="img" />
                    </div>
                  )
                })
              }
            </div>
            <div
              className="card order-details-container-offer"
              style={{ height: "60px" }}
            >
              {language === "Ur" ? (
                <div className="row mt-3">
                  <div className="col-5">
                    <span
                      style={{
                        paddingLeft: "15px",
                        fontWeight: "bold",
                        color: "#D81616",
                      }}
                    >
                      #89382938
                    </span>
                  </div>
                  <div className="col-7">
                    <button
                      className="call-btn-bid"
                      style={{ fontSize: "14px" }}
                    >
                      <BsIcons.BsFillTelephoneFill fill="#fff" />{" "}
                      {pageContent.call_us}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="row mt-3">
                  <div className="col-6">
                    <span
                      style={{
                        paddingLeft: "20px",
                        fontWeight: "bold",
                        color: "#D81616",
                      }}
                    >
                      #89382938
                    </span>
                  </div>
                  <div className="col-6">
                    <button className="call-btn-bid">
                      <BsIcons.BsFillTelephoneFill fill="#fff" />{" "}
                      {pageContent.call_us}
                    </button>
                  </div>
                </div>
              )}
            </div>

            <div
              className="card order-details-container-offer text-center"
              style={{}}
            >
              <div className="row mt-1">
                <div className="col-12">
                  {language === "Ur" ? (
                    <>
                      <div className="mr-4 mt-3 text-right">
                        <p className="text-right mr-4">
                          <b>{pageContent.lab_bid}</b>
                        </p>
                        <input
                          placeholder="$46000"
                          className="bid-price-input"
                        />{" "}
                        <br />
                        <p className="text-right mr-4 mt-2">
                          <b>{pageContent.lab_deliever}</b>
                        </p>
                        <input
                          placeholder="I will send my vehicles 8:00 am"
                          className="bid-price-input"
                        />
                      </div>
                      <div className=" mr-5 mt-3 font-weight-bold text-right">
                        <span style={{ fontSize: "12px" }}>
                          {pageContent.lab_req_veh} {data.requested_vehicle}
                        </span>
                      </div>
                      <div className="col-11 ml-3  mt-2 text-center pb-4">
                        <button className="bid-now-btn">
                          {pageContent.btn_now}
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="pl-1 mt-3">
                        <p className="text-left ml-4">
                          <b>{pageContent.lab_bid}</b>
                        </p>
                        <input
                          placeholder="$46000"
                          className="bid-price-input"
                          value={bid_price}
                          onChange={(e) => setBidPrice(e.target.value)}
                        />
                        {/* <b>{pageContent.lab_deliever}</b> */}
                        <p className="text-left ml-4 mt-2">
                          <b>{pageContent.lab_deliever}</b>
                        </p>
                        <input
                          placeholder="I will send my vehicles 8:00 am"
                          className="bid-price-input"
                          value={bid_timeline}
                          onChange={(e) => setBidtimeline(e.target.value)}
                        />
                      </div>
                      <div className=" ml-4 pl-1 mt-3 font-weight-bold text-left">
                        <span style={{ fontSize: "12px" }}>
                          {pageContent.lab_req_veh} {data.requested_vehicle}
                        </span>
                      </div>
                      <div className="col-12 pl-3 ml-2  mt-2 text-center pb-4" style={{ width: '97%' }}>
                        <button className="bid-now-btn" onClick={handleBid}>
                          {pageContent.btn_now}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="col-12 mt-3">
              <ReactPlayer url={`${order_video}${data.order_video_gallery[0].path}`} controls={true} height="250px" width="100%" />
            </div>
            <div className="row mx-0 col-12 my-4">
              {
                data.order_gallery.map((data, index) => {
                  // console.log(data.path);
                  return (
                    <div className="col-6" key={index}>
                      <img src={`${order_img}${data.path}`} style={{height:'100px', width: '100px'}} alt="img" />
                    </div>
                  )
                })
              }
            </div> */}
            <div style={{ height: "100px", border: "2px solid white" }}></div>

            <div className="row">
              <div className="col-11">
                <div className="card bottom-search-car">
                  <div className="row show-pointer mt-3">
                    <div
                      className="col-7"
                      style={{ marginLeft: "8px" }}
                      onClick={() => history.push("/user/offer/")}
                    >
                      <img
                        src={search}
                        alt=""
                        style={{ height: "27px", width: "28px" }}
                      />
                      <span style={{ fontSize: "13px", marginLeft: "10px" }}>
                        {pageContent.search_order}
                      </span>
                    </div>
                    <div
                      className="col-5"
                      style={{ textAlign: "end", marginLeft: "-18px" }}
                      onClick={() => history.push("/user/tracking/")}
                    >
                      <img
                        src={car}
                        alt=""
                        style={{ height: "27px", width: "28px" }}
                      />
                      <span
                        style={{
                          fontSize: "13px",
                          marginLeft: "8px",
                          marginRight: "8px",
                        }}
                      >
                        {pageContent.track_vehicle}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Bid;
