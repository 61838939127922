import React, { useState, useEffect } from "react";
import "./Main.css";
import * as IoIcons from "react-icons/io";
import * as HiIcons from "react-icons/hi";
import license from "../../assets/license.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import PreLoader from "../PreLoader/PreLoader";
import Language from "../Language/Language";
import api from "../../library/api";

const UserSignUp = (props) => {
  /* STATES */
  // History For Routing
  const history = useHistory();
  // console.log(props.location.state.data.data.user);
  // State For PreLoader
  const [hidepre, setHidePre] = useState(true);

  // State For Form
  const [companylogoPreview, setCompanyLogoPreview] = useState(license);
  const [companylogofile, setCompanylogofile] = useState(null);

  // State For CheckBox & Button
  const [checked, setchecked] = useState(false);
  const [btndisabled, setbtn] = useState(true);
  const [name, setname] = useState("");
  const [accountType, setaccountType] = useState("");
  const [address, setAddress] = useState("");
  const [radio, setRadio] = useState(false);
  // Language State
  const [language, setlanguage] = useState("");
  const [pageContent, setPageContent] = useState({});

  /* HOOKS */
  useEffect(() => {
    const lang = localStorage.getItem("language");
    setlanguage(lang);
    if(props?.location?.state?.data !== undefined){
      setname(props.location?.state?.data?.data?.user.name);
      setAddress(props.location?.state?.data?.data?.user.address);
      setaccountType(props.location?.state?.data?.data?.user.type);
    }
  }, []);
  useEffect(() => {
    if (checked) {
      setbtn(false);
    } else {
      setbtn(true);
    }
  }, [checked, hidepre]);
  useEffect(() => {
    if (language === "En") {
      setPageContent({
        mainHeading: "Sendit",
        subHeading: "Client/User",
        place_name: "Full Name",
        label_name: "Full Name",
        radio1: "Business",
        radio2: "Personal",
        pic_btn: "Upload Picture",
        place_address: "Address",
        label_address: "Address",
        term_head: "Terms & Conditions",
        term_txt1: " Welcome to send-it app!",
        term_txt2:
          "These terms and conditions outline the rules and regulations forthe use of Company Name Is Website, located athttp://senditweb.com.These terms and conditions outline the rules and regulations forthe use of Company Name Is Website, located athttp://senditweb.com.These terms and conditions outline the rules and regulations forthe use of Company Name Is Website, located athttp://senditweb.com.These terms and conditions outline the rules and regulations forthe use of Company Name Is Website, located athttp://senditweb.com.These terms and conditions outline the rules and regulations forthe use of Company Name Is Website, located athttp://senditweb.com.These terms and conditions outline the rules and regulations forthe use of Company Name Is Website, located athttp://senditweb.com.These terms and conditions outline the rules and regulations forthe use of Company Name Is Website, located athttp://senditweb.com.",
        sub_btn: "Submit Now",
        accpet_term: "Accept Terms & Conditions",
      });
    } else if (language === "Ur") {
      setPageContent({
        mainHeading: "سینڈاِٹ",
        subHeading: "صارف/مؤکل",
        place_name: "نام",
        label_name: "نام",
        radio1: "کاروبار",
        radio2: "ذاتی",
        pic_btn: "تصویوراپلوڈکریں",
        place_address: "پتہ",
        label_address: "پتہ",
        term_head: "شرائط و ضوابط",
        term_txt1: " سینڈاِٹ ایپ میں خوش آمدید!",
        term_txt2:
          "یہ شرائط و ضوابط http://senditweb.com پر واقع کمپنی کا نام ہے ویب سائٹ کے استعمال کے لیے قواعد و ضوابط کا خاکہ پیش کرتے ہیں۔ یہ شرائط و ضوابط http://senditweb.com پر واقع کمپنی کا نام ہے ویب سائٹ کے استعمال کے لیے قواعد و ضوابط کا خاکہ پیش کرتے ہیں۔ یہ شرائط و ضوابط http://senditweb.com پر واقع کمپنی کا نام ہے ویب سائٹ کے استعمال کے لیے قواعد و ضوابط کا خاکہ پیش کرتے ہیں۔",
        sub_btn: "درج کریں",
        accpet_term: "شرائط و ضوابط سے متفق",
      });
    }
  }, [language]);
  // Function For Name State Change
  const handleName = (e) => {
    setname(e.target.value);
  };
  // handle Account type
  const handleAccount = (e) => {
    setaccountType(e.target.value);
    setRadio(!radio);
  };
  // Function for checkbox
  const handleCheckbox = (e) => {
    setchecked(!checked);
  };
  const handleAddress = (e) => {
    setAddress(e.target.value);
  };
  // Profile Photo
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setCompanyLogoPreview(URL.createObjectURL(event.target.files[0]));
      setCompanylogofile(event.target.files);
    }
  };
  // Submit Form function
  const handleClick = () => {
    if (props?.location?.state?.data !== undefined) {
      setHidePre(false);
      const phone = localStorage.getItem("phone");
      const otp = localStorage.getItem("otp");
      const formData = new FormData();
      formData.append("name", name);
      formData.append("phone", phone);
      formData.append("type", accountType);
      formData.append("cover", "Example Cover");
      if(companylogofile !== null){
        formData.append("picture", companylogofile[0]);
      }
      formData.append("address", address);
      formData.append("otp", otp);
        api
          .post("client/update", formData)
          .then((response) => {
            toast.success("User Updated Successfully");
            setHidePre(true);
            history.push("/user/dashboard");
          })
          .catch((err) => {
            setHidePre(true);
            toast.error("Phone Number Already Exist");
          });
    }
    else if(props?.location?.state?.data === undefined){      
      if (name === "") {
        toast.error("Please Enter Your Name");
      } else if (accountType === "") {
        toast.error("Please Select Your Account Type");
      }
      else if(companylogofile === null){
          toast.error("Please Upload Your Photo");
      }
       else if (address === "") {
        toast.error("Please Enter Your Address");
      } else {
        setHidePre(false);
        const phone = localStorage.getItem("phone");
        const otp = localStorage.getItem("otp");
        const formData = new FormData();
        formData.append("name", name);
        formData.append("phone", phone);
        formData.append("type", accountType);
        formData.append("cover", "Example Cover");
        if(companylogofile !== null){
          formData.append("picture", companylogofile[0]);
        }
        formData.append("address", address);
        formData.append("otp", otp);
        if(props?.location?.state?.data !== undefined){
          api
            .post("client/update", formData)
            .then((response) => {
              // localStorage.setItem("token", response.data.data.token);
              toast.success("User Updated Successfully");
              setHidePre(true);
              history.push("/user/dashboard");
            })
            .catch((err) => {
              setHidePre(true);
              // console.log(err);
              // setchecked(!checked);
              toast.error("Phone Number Already Exist");
            });
        }
        else{
          api
            .post("client/register", formData)
            .then((response) => {
              localStorage.setItem("token", response.data.data.token);
              toast.success("User Registered Successfully");
              setHidePre(true);
              history.push("/user/dashboard");
            })
            .catch((err) => {
              setHidePre(true);
              // console.log(err);
              // setchecked(!checked);
              toast.error("Phone Number Already Exist");
            });
        }
      }
    }
  };
  return (
    <>
      <div className="main-div-user">
        <span className="lan-dropdown">
          <Language value={setlanguage} curr={language} />
        </span>
        <span className="back-btn">
          <IoIcons.IoIosArrowRoundBack
            fill="#fff"
            style={{ width: "89px", height: "60px" }}
            onClick={() => {
              if(props?.location?.state?.data !== undefined){
                history.push("/user/dashboard")
              }
              else{
                history.push("/login")
              }
            }
          }
          />
        </span>
        <HiIcons.HiDotsVertical
          fill="#fff"
          style={{ height: "27px", width: "70px" }}
          className="menu-btn"
        />
        <h2 className="mainheading">{pageContent.mainHeading}</h2>
        <h4 className="subheading">{pageContent.subHeading}</h4>
      </div>
      {hidepre ? (
        <div className="user-login-div" style={{ marginTop: "10%" }}>
          {language === "Ur" ? (
            <>
              <p className="text-right pr-4 mt-3">
                <b className="mt-4">{pageContent.label_name}</b> <br />
              </p>
              <input
                type="text"
                className="company-login-input"
                placeholder={pageContent.place_name}
                value={name}
                checked={radio}
                onChange={handleName}
                style={{ placeholder: "right" }}
                dir="rtl"
              />
            </>
          ) : (
            <>
              <p className="mt-3" style={{paddingLeft: '19px'}}>
                <b className="mt-4">{pageContent.label_name}</b> <br />
              </p>
              <input
                type="text"
                className="company-login-input"
                placeholder={pageContent.place_name}
                value={name}
                checked={radio}
                onChange={handleName}
                style={{ placeholder: "right" }}
              />
            </>
          )}
          <div className="logo-container">
            <div className="image-preview-logo-company">
              <img
                id="target"
                src={companylogoPreview}
                className="logo-company-image"
                alt="ImagePreview"
              />
            </div>
            <label
              htmlFor="filePicker"
              className="select-profile-btn"
              style={{ width: "100px" }}
            >
              {pageContent.pic_btn}
            </label>
            <input
              id="filePicker"
              accept="image/png, image/gif, image/jpeg"
              style={{ visibility: "hidden" }}
              type={"file"}
              onChange={onImageChange}
            ></input>
          </div>
          <div className="radio-btn-container">
            <span>
              <input
                type="radio"
                className="radio-btn-forms"
                value="business"
                name="account"
                checked={accountType === "business" ? true : false}
                onChange={handleAccount}
              />{" "}
              {pageContent.radio1}
            </span>
            <span style={{ marginTop: "0px", marginLeft: "20px" }}>
              <input
                type="radio"
                value="personal"
                className="radio-btn-forms"
                checked={accountType === "personal" ? true : false}
                name="account"
                onChange={handleAccount}
              />{" "}
              {pageContent.radio2}
            </span>
          </div>
          {language === "Ur" ? (
            <>
              <p className="text-right pr-4 mt-3">
                <b className="mt-4">{pageContent.label_address}</b> <br />
              </p>
              <textarea
                type="text"
                className="company-login-textarea"
                placeholder={pageContent.place_address}
                value={address}
                onChange={handleAddress}
                dir="rtl"
              />
            </>
          ) : (
            <>
              <p className="mt-3" style={{paddingLeft: '19px'}}>
                <b className="mt-4">{pageContent.label_address}</b> <br />
              </p>
              <textarea
                type="text"
                className="company-login-textarea"
                placeholder={pageContent.place_address}
                value={address}
                onChange={handleAddress}
              />
            </>
          )}
          <br />

          {language === "Ur" ? (
            <div className="mr-3 text-right term-conditions">
              <b>{pageContent.term_head}</b>
              <p>
                {pageContent.term_txt1}
                <br />
                <br />
                {pageContent.term_txt2}
              </p>
            </div>
          ) : (
            <div className="term-conditions">
              <b>{pageContent.term_head}</b>
              <p>
                {pageContent.term_txt1}
                <br />
                <br />
                {pageContent.term_txt2}
              </p>
            </div>
          )}

          <div>
            {language === "Ur" ? (
              <div className="myTest custom-control custom-checkbox pr-3 new-label-urdu">
                <input
                  type="checkbox"
                  onChange={handleCheckbox}
                  value={checked}
                  className="custom-control-input"
                  id="customCheckUrdu1"
                />
                <label
                  className="custom-control-label pr-3"
                  htmlFor="customCheckUrdu1"
                >
                  <b className="urdu-radio-label">{pageContent.accpet_term}</b>
                </label>
              </div>
            ) : (
              <div className="myTest custom-control custom-checkbox ml-3">
                <input
                  type="checkbox"
                  onChange={handleCheckbox}
                  checked={checked}
                  className="custom-control-input"
                  id="customCheck1"
                />
                <label className="custom-control-label" htmlFor="customCheck1">
                  <b className="">{pageContent.accpet_term}</b>
                </label>
              </div>
            )}
            <br />
            <button
              type="submit"
              className="submit-company-btn"
              disabled={btndisabled}
              onClick={handleClick}
              style={{ marginLeft: "15px" }}
            >
              {pageContent.sub_btn}
            </button>
          </div>
        </div>
      ) : (
        <PreLoader hidden={hidepre} text="Loading..." />
      )}
      <ToastContainer />
    </>
  );
};

export default UserSignUp;
