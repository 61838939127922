import React, { useState, useEffect } from "react";
import * as IoIcons from "react-icons/io";
import "./Setting.css";
import { useHistory } from "react-router-dom";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import Language from "../../Language/Language";
import api from "../../../library/api";

const Setting = () => {
  const history = useHistory();
  const [language, setlanguage] = useState("");
  const [pageContent, setPageContent] = useState({});
  const [data, setdata] = useState("");
  /* HOOKS */
  useEffect(() => {
    const lang = localStorage.getItem("language");
    setlanguage(lang);
    // api.get("userinfo").then((response) => {
    //   setdata(response.data.data.user)
    //     })
    //     .catch((err) => history.push('/'));
    // });
    api
      .get("userinfo")
      .then((res) => {
        setdata(res.data);
      })
      .catch(() => {
        history.push("/");
      });
  }, []);

  // const handleEditProfile = () => {
  //   setShowSettings(false);
  // };

  useEffect(() => {
    if (language === "En") {
      setPageContent({
        btn_setting: "Settings",
        lab_1: "Change account settings and contact us for support",
        lab_2: "Account",
        lab_3: "Push Notification",
        lab_4: "Edit Profile",
        // lab_5: "Change Password",
        // lab_6: "Add Payment Method",
        // lab_7: "Switch to Service Provider",
        lab_8: "Account",
        lab_9: "Support",
        lab_10: "Feedback",
        // lab_11: "Privacy Policy",
        lab_12: "Logout",
      });
    } else if (language === "Ur") {
      setPageContent({
        btn_setting: "ترتیبات",
        lab_1: "اکاؤنٹ کی ترتیبات کو تبدیل کریں اور مڈ کے لیے ہم سے رابطہ کریں",
        lab_2: "اکاؤنٹنٹ",
        lab_3: "اطلاعات",
        lab_4: "پروفائل میں ترمیم کریں",
        // lab_5: "پاس ورڈ تبدیل کریں",
        // lab_6: "ادائیگی کا طریقہ شامل کریں",
        // lab_7: "سروس فراہم کرنےوالوں سے رابطہ کریں",
        lab_8: "اکاؤنٹنٹ",
        lab_9: "حمایت",
        lab_10: "ردِعمل",
        // lab_11: "رازداری کی پالیسی",
        lab_12: "لاگ آوٹ",
      });
    }
  }, [language]);

  return (
    <>
        <div className="container">
          <div className="row main-div-dashboard-setting">
            <span className="lan-dropdown text-right" style={{ zIndex: "30" }}>
              <Language value={setlanguage} curr={language} />
            </span>
            <div className="col-12">
              <span
                className="back-btn"
                onClick={() => history.push("/user/dashboard/")}
                style={{ width: "89px", height: "60px", zIndex: "10" }}
              >
                <IoIcons.IoIosArrowRoundBack
                  fill="#fff"
                  style={{ width: "89px", height: "60px" }}
                />
              </span>
              <div className="row">
                <div className="col-12 text-center mt-4">
                  <div className="mt-4">
                    <button className="setting-btn" disabled={true}>
                      {pageContent.btn_setting}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row ml-1 mt-3">
              {language === "Ur" ? (
                <>
                  <div className="col-12 text-right pr-4">
                    <b style={{ fontSize: "18px" }}>{pageContent.lab_1}</b>
                  </div>
                  {/* <div className="col-12 mt-3 text-right pr-4">
                  <b style={{ color: "#31B004", fontSize: "18px" }}>
                    {pageContent.lab_2}
                  </b>
                </div> */}
                  <div
                    className="col-12 mt-3 text-right pr-4"
                    onClick={() => {
                      if (data.data?.user?.user_is === "company") {
                        history.push("/company/register", { data: data });
                      }
                    }}
                  >
                    <b style={{ fontSize: "18px" }}>{pageContent.lab_4}</b>
                  </div>
                  <div className="col-6 mt-3 text-left pr-4">
                    <span style={{ marginLeft: "70px" }}>
                      <Toggle defaultChecked={true} />
                    </span>
                  </div>
                  <div className="col-6 mt-3 text-right pr-4">
                    <b style={{ fontSize: "18px" }}>{pageContent.lab_3}</b>
                  </div>
                  {/* <div className="col-12 mt-3 text-right pr-4">
                  <b style={{ fontSize: "18px" }}>{pageContent.lab_5}</b>
                </div>
                <div className="col-12 mt-3 text-right pr-4">
                  <b style={{ fontSize: "18px" }}>{pageContent.lab_6}</b>
                </div>
                <div className="col-12 mt-3 text-right pr-4">
                  <b style={{ fontSize: "18px" }}>{pageContent.lab_7}</b>
                </div> */}
                  <div
                    className="col-11 mt-3"
                    style={{ float: "left", borderBottom: "2px solid black" }}
                  ></div>
                  <div className="col-12 mt-3 text-right pr-4">
                    <b style={{ color: "#31B004", fontSize: "18px" }}>
                      {pageContent.lab_8}
                    </b>
                  </div>
                  <div className="col-12 mt-3 text-right pr-4">
                    <b style={{ fontSize: "18px" }}>{pageContent.lab_9}</b>
                  </div>
                  <div className="col-12 mt-3 text-right pr-4">
                    <b style={{ fontSize: "18px" }}>{pageContent.lab_10}</b>
                  </div>
                  {/* <div className="col-12 mt-3 text-right pr-4">
                  <b style={{ fontSize: "18px" }}>{pageContent.lab_11}</b>
                </div> */}
                  <div
                    className="col-11 mt-3 text-right pr-4"
                    style={{ float: "left", borderBottom: "2px solid black" }}
                  ></div>
                  <div className="col-12 mt-3 text-right pr-4">
                    <b style={{ fontSize: "18px", color: "#d81616" }}>
                      {pageContent.lab_12}
                    </b>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-12 text-left pr-4">
                    <b style={{ fontSize: "18px" }}>{pageContent.lab_1}</b>
                  </div>
                  <div className="col-12 mt-3" onClick={() => {
                      if (data.data?.user?.user_is === "company") {
                        history.push("/company/register", { data: data });
                      }
                      if (data.data?.user?.user_is === "driver") {
                        history.push("/driver/register", { data: data });
                      }
                      if (data.data?.user?.user_is === "client") {
                        history.push("/user/register", { data: data });
                      }
                    }}>
                    <b style={{ fontSize: "18px" }}>{pageContent.lab_4}</b>
                  </div>
                  {/* <div className="col-12 mt-3">
                  <b style={{ fontSize: "18px" }}>{pageContent.lab_5}</b>
                </div> */}
                  <div className="col-8 mt-3 text-left pr-4">
                    <b style={{ fontSize: "18px" }}>{pageContent.lab_3}</b>
                  </div>
                  <div className="col-4 mt-3 text-right pr-4">
                    <span>
                      <Toggle defaultChecked={true} />
                    </span>
                  </div>
                  {/* <div className="col-12 mt-3">
                  <b style={{ fontSize: "18px" }}>{pageContent.lab_6}</b>
                </div> */}
                  {/* <div className="col-12 mt-3">
                  <b style={{ fontSize: "18px" }}>{pageContent.lab_7}</b>
                </div> */}
                  <div
                    className="col-11 mt-3"
                    style={{ float: "left", borderBottom: "2px solid black" }}
                  ></div>
                  <div className="col-12 mt-3">
                    <b style={{ color: "#31B004", fontSize: "18px" }}>
                      {pageContent.lab_8}
                    </b>
                  </div>
                  <div className="col-12 mt-3">
                    <b style={{ fontSize: "18px" }}>{pageContent.lab_9}</b>
                  </div>
                  <div className="col-12 mt-3">
                    <b style={{ fontSize: "18px" }}>{pageContent.lab_10}</b>
                  </div>
                  {/* <div className="col-12 mt-3">
                  <b style={{ fontSize: "18px" }}>{pageContent.lab_11}</b>
                </div> */}
                  <div
                    className="col-11 mt-3"
                    style={{ float: "left", borderBottom: "2px solid black" }}
                  ></div>
                  <div className="col-12 mt-3">
                    <b style={{ fontSize: "18px", color: "#d81616" }} onClick={() => {
                      history.push("/");
                      localStorage.setItem('phone', null);
                      localStorage.setItem('otp', null);
                      }}>
                      {pageContent.lab_12}
                    </b>
                  </div>
                </>
              )}
            </div>
          </div>

          <div style={{ height: "100px", border: "2px solid white" }}></div>
        </div>
    </>
  );
};

export default Setting;
