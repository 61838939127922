import React, { useEffect, useState } from "react";
import * as IoIcons from "react-icons/io";
import * as HiIcons from "react-icons/hi";
import { useHistory } from "react-router-dom";
import "./CompanyConnect.css";
import PreLoader from "../../PreLoader/PreLoader";
import { ToastContainer, toast } from "react-toastify";
import Language from "../../Language/Language";
import api from "../../../library/api";
import {
  clients_url,
  driver_url,
  company_url,
  partner_url,
  contractor_url,
} from "../../../library/Url";

const ConnectWithCompany = () => {
  const [data, setdata] = useState("");
  const [company, setcompany] = useState("");
  const [imgurl, setimgurl] = useState("");
  const [companyid, setcompanyid] = useState("");
  const history = useHistory();
  const [language, setlanguage] = useState("");
  const [pageContent, setPageContent] = useState({});

  /* HOOKS */
  useEffect(() => {
    const lang = localStorage.getItem("language");
    setlanguage(lang);
  }, []);

  useEffect(() => {
    if (language === "En") {
      setPageContent({
        btn_now: "Connect Now",
      });
    } else if (language === "Ur") {
      setPageContent({
        btn_now: "ابھی رابطہ کریں",
      });
    }
  }, [language]);

  useEffect(() => {
    api.get("userinfo").then((response) => {
      setdata(response.data.data.user);
      if (response.data.data.user.user_is === "client") {
        setimgurl(clients_url);
      } else if (response.data.data.user.user_is === "company") {
        setimgurl(company_url);
      } else if (response.data.data.user.user_is === "contractor") {
        setimgurl(contractor_url);
      } else if (response.data.data.user.user_is === "driver") {
        setimgurl(driver_url);
      } else if (response.data.data.user.user_is === "partner") {
        setimgurl(partner_url);
      }
    });
  }, []);
  useEffect(() => {
    api.get("companies/all").then((response) => {
      setcompany(response.data.data.companies);
    });
  }, []);

  const handleCompany = (e) => {
    setcompanyid(e.target.value);
    // console.log(e.target.value);
  };
  const handleClick = () => {
    if (companyid === "") {
      toast.error("Please Select A Company First");
    } else {
      // const data = {JSON.stringify(company_id : companyid)}
      const data = JSON.stringify({ company_id: companyid });
      api.post("driver/connecting", data).then((response) => {
        toast.success("Requested Successfully");
      }).then((response) => history.push('/user/dashboard'));
    }
  };
  return (
    <>
      <div
        className="container"
        style={{ position: "relative", minHeight: "100vh" }}
      >
        <div className="row main-div-dashboard">
          <span className="lan-dropdown" style={{ zIndex: "30" }}>
            <Language value={setlanguage} curr={language} />
          </span>
          <div className="col-12" style={{ zIndex: "20" }}>
            <span className="back-btn">
              <IoIcons.IoIosArrowRoundBack
                fill="#fff"
                style={{ width: "89px", height: "60px", zIndex: "10" }}
                onClick={() => history.push("/user/dashboard/")}
              />
            </span>
            <HiIcons.HiDotsVertical
              fill="#fff"
              style={{ height: "27px", width: "70px" }}
              className="menu-btn"
            />
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-12 text-center">
                <img
                  src={`${imgurl}${data.picture}`}
                  alt=""
                  className="dash-img"
                />
                <div className="">
                  <span className="h6 font-weight-bold text-white">
                    {data.name}
                  </span>{" "}
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row text-center">
          {company.length === 0 ? (
            <PreLoader hidden={false} text="Loading..." />
          ) : (
            company.map((item, key) => {
              return (
                <div className="col-6" key={key}>
                  <label>
                    <input
                      type="radio"
                      name="product"
                      className="card-input-element"
                      value={item.id}
                      onClick={handleCompany}
                    />
                    <div className="card panel panel-default card-input text-center">
                      <p>
                        <img
                          src={`${company_url}${item.picture}`}
                          alt=""
                          style={{
                            width: "100px",
                            height: "80px",
                            marginTop: "10px",
                          }}
                        />
                      </p>
                      <div className="panel-heading">
                        <b>{item.name}</b>
                      </div>
                    </div>
                  </label>
                </div>
              );
            })
          )}
          {company.length === 0 ? (
            <></>
          ) : (
            <div className="col-12">
              <button onClick={handleClick} className="btn-connect-now">
                {pageContent.btn_now}
              </button>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ConnectWithCompany;
