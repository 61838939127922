import React from "react";
import Loader from "react-loader-spinner";
import "./Pre.css";
const PreLoader = (props) => {
  return (
    <>
      <div className="text-center mt-5 preloader" hidden={props.hidden}>
        <div className="loader-container">
          <Loader
            type="TailSpin"
            color="#31b004"
            height={100}
            width={100}
            visible={true}
            className="lod"
          />
          <h3 className="font-weight-bold h5 text-white">{props.text}</h3>
        </div>
      </div>
    </>
  );
};

export default PreLoader;
