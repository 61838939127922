import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Redirect,
} from "react-router-dom";
import Login from "./components/Form/Login";
import LoginAs from "./components/LoginAs/LoginAs";
import CompanySignup from "./components/Form/CompanySignup";
import ContractSignUp from "./components/Form/ContractSignUp";
import DriverSignUp from "./components/Form/DriverSignUp";
import PartnerSignUp from "./components/Form/PartnerSignUp";
import UserSignUp from "./components/Form/UserSignUp";
import Dashboard from "./components/DashBoard/Dashboard";
import Payment from "./components/Payment/Payment";
import Offers from "./components/DashBoard/Offers/Offers";
import Bid from "./components/DashBoard/Bid/Bid";
import PlaceOrder from "./components/DashBoard/PlaceOrder/PlaceOrder";
import Tracking from "./components/DashBoard/Tracking/Tracking";
import AcceptOrder from "./components/DashBoard/AcceptOrder/AcceptOrder";
import Setting from "./components/DashBoard/Setting/Setting";
import RegisterTruck from "./components/DashBoard/RegisterVehicle/RegisterTruck";
import ConnectWithCompany from "./components/DashBoard/ConnectWithCompany/ConnectWithCompany";
import AssignVehicle from "./components/DashBoard/Assign_Vehicle/AssignVehicle";
import DriverRequest from "./components/DriverRequest/DriverRequest";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import api from "./library/api";

function App() {
  const showPosition = (pos) => {
    // console.log(pos);
  };
  let lat = localStorage.getItem("lat");
  let lng = localStorage.getItem("lng");
  useEffect(() => {
    api
      .post("/driver/check-active-order")
      .then((response) => {
        console.log(response);
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(showPosition);
          if (navigator.geolocation) {
            navigator.geolocation.watchPosition(function (position) {
              localStorage.setItem("lat", position.coords.latitude);
              localStorage.setItem("lng", position.coords.longitude);
              const data = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              };
              api
                .post("update-location", data)
                .then((res) => console.log(res))
                .catch((err) => console.log(err));
              console.log(position.coords.latitude);
              console.log(position.coords.longitude);
            });
          }
        }
      })
      .catch((response) => console.log(response));
  }, [lat, lng]);
  return (
    <>
      <Router>
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/login" component={LoginAs} />
          <Route path="/company/register" component={CompanySignup} />
          <Route path="/contract/register" component={ContractSignUp} />
          <Route path="/driver/register" component={DriverSignUp} />
          <Route path="/partner/register" component={PartnerSignUp} />
          <Route path="/user/register" component={UserSignUp} />
          <Route path="/user/dashboard" component={Dashboard} />
          <Route path="/user/payment" component={Payment} />
          <Route path="/user/offer" component={Offers} />
          <Route path="/user/bid" component={Bid} />
          <Route path="/user/placeorder" component={PlaceOrder} />
          <Route path="/user/tracking" component={Tracking} />
          <Route path="/user/accept/order" component={AcceptOrder} />
          <Route path="/user/setting" component={Setting} />
          <Route path="/register/truck" component={RegisterTruck} />
          <Route path="/connect/company" component={ConnectWithCompany} />
          <Route path="/assign/vehicle/" component={AssignVehicle} />
          <Route path="/requested/drivers/" component={DriverRequest} />
        </Switch>
        {/* <Route render={() => <Redirect to="/" />} /> */}
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
