// import React from "react";
import axios from "axios";
// import { ToastContainer, toast } from "react-toastify";

const liveUrl = "https://sendit.enigmapvtltd.com/api/v1/auth/";
// const localUrl = "http://127.0.0.1:8000/api/v1/auth/";
// const localUrl = "http://192.168.0.99:8000/api/v1/auth/";

const api = axios.create({
  baseURL: liveUrl,
  timeout: 6000,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers = {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      };
    } else {
      config.headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    // const originalRequest = error.config;
    // console.log('original request == ' + JSON.stringify(originalRequest));
    console.log(" orignal error --> ", JSON.stringify(error.message));

    if (error.message === "Network Error") {
      throw new Error("Network Error");
    }
    if (error.message === "Request failed with status code 401") {
      throw new Error("Unauthorized Token Expired");
    }
    if (error.message === "Request failed with status code 422") {
      if (error.response.data) {
        throw new Error(JSON.stringify(error.response.data));
      }
      throw new Error("Unauthorized  Status 422");
    }
    console.log("IN API ERROR ->", JSON.stringify(error.response));
    axios.Cancel("");
    return Promise.reject(error);
  }
);

export default api;
