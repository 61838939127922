import React, { useState, useEffect } from "react";
import "./LoginAs.css";
import User from "../../assets/User.png";
import Building from "../../assets/building.png";
import Driver from "../../assets/driver.png";
// import Contract from "../../assets/contract.png";
// import Partner from "../../assets/partner.png";
import { useHistory } from "react-router-dom";
import * as IoIcons from "react-icons/io";
import * as HiIcons from "react-icons/hi";
import Language from "../Language/Language";
import api from "../../library/api";

const LoginAs = () => {
  /* STATES */
  const history = useHistory();
  const [language, setlanguage] = useState("");
  const [pageContent, setPageContent] = useState({});

  /* HOOKS */
  useEffect(() => {
    const lang = localStorage.getItem("language");
    setlanguage(lang);
  }, []);

  useEffect(() => {
    if (language === "En") {
      setPageContent({
        mainHeading: "Sendit",
        subHeading: "We move you",
        btn1: "Client/User",
        btn2: "Company/Carrier/Shipper",
        btn3: "Contractor",
        btn4: "Truck Driver",
        btn5: "Partner",
      });
    } else if (language === "Ur") {
      setPageContent({
        mainHeading: "سینڈاِٹ",
        subHeading: "لاگ اِن​​​​​​​​​​​/شامل بطور",
        btn1: "صارف/مؤکل",
        btn2: "کمپنی/قاصد/اشیاءبھیجنےوالا",
        btn3: "کنٹریکٹر",
        btn4: "ٹرک ڈرائیور",
        btn5: "شراکت دار",
      });
    }
  }, [language]);
  const phone = localStorage.getItem("phone");
  /* FUNCTIONS */
  const handleClient = () => {
    api.post('client/login', {phone}).then((response) => {
      localStorage.setItem('token', response.data.token);
      history.push("/user/dashboard/");
    }).catch((err) => {
      history.push("/user/register/");
    });
  };
  const handleCompany = () => {
    api.post('company/login', {phone}).then((response) => {
      localStorage.setItem('token', response.data.token);
      history.push("/user/dashboard/");
    }).catch((err) => {
      history.push("/company/register/");
    });
  };
  // const handleContract = () => {
  //   history.push("/contract/register/");
  // };
  const handleDriver = () => {
    api.post('driver/login', {phone}).then((response) => {
      localStorage.setItem('token', response.data.token);
      history.push("/user/dashboard/");
    }).catch((err) => {
      history.push("/driver/register/");
    });
  };
  // const handlePartner = () => {
  //   history.push("/partner/register/");
  // };
  /* RENDER */
  return (
    <>
      <div className="main-div">
        <span className="lan-dropdown">
          <Language value={setlanguage} curr={language} />
        </span>
        <span className="back-btn">
          <IoIcons.IoIosArrowRoundBack
            fill="#fff"
            style={{ width: "89px", height: "60px" }}
            onClick={() => history.push("/")}
          />
        </span>
        <HiIcons.HiDotsVertical
          fill="#fff"
          style={{ height: "27px", width: "70px" }}
          className="menu-btn"
        />
        <h2 className="mainheading">{pageContent.mainHeading}</h2>
        <h4 className="subheading">{pageContent.subHeading}</h4>

        <div className="card col-11 ml-3 mt-5 loginas-card">
          <button className="login-as-btn" onClick={handleClient}>
            <img src={User} alt="Users" className="login-btn-img" />
            {pageContent.btn1}
          </button>
          <button className="login-as-btn" onClick={handleCompany}>
            <img src={Building} alt="Building" className="login-btn-img" />
            {pageContent.btn2}
          </button>
          {/* <button className="login-as-btn" onClick={handleContract}>
            <img src={Contract} alt="Contractor" className="login-btn-img" style={{}}/>
            {pageContent.btn3}
          </button> */}
          {/* 

          <button className="login-as-btn-last" onClick={handlePartner}>
            <img src={Partner} alt="Partner" className="login-btn-img" />
            {pageContent.btn5}
          </button> */}
          <button className="login-as-btn" onClick={handleDriver}>
            <img src={Driver} alt="Truck Driver" className="login-btn-img" />
            {pageContent.btn4}
          </button>
        <div style={{height: '25px'}}></div>
        </div>
      </div>
    </>
  );
};

export default LoginAs;
