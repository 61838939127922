import React, { useEffect, useState } from "react";
import * as IoIcons from "react-icons/io";
import * as HiIcons from "react-icons/hi";
import jazzcash from "../../assets/Payment/jazzcash.png";
import co from "../../assets/Payment/2co.png";
import keenu from "../../assets/Payment/keenu.png";
import payfast from "../../assets/Payment/playfast.png";
import adyen from "../../assets/Payment/adyen.png";
import bluesnap from "../../assets/Payment/bluesnap.png";
import alipay from "../../assets/Payment/alipay.png";
import nmi from "../../assets/Payment/nmi.png";
import dotss from "../../assets/Payment/dotss.png";
import tenpay from "../../assets/Payment/tenpay.png";
import wiretransfer from "../../assets/Payment/wiretransfer.png";
import car from "../../assets/car.png";
import search from "../../assets/search.png";
import api from "../../library/api";
import {
  clients_url,
  driver_url,
  company_url,
  partner_url,
  contractor_url,
} from "../../library/Url";

const Payment = () => {
  const [data, setdata] = useState("");
  const [imgurl, setimgurl] = useState("");
  useEffect(() => {
    api.get("userinfo").then((response) => {
      setdata(response.data.data.user);
      if (response.data.data.user.user_is === "client") {
        setimgurl(clients_url);
      } else if (response.data.data.user.user_is === "company") {
        setimgurl(company_url);
      } else if (response.data.data.user.user_is === "contractor") {
        setimgurl(contractor_url);
      } else if (response.data.data.user.user_is === "driver") {
        setimgurl(driver_url);
      } else if (response.data.data.user.user_is === "partner") {
        setimgurl(partner_url);
      }
    });
  }, []);

  return (
    <>
      <div
        className="container"
        style={{ position: "relative", minHeight: "100vh" }}
      >
        <div className="row main-div-dashboard">
          <span className="back-btn">
            <IoIcons.IoIosArrowRoundBack
              fill="#fff"
              style={{ width: "89px", height: "60px" }}
              // onClick={() => alert("Clicked back")}
            />
          </span>
          <HiIcons.HiDotsVertical
            fill="#fff"
            style={{ height: "27px", width: "70px" }}
            className="menu-btn"
          />
          <div className="col-12">
            <div className="row">
              <div className="col-12 text-center">
                <img
                  src={`${imgurl}${data.profile_picture}`}
                  alt=""
                  className="dash-img mt-3"
                />
                <div className="">
                  <span className="h6 font-weight-bold text-white">
                    {data.name}
                  </span>
                </div>
                <span className="h6 font-weight-bold text-white">
                  Payment Gateway
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row mt-5 col-12">
            <div className="col-4 mt-2">
              <img
                src={jazzcash}
                alt=""
                style={{ height: "100%", width: "100%", objectFit: "fill" }}
              />
            </div>
            <div className="col-4 mt-2">
              <img
                src={alipay}
                alt=""
                style={{ height: "100%", width: "100%", objectFit: "fill" }}
              />
            </div>
            <div className="col-4 mt-2">
              <img
                src={co}
                alt=""
                style={{ height: "100%", width: "100%", objectFit: "fill" }}
              />
            </div>
            <div className="col-4 mt-2">
              <img
                src={keenu}
                alt=""
                style={{
                  height: "60%",
                  width: "100%",
                  objectFit: "fill",
                  marginTop: "20px",
                }}
              />
            </div>
            <div className="col-4 mt-2">
              <img
                src={payfast}
                alt=""
                style={{
                  height: "80%",
                  width: "100%",
                  objectFit: "fill",
                  marginTop: "20px",
                }}
              />
            </div>
            <div className="col-4 mt-2">
              <img
                src={adyen}
                alt=""
                style={{
                  height: "80%",
                  width: "100%",
                  objectFit: "fill",
                  marginTop: "20px",
                }}
              />
            </div>
            <div className="col-4 mt-2">
              <img
                src={bluesnap}
                alt=""
                style={{
                  height: "70%",
                  width: "100%",
                  objectFit: "fill",
                  marginTop: "20px",
                }}
              />
            </div>
            <div className="col-4 mt-2">
              <img
                src={nmi}
                alt=""
                style={{
                  height: "60%",
                  width: "100%",
                  objectFit: "fill",
                  marginTop: "20px",
                }}
              />
            </div>
            <div className="col-4 mt-2">
              <img
                src={dotss}
                alt=""
                style={{
                  height: "70%",
                  width: "100%",
                  objectFit: "fill",
                  marginTop: "20px",
                }}
              />
            </div>
            <div className="col-4 mt-2">
              <img
                src={tenpay}
                alt=""
                style={{
                  height: "60%",
                  width: "100%",
                  objectFit: "fill",
                  marginTop: "20px",
                }}
              />
            </div>
            <div className="col-4 mt-2">
              <img
                src={wiretransfer}
                alt=""
                style={{
                  height: "60%",
                  width: "100%",
                  objectFit: "fill",
                  marginTop: "20px",
                }}
              />
            </div>
          </div>
        </div>

        <div style={{ height: "100px", border: "2px solid white" }}></div>

        <div className="row">
          <div className="col-11">
            <div className="card bottom-search-car">
              <div className="row mt-3">
                <div className="col-7" style={{ marginLeft: "8px" }}>
                  <img
                    src={search}
                    alt=""
                    style={{ height: "27px", width: "28px" }}
                  />
                  <span style={{ fontSize: "13px", marginLeft: "10px" }}>
                    Search Orders
                  </span>
                </div>
                <div
                  className="col-5"
                  style={{ textAlign: "end", marginLeft: "-18px" }}
                >
                  <img
                    src={car}
                    alt=""
                    style={{ height: "27px", width: "28px" }}
                  />
                  <span
                    style={{
                      fontSize: "13px",
                      marginLeft: "8px",
                      marginRight: "8px",
                    }}
                  >
                    Track Vehicles
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
