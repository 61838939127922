import React, { useEffect, useState } from "react";
import "./Login.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DigitCode from "../DigitCode/DigitCode";
import Language from "../Language/Language";
import api from "../../library/api";
import { useHistory } from "react-router-dom";

const Login = () => {
  // State
  const [number, setNumber] = useState("");
  const [show, setShow] = useState(true);
  const [server_otp, setServerOtp] = useState(null);
  const [language, setlanguage] = useState("En");
  const history = useHistory();
  const [pageContent, setPageContent] = useState({});
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (language === "En") {
      // localStorage.setItem("language", language);
      setPageContent({
        mainHeading: "Sendit",
        subHeading: "We move you",
        EnterMob: "Enter Mobile Number",
        btn_txt: "Join Now",
      });
    } else if (language === "Ur") {
      // localStorage.setItem("language", language);
      setPageContent({
        mainHeading: "سینڈاِٹ",
        subHeading: "وی موویو",
        EnterMob: " موبائل نمبر درج کیجئے",
        btn_txt: "درج کریں",
      });
    }
  }, [language]);
  useEffect(() => {
    const lang = localStorage.getItem("language");
    setlanguage(lang);
  }, []);
  useEffect (() => {
    let number = localStorage.getItem("phone");
    let otp = localStorage.getItem("otp");
    console.log(number);
    console.log(otp);
    // if(number !=="null" && otp !=="null"){
    //   history.push("/login");
    // }
  })
  //   Functions
  const handleChange = (e) => {
    setNumber(e.target.value);
  };
  // Handle Form Submit
  const handleForm = (e) => {
    e.preventDefault();
    try {
      const new_num_dot = number.split(".");
      const new_num_plus = number.split("+");
      const new_num_minus = number.split("-");
      if (number === "") {
        toast.error("Please Enter Mobile Number");
      } else if (new_num_dot.length > 1) {
        toast.error("Symbols Are Not Allowed");
      } else if (new_num_plus.length > 1) {
        toast.error("Symbols Are Not Allowed");
      } else if (new_num_minus.length > 1) {
        toast.error("Symbols Are Not Allowed");
      } 
      // else if (number.length !== 12) {
      //   toast.error("Please Enter A Valid Number");
      // } 
      else {
        setDisabled(true);
        const otp = Math.floor(100000 + Math.random() * 900000);
        setServerOtp(otp);
        const formData = new FormData();
        formData.append("phone", number);
        // formData.append("otp", OTP);
        const phone = number;
        const json = JSON.stringify({ phone, otp });
        // axios.post('')
        api
          .post(`sendotp`, json)
          .then((response) => {
            // toast.success("OTP Send To Your Number");
            setDisabled(false);
            setShow(false);
          })
          .catch((err) => {
            setDisabled(false);
            console.log(err);
            toast.error("This Phone Number Already Exists.");
          });
        // API Call Will be Here
        localStorage.setItem("phone", number);
      }
    } catch (error) {
      alert("Please Enter Number Only");
    }
  };

  // Return
  return show ? (
    <>
      <div className="main-div">
        <div className="col-11 text-right pt-2">
          <Language value={setlanguage} curr={language} />
        </div>
        <h2 className="mainheading">{pageContent.mainHeading}</h2>
        <h4 className="subheading">{pageContent.subHeading}</h4>

        <div className="card col-11 ml-3 mt-5 login-card">
          <p className="mt-4 mblhead">{pageContent.EnterMob}</p>
          <form onSubmit={handleForm}>
            <input
              type="number"
              className="input-login"
              value={number}
              onChange={handleChange}
              maxLength={14}
              placeholder="ex:923001234567"
              autoFocus={true}
            />
            <br />
            <button
              type="submit"
              className="login-btn"
              disabled={disabled}
              // value={pageContent.btn_txt}
            >{pageContent.btn_txt}</button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  ) : (
    <DigitCode serverotp={server_otp} />
  );
};

export default Login;
