import React, { useEffect, useState } from "react";
import PreLoader from "../../PreLoader/PreLoader";
import { useHistory } from "react-router-dom";
import * as IoIcons from "react-icons/io";
import * as HiIcons from "react-icons/hi";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import MenuItem from "material-ui/MenuItem";
import Select from "@material-ui/core/Select";
import small from "../../../assets/Vehicle_Type/Small Pickups/suzuki_closed.png";
import "./AssignVehicle.css";
import api from "../../../library/api";
import {
  clients,
  driver,
  company,
  partner,
  contractor,
} from "../../../library/Url";
import { toast } from "react-toastify";

const AssignVehicle = (props) => {
  /* VARIABLES */
  const history = useHistory();
  const id = props.location.state.id;
  const req_veh = props.location.state.req_vehicle;

  /* STATES */
  const [data, setdata] = useState("");
  const [imgurl, setimgurl] = useState("");
  const [alldrivers, setDrivers] = useState("");
  const [allvehicles, setVehicles] = useState("");
  const [vehicle_id, setVehicleId] = useState("");
  const [driver_id, setDriverId] = useState("");
  const [count, setCount] = useState(0);
  /* HOOKS */
  useEffect(() => {
    api.get("userinfo").then((response) => {
      setdata(response.data.data.user);
      if (response.data.data.user.user_is === "client") {
        setimgurl(clients);
      } else if (response.data.data.user.user_is === "company") {
        setimgurl(company);
      } else if (response.data.data.user.user_is === "contractor") {
        setimgurl(contractor);
      } else if (response.data.data.user.user_is === "driver") {
        setimgurl(driver);
      } else if (response.data.data.user.user_is === "partner") {
        setimgurl(partner);
      }
    });
  }, []);

  useEffect(() => {
    api.get("company/vehicles").then((response) => {
      setVehicles(response.data);
      console.log(response)
    });
  }, []);
  useEffect(() => {
    api.post("company/all-available-drivers").then((response) => {
      // console.log(response.data);
      setDrivers(response.data);
    });
    api.post("company/all-available-vehicles").then((response) => {
      // console.log(response.data);
      // setVehicles(response.data);
    });
  }, [count]);
  /* FUNCTIONS */
  const handleAssign = () => {
    const payload = {
      order_id: id,
      vehicle_id: vehicle_id,
      driver_id: driver_id,
    };
    api
      .post("company/makeLineitem", payload)
      .then((response) => {
        setCount(count+1);
      }).then((response) => {
        toast.success("Vehicle Assigned To Driver Successfully");
      })
      .catch((err) => toast.error("Something Went Wrong"));
  };
  const handleDriver = (e) => {
    setDriverId(e.target.value);
  }
  const handleVehicle = (e) => {
    setVehicleId(e.target.value);
  }
  return (
    <>
      {data === "" ? (
        <PreLoader text="Loading..." />
      ) : (
        <>
          <div className="container">
            <div className="row main-div-dashboard">
              <span className="back-btn" style={{ zIndex: "10" }}>
                <IoIcons.IoIosArrowRoundBack
                  fill="#fff"
                  style={{ width: "89px", height: "60px", zIndex: "10" }}
                  onClick={() => history.push("/user/dashboard/")}
                />
              </span>
              <HiIcons.HiDotsVertical
                fill="#fff"
                style={{ height: "27px", width: "70px", zIndex: "20" }}
                className="menu-btn"
              />
              <div className="col-12">
                <div className="row">
                  <div className="col-12 text-center">
                    <img
                      src={`${imgurl}${data.picture}`}
                      alt=""
                      className="dash-img mt-3"
                    />
                    <div className="">
                      <span className="h6 font-weight-bold text-white">
                        {data.name}
                      </span>
                      <br />
                      <br />
                    </div>
                    <div className="pt-5">
                      <span className="h6 font-weight-bold text-white">
                        Required Vehicle {req_veh}
                      </span>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12" style={{ marginTop: "10%" }}>
                <MuiThemeProvider>
                  <React.Fragment>
                    <div className="row text-center">
                      <div className="col-12">
                        <b>Select Vehicle</b>
                      </div>
                      <div className="col-12">
                        <br />
                        <Select
                          labelId="demo-simple-select-placeholder-label-label"
                          id="demo-simple-select-placeholder-label"
                          //   value={vehile_type}
                            onChange={handleVehicle}
                          displayEmpty
                          className="select-option"
                        >
                          <MenuItem value="">
                            <em>None</em>{" "}
                          </MenuItem>
                          {allvehicles === "" ? (
                            <MenuItem></MenuItem>
                          ) : (
                            allvehicles.map((data, index) => {

                              return (
                                <MenuItem value={data.id} key={index}>
                                  <span>
                                    {/* <img src={`${driver}${data.picture}`} width="20" height="20" className="mr-4" alt="" style={{marginTop:''}} /> */}
                                    <img
                                      src={small}
                                      width="20"
                                      height="20"
                                      className="mr-4"
                                      alt=""
                                      style={{ marginTop: "" }}
                                    />
                                    {data.registration_number}
                                  </span>
                                </MenuItem>
                              );
                            })
                          )}
                        </Select>
                      </div>
                      <div className="col-12">
                        <br />
                        <div className="col-12">
                          <b>Select Driver</b>
                        </div>
                        <Select
                          labelId="demo-simple-select-placeholder-label-label"
                          id="demo-simple-select-placeholder-label"
                          //   value={vehile_type}
                            onChange={handleDriver}
                          displayEmpty
                          className="select-option mt-4"
                        >
                          <MenuItem value="">
                            <em>None</em>{" "}
                          </MenuItem>
                          {alldrivers === "" ? (
                            <></>
                          ) : (
                            alldrivers.map((data, index) => {
                              return (
                                <MenuItem value={data.id} key={index}>
                                  <span>
                                    {/* <img src={`${driver}${data.picture}`} width="20" height="20" className="mr-4" alt="" style={{marginTop:''}} /> */}
                                    <img
                                      src={small}
                                      width="20"
                                      height="20"
                                      className="mr-4"
                                      alt=""
                                      style={{ marginTop: "" }}
                                    />
                                    {data.name}
                                  </span>
                                </MenuItem>
                              );
                            })
                          )}
                        </Select>
                      </div>
                    </div>
                  </React.Fragment>
                </MuiThemeProvider>
                <div className="col-12 mt-5 text-center pb-5">
                  <button className="assignvehicle-btn" onClick={handleAssign}>
                    Assign
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AssignVehicle;
