import React, { useState, useEffect } from "react";
import "./Main.css";
import * as IoIcons from "react-icons/io";
import * as HiIcons from "react-icons/hi";
import upload from "../../assets/upload_image.jpeg";
import { ToastContainer, toast } from "react-toastify";
import license from "../../assets/license.png";
import { useHistory } from "react-router-dom";
import PreLoader from "../PreLoader/PreLoader";
import Language from "../Language/Language";
import api from "../../library/api";

const CompanySignup = (props) => {
  // History For Routing
  /* VARIABLES */
  const history = useHistory();
  // Pre Loader State
  const [hidepre, setHidePre] = useState(true);

  /* States */
  const [companylogoPreview, setCompanyLogoPreview] = useState(license);
  const [companylogofile, setCompanylogofile] = useState(null);
  const [companyBigPreview, setCompanyBigPreview] = useState(null);
  const [companyBigfile, setCompanyBigfile] = useState(null);
  const [MultipleImageFile, setMultipleImageFile] = useState([]);
  const [MultiImgPreview, setMultipleImgPreview] = useState([]);
  const [checked, setchecked] = useState(false);
  const [btndisabled, setbtn] = useState(true);
  const [name, setName] = useState("");
  const [regno, setRegNo] = useState("");
  const [busniess, setBusniess] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [offices, setOffices] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [address, setAddress] = useState("");
  const [cover_letter, setCoverLetter] = useState("");
  const [language, setlanguage] = useState("");
  const [pageContent, setPageContent] = useState({});

  /* HOOKS */
  useEffect(() => {
    // console.log(props.location.state.data.data.user);
    const lang = localStorage.getItem("language");
    setlanguage(lang);
    if (props?.location?.state?.data !== undefined) {
      setName(props.location?.state?.data?.data?.user.name);
      setRegNo(props.location?.state?.data?.data?.user.registration_number);
      setBusniess(props.location?.state?.data?.data?.user.type);
      setVehicle(props.location?.state?.data?.data?.user.no_of_vehicles);
      setOffices(props.location?.state?.data?.data?.user.no_of_offices);
      setEmail(props.location?.state?.data?.data?.user.email);
      setWebsite(props.location?.state?.data?.data?.user.website);
      setAddress(props.location?.state?.data?.data?.user.address);
      setCoverLetter(props.location?.state?.data?.data?.user.cover);
    }
  }, []);

  useEffect(() => {
    if (language === "En") {
      setPageContent({
        mainHeading: "Sendit",
        subHeading: "Carrier Logistic Company",
        label_name: "Full Registration Name",
        label_rgno: "Registration Number#",
        label_typebus: "Type Of Business",
        label_novehicle: "No. Of Vehicles",
        label_nooffices: "No. Of Offices",
        label_email: "Email Address (Optional)",
        label_website: "Website (Optional)",
        place_name: "Full Registration Name",
        place_rgno: "Registration Number#",
        place_typebus: "Type Of Business",
        place_novehicle: "No. Of Vehicles",
        place_nooffices: "No. Of Offices",
        place_email: "Email Address",
        place_website: "www.website.com",
        company_cover: "Company Cover",
        place_cover: "Company Cover Letter",
        label_cover: "Company Cover Letter",
        pic_btn: "Choose Company Logo",
        label_address: "Company Complete Address",
        place_address: "Company Complete Address",
        place_vehicle_photo: "Add Photos Of Your Vehicles",
        term_head: "Terms & Conditions",
        term_txt1: " Welcome to send-it app!",
        term_txt2:
          "These terms and conditions outline the rules and regulations forthe use of Company Name Is Website, located athttp://senditweb.com.These terms and conditions outline the rules and regulations forthe use of Company Name Is Website, located athttp://senditweb.com.These terms and conditions outline the rules and regulations forthe use of Company Name Is Website, located athttp://senditweb.com.These terms and conditions outline the rules and regulations forthe use of Company Name Is Website, located athttp://senditweb.com.These terms and conditions outline the rules and regulations forthe use of Company Name Is Website, located athttp://senditweb.com.These terms and conditions outline the rules and regulations forthe use of Company Name Is Website, located athttp://senditweb.com.These terms and conditions outline the rules and regulations forthe use of Company Name Is Website, located athttp://senditweb.com.",
        sub_btn: "Submit Now",
        accpet_term: "Accept Terms & Conditions",
      });
    } else if (language === "Ur") {
      setPageContent({
        mainHeading: "سینڈاِٹ",
        subHeading: "کیریرلاجسٹکس کمپنی",
        label_name: "مکمل رجسٹرڈ نام",
        label_rgno: "رجسٹریشن نمبر",
        label_typebus: "کاروبارکی قسم",
        label_novehicle: "گاریوں کی تعداد",
        label_nooffices: "دفاتر کی تعداد",
        label_email: "ای میل(اختیاری)",
        label_website: "ویب سائٹ(اختیاری)",
        place_name: "مکمل رجسٹرڈ نام",
        place_rgno: "رجسٹریشن نمبر",
        place_typebus: "کاروبارکی قسم",
        place_novehicle: "گاریوں کی تعداد",
        place_nooffices: "دفاتر کی تعداد",
        place_email: "ای میل",
        place_website: "ویب سائٹ",
        company_cover: "کمپنی کی تفصیل",
        place_cover: "کمپنی کا کور لیٹر",
        pic_btn: "کمپنی کا لوگو",
        place_address: "کمپنی کامکمل پتہ",
        label_address: "کمپنی کامکمل پتہ",
        place_vehicle_photo: "اپنی موجودہ گاڑیوں کی تصاویر درج کریں",
        term_head: "شرائط و ضوابط",
        term_txt1: " سینڈاِٹ ایپ میں خوش آمدید!",
        term_txt2:
          "یہ شرائط و ضوابط http://senditweb.com پر واقع کمپنی کا نام ہے ویب سائٹ کے استعمال کے لیے قواعد و ضوابط کا خاکہ پیش کرتے ہیں۔ یہ شرائط و ضوابط http://senditweb.com پر واقع کمپنی کا نام ہے ویب سائٹ کے استعمال کے لیے قواعد و ضوابط کا خاکہ پیش کرتے ہیں۔ یہ شرائط و ضوابط http://senditweb.com پر واقع کمپنی کا نام ہے ویب سائٹ کے استعمال کے لیے قواعد و ضوابط کا خاکہ پیش کرتے ہیں۔",
        sub_btn: "درج کریں",
        accpet_term: "شرائط و ضوابط سے متفق",
      });
    }
  }, [language]);

  // State Change Functions
  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleRegNo = (e) => {
    setRegNo(e.target.value);
  };
  const handleBusniess = (e) => {
    setBusniess(e.target.value);
  };
  const handleVehicle = (e) => {
    setVehicle(e.target.value);
  };
  const handleOffice = (e) => {
    setOffices(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleWebsite = (e) => {
    setWebsite(e.target.value);
  };
  const handleAddress = (e) => {
    setAddress(e.target.value);
  };
  const handleCover = (e) => {
    setCoverLetter(e.target.value);
  };

  // CheckBox Function
  const handleCheckbox = (e) => {
    setchecked(!checked);
    if (checked) {
      setbtn(true);
    } else {
      setbtn(false);
    }
  };
  // Company logo Function
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setCompanyLogoPreview(URL.createObjectURL(event.target.files[0]));
      setCompanylogofile(event.target.files);
    }
  };
  // Company background image function
  const onBigImg = (event) => {
    if (event.target.files && event.target.files[0]) {
      setCompanyBigPreview(URL.createObjectURL(event.target.files[0]));
      setCompanyBigfile(event.target.files);
    }
  };
  // image to base64
  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      // Setting Complete Image in state
      setMultipleImageFile((MultipleImageFile) => [
        ...MultipleImageFile,
        reader.result,
      ]);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  // Multiple Image Function
  const onMultipleChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      // Setting Only The Preview URL For image Preview
      setMultipleImgPreview((MultiImg) => [
        ...MultiImg,
        URL.createObjectURL(e.target.files[0]),
      ]);

      // Image To Base64
      const multiimg = e.target.files[0];

      getBase64(multiimg);
    }
  };

  // Form Submit Function
  const handleClick = () => {
    // email regex
    // let reg = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w\w+)+$/;
    if (props?.location?.state?.data !== undefined) {
      setHidePre(false);
      const phone = localStorage.getItem("phone");
      const otp = localStorage.getItem("otp");
      const formData = new FormData();
      formData.append("name", name);
      formData.append("phone", phone);
      formData.append("registration_number", regno);
      formData.append("type", busniess);
      formData.append("no_of_vehicles", vehicle);
      formData.append("no_of_offices", offices);
      formData.append("website", website);
      formData.append("cover", "Example Cover");
      if (companylogofile !== null) {
        formData.append("picture", companylogofile[0]);
      }
      formData.append("address", address);
      formData.append("photos", JSON.stringify(MultipleImageFile));
      if (companyBigfile !== null) {
        formData.append("cover_image", companyBigfile[0]);
      }
      formData.append("otp", otp);      
      api
          .post("company/update", formData)
          .then((response) => {
            toast.success("User Updated Successfully");
            setHidePre(true);
            history.push("/user/dashboard");
          })
          .catch((err) => {
            setHidePre(true);
            toast.error("Phone Number Already Exist");
          });
    }
    else if(props?.location?.state?.data === undefined){
      if (name === "") {
        toast.error("Please Enter Your Name");
      } else if (regno === "") {
        toast.error("Please Enter Your Registration Number");
      } else if (busniess === "") {
        toast.error("Please Enter Your Business Type");
      } else if (vehicle === "") {
        toast.error("Please Enter Your No Of Vehicle");
      } else if (!parseInt(vehicle)) {
        toast.error("Please Enter Correct No Of Vehicles");
      } else if (offices === "") {
        toast.error("Please Enter Your No Of Offices");
      } else if (!parseInt(offices)) {
        toast.error("Please Enter Correct No Of Offices");
      }
      else if (cover_letter === "") {
        toast.error("Please Enter Your Cover Letter");
      } else if (address === "") {
        toast.error("Please Enter Your Address");
      } else if (companylogofile === null) {
          toast.error("Please Upload Company Logo");
      } else if (MultipleImageFile.length === 0) {
          toast.error("Please Upload Vehicles Photos");
      } else if (companyBigfile === null) {
          toast.error("Please Upload Background Photo");
      } else {
        setHidePre(false);
        const phone = localStorage.getItem("phone");
        const otp = localStorage.getItem("otp");
        const formData = new FormData();
        formData.append("name", name);
        formData.append("phone", phone);
        formData.append("registration_number", regno);
        formData.append("type", busniess);
        formData.append("no_of_vehicles", vehicle);
        formData.append("no_of_offices", offices);
        formData.append("website", website);
        formData.append("cover", "Example Cover");
        if (companylogofile !== null) {
          formData.append("picture", companylogofile[0]);
        }
        formData.append("address", address);
        formData.append("photos", JSON.stringify(MultipleImageFile));
        if (companyBigfile !== null) {
          formData.append("cover_image", companyBigfile[0]);
        }
        formData.append("otp", otp);
          api
            .post("company/register", formData)
            .then((response) => {
              localStorage.setItem("token", response.data.data.token);
              toast.success("User Registered Successfully");
              setHidePre(true);
              history.push("/user/dashboard");
            })
            .catch((err) => {
              setHidePre(true);
              toast.error("Phone Number Already Exist");
            });
      }
    }
  };
  return (
    <>
      <div className="main-div-company">
        <span className="lan-dropdown">
          <Language value={setlanguage} curr={language} />
        </span>
        <span className="back-btn">
          <IoIcons.IoIosArrowRoundBack
            fill="#fff"
            style={{ width: "89px", height: "60px" }}
            onClick={() => {
              if (props?.location?.state?.data !== undefined) {
                history.push("/user/dashboard");
              } else {
                history.push("/login");
              }
            }}
          />
        </span>
        <HiIcons.HiDotsVertical
          fill="#fff"
          style={{ height: "27px", width: "70px" }}
          className="menu-btn"
        />
        <h2 className="mainheading">{pageContent.mainHeading}</h2>
        <h4 className="subheading">{pageContent.subHeading}</h4>
      </div>
      {hidepre ? (
        <div className="company-login-div">
          {language === "Ur" ? (
            <>
              <p className="text-right pr-4 mt-3">
                <b className="mt-4">{pageContent.label_name}</b> <br />
              </p>
              <input
                type="text"
                className="company-login-input"
                placeholder={pageContent.place_name}
                value={name}
                onChange={handleName}
                dir="rtl"
              />
              <p className="text-right pr-4 mt-3">
                <b className="mt-4">{pageContent.label_rgno}</b> <br />
              </p>
              <input
                type="text"
                className="company-login-input"
                placeholder={pageContent.place_rgno}
                value={regno}
                onChange={handleRegNo}
                dir="rtl"
              />
              <p className="text-right pr-4 mt-3">
                <b className="mt-4">{pageContent.label_typebus}</b> <br />
              </p>
              <input
                type="text"
                className="company-login-input"
                placeholder={pageContent.place_typebus}
                value={busniess}
                onChange={handleBusniess}
                dir="rtl"
              />
              <p className="text-right pr-4 mt-3">
                <b className="mt-4">{pageContent.label_novehicle}</b> <br />
              </p>
              <input
                type="text"
                className="company-login-input"
                placeholder={pageContent.place_novehicle}
                value={vehicle}
                onChange={handleVehicle}
                dir="rtl"
              />
              <p className="text-right pr-4 mt-3">
                <b className="mt-4">{pageContent.label_nooffices}</b> <br />
              </p>
              <input
                type="text"
                className="company-login-input"
                placeholder={pageContent.place_nooffices}
                value={offices}
                onChange={handleOffice}
                dir="rtl"
              />
              <p className="text-right pr-4 mt-3">
                <b className="mt-4">{pageContent.label_email}</b> <br />
              </p>
              <input
                type="text"
                className="company-login-input"
                placeholder={pageContent.place_email}
                value={email}
                onChange={handleEmail}
                dir="rtl"
              />
              <p className="text-right pr-4 mt-3">
                <b className="mt-4">{pageContent.label_website}</b> <br />
              </p>
              <input
                type="text"
                className="company-login-input"
                placeholder={pageContent.place_website}
                value={website}
                onChange={handleWebsite}
                dir="rtl"
              />
            </>
          ) : (
            <>
              <p className="mt-3" style={{ paddingLeft: "22px" }}>
                <b className="mt-4">{pageContent.label_name}</b> <br />
              </p>
              <input
                type="text"
                className="company-login-input"
                placeholder={pageContent.place_name}
                value={name}
                onChange={handleName}
              />
              <p className="mt-3" style={{ paddingLeft: "22px" }}>
                <b className="mt-4">{pageContent.label_rgno}</b> <br />
              </p>
              <input
                type="text"
                className="company-login-input"
                placeholder={pageContent.place_rgno}
                value={regno}
                onChange={handleRegNo}
              />
              <p className="mt-3" style={{ paddingLeft: "22px" }}>
                <b className="mt-4">{pageContent.label_typebus}</b> <br />
              </p>
              <input
                type="text"
                className="company-login-input"
                placeholder={pageContent.place_typebus}
                value={busniess}
                onChange={handleBusniess}
              />
              <p className="mt-3" style={{ paddingLeft: "22px" }}>
                <b className="mt-4">{pageContent.label_novehicle}</b> <br />
              </p>
              <input
                type="text"
                className="company-login-input"
                placeholder={pageContent.place_novehicle}
                value={vehicle}
                onChange={handleVehicle}
              />
              <p className="mt-3" style={{ paddingLeft: "22px" }}>
                <b className="mt-4">{pageContent.label_nooffices}</b> <br />
              </p>
              <input
                type="text"
                className="company-login-input"
                placeholder={pageContent.place_nooffices}
                value={offices}
                onChange={handleOffice}
              />
              <p className="mt-3" style={{ paddingLeft: "22px" }}>
                <b className="mt-4">{pageContent.label_email}</b> <br />
              </p>
              <input
                type="text"
                className="company-login-input"
                placeholder={pageContent.place_email}
                value={email}
                onChange={handleEmail}
              />
              <p className="mt-3" style={{ paddingLeft: "22px" }}>
                <b className="mt-4">{pageContent.label_website}</b> <br />
              </p>
              <input
                type="text"
                className="company-login-input"
                placeholder={pageContent.place_website}
                value={website}
                onChange={handleWebsite}
              />
            </>
          )}
          {language === "Ur" ? (
            <>
              <p className="text-right pr-4 mt-3" dir="rtl">
                <b className="mt-4">{pageContent.company_cover}</b> <br />
              </p>
              <textarea
                type="text"
                className="company-login-textarea"
                placeholder={pageContent.place_cover}
                value={cover_letter}
                onChange={handleCover}
                dir="rtl"
              />
            </>
          ) : (
            <>
              <p className="mt-3" style={{ paddingLeft: "22px" }}>
                <b className="mt-4">{pageContent.company_cover}</b> <br />
              </p>
              <textarea
                type="text"
                className="company-login-textarea"
                placeholder={pageContent.place_cover}
                value={cover_letter}
                onChange={handleCover}
              />
            </>
          )}
          <div className="logo-container">
            <div className="image-preview-logo-company">
              <img
                id="target"
                src={companylogoPreview}
                className="logo-company-image"
                alt="ImagePreview"
              />
            </div>
            {language === "Ur" ? (
              <label htmlFor="filePicker" className="select-logo-btn">
                {pageContent.pic_btn}
              </label>
            ) : (
              <label htmlFor="filePicker" className="select-logo-btn">
                {pageContent.pic_btn}
              </label>
            )}
            <input
              id="filePicker"
              accept="image/png, image/gif, image/jpeg"
              style={{ visibility: "hidden" }}
              type={"file"}
              onChange={onImageChange}
            ></input>
          </div>
          {language === "Ur" ? (
            <>
              <p className="text-right pr-4 mt-3" dir="rtl">
                <b className="mt-4">{pageContent.label_address}</b> <br />
              </p>
              <textarea
                type="text"
                className="company-login-textarea"
                placeholder={pageContent.place_address}
                value={address}
                onChange={handleAddress}
                dir="rtl"
              />
            </>
          ) : (
            <>
              <p className="mt-3" style={{ paddingLeft: "22px" }}>
                <b className="mt-4">{pageContent.label_address}</b> <br />
              </p>
              <textarea
                type="text"
                className="company-login-textarea"
                placeholder={pageContent.place_address}
                value={address}
                onChange={handleAddress}
              />
            </>
          )}
          <br />
          {language === "Ur" ? (
            <p className="text-right pr-4" style={{ color: "#828282" }}>
              {pageContent.place_vehicle_photo}
            </p>
          ) : (
            <b className="ml-4" style={{ color: "#828282" }}>
              {pageContent.place_vehicle_photo}
            </b>
          )}

          <div className="" style={{ paddingLeft: "1px", marginLeft: "0px" }}>
            {MultiImgPreview.map(function (img, index) {
              return (
                <div className="main-content-img" key={index}>
                  <img
                    key={index}
                    id="target"
                    src={img}
                    className="multiple-company-image"
                    alt="lmage"
                  />
                </div>
              );
            })}
            {/* <div className=""> */}
            <label htmlFor="filePickermultiple" className="col-3">
              <img src={upload} alt="Preview" className="upload-img-btn" />
            </label>
            <input
              id="filePickermultiple"
              accept="image/png, image/gif, image/jpeg"
              style={{ visibility: "hidden" }}
              type={"file"}
              onChange={onMultipleChange}
              className="image-input-btn"
              // multiple={true}
            />
            {/* </div> */}
          </div>

          <div className="big-container">
            <div className="ml-3 col-11 image-preview-big-company">
              <label htmlFor="filePickerBig">
                <img
                  src={upload}
                  alt="Preview"
                  className="upload-img-btn-big"
                />
              </label>
              <input
                id="filePickerBig"
                accept="image/png, image/gif, image/jpeg, image/jpg"
                style={{ visibility: "hidden" }}
                type={"file"}
                onChange={onBigImg}
                className="image-input-btn"
              />
              <img
                id="target"
                src={companyBigPreview}
                className="big-company-image"
                alt="ImagePreview"
              />
            </div>
          </div>

          {language === "Ur" ? (
            <div className="mr-3 text-right term-conditions">
              <b>{pageContent.term_head}</b>
              <p>
                {pageContent.term_txt1}
                <br />
                <br />
                {pageContent.term_txt2}
              </p>
            </div>
          ) : (
            <div className="term-conditions">
              <b>{pageContent.term_head}</b>
              <p>
                {pageContent.term_txt1}
                <br />
                <br />
                {pageContent.term_txt2}
              </p>
            </div>
          )}

          <div>
            {language === "Ur" ? (
              <div className="myTest custom-control custom-checkbox ml-3 new-label-urdu">
                <input
                  type="checkbox"
                  onChange={handleCheckbox}
                  value={checked}
                  className="custom-control-input"
                  id="customCheckUrdu1"
                />
                <label
                  className="custom-control-label"
                  htmlFor="customCheckUrdu1"
                >
                  <b className="urdu-radio-label">{pageContent.accpet_term}</b>
                </label>
              </div>
            ) : (
              <div className="myTest custom-control custom-checkbox ml-4">
                <input
                  type="checkbox"
                  onChange={handleCheckbox}
                  checked={checked}
                  className="custom-control-input"
                  id="customCheck1"
                />
                <label className="custom-control-label" htmlFor="customCheck1">
                  <b className="">{pageContent.accpet_term}</b>
                </label>
              </div>
            )}
            <br />
            <button
              type="submit"
              className="submit-company-btn"
              disabled={btndisabled}
              onClick={handleClick}
              style={{ marginLeft: "15px" }}
            >
              {pageContent.sub_btn}
            </button>
          </div>
        </div>
      ) : (
        <PreLoader hidden={hidepre} text="Loading...." />
      )}
      <ToastContainer />
    </>
  );
};

export default CompanySignup;
