import React, { useState, useEffect } from "react";
import car from "../../../assets/car.png";
import search from "../../../assets/search.png";
import * as IoIcons from "react-icons/io";
import * as HiIcons from "react-icons/hi";
import { useHistory } from "react-router-dom";
import "./PlaceOrder.css";
import upload from "../../../assets/upload_image.jpeg";
import { ToastContainer, toast } from "react-toastify";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import MenuItem from "material-ui/MenuItem";
import Select from "@material-ui/core/Select";
import small from "../../../assets/Vehicle_Type/Small Pickups/suzuki_closed.png";
import medium from "../../../assets/Vehicle_Type/Medium Pickups/mazda_containerized.png";
import heavy from "../../../assets/Vehicle_Type/Heavy Pickups/40ft_containerized.png";
import suzuki_c from "../../../assets/Vehicle_Type/Small Pickups/suzuki_closed.png";
import suzuki_o from "../../../assets/Vehicle_Type/Small Pickups/suzuki_open.png";
import shahzor_o from "../../../assets/Vehicle_Type/Medium Pickups/shahzor_open.png";
import shahzor_c from "../../../assets/Vehicle_Type/Medium Pickups/shahzor_closed.png";
import mazda_o from "../../../assets/Vehicle_Type/Medium Pickups/mazda_open.png";
import mazda_fl from "../../../assets/Vehicle_Type/Medium Pickups/mazda_flatbed.png";
import mazda_con from "../../../assets/Vehicle_Type/Medium Pickups/mazda_containerized.png";
import mazda_c_20 from "../../../assets/Vehicle_Type/Medium Pickups/mazda_commercial_20ft.png";
import mazda_c_16 from "../../../assets/Vehicle_Type/Medium Pickups/mazda_commercial_16ft.png";
import heavy_c_20 from "../../../assets/Vehicle_Type/Heavy Pickups/20ft_containerized.png";
import heavy_fb_20 from "../../../assets/Vehicle_Type/Heavy Pickups/20ft_flat_body.png";
import heavy_hf_20 from "../../../assets/Vehicle_Type/Heavy Pickups/20ft_halfbed.png";
import heavy_c_40 from "../../../assets/Vehicle_Type/Heavy Pickups/40ft_containerized.png";
import heavy_fb_40 from "../../../assets/Vehicle_Type/Heavy Pickups/40ft_flatbed.png";
import heavy_hf_40 from "../../../assets/Vehicle_Type/Heavy Pickups/40ft_half_body.png";
import Autocomplete from "react-google-autocomplete";
import PreLoader from "../../PreLoader/PreLoader";
import Language from "../../Language/Language";
import api from "../../../library/api";
import {
  clients_url,
  driver_url,
  company_url,
  partner_url,
  contractor_url,
} from "../../../library/Url";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";

// import FileThumbnail from "react-uploaded-video-preview";

const PlaceOrder = () => {
  /* VARIABLES */
  /* STATES */
  const [data, setdata] = useState("");
  const [imgurl, setimgurl] = useState("");
  // const [companylogoPreview, setCompanyLogoPreview] = useState([]);
  const [companylogofile, setCompanylogofile] = useState("");
  const [MultipleImageFile, setMultipleImageFile] = useState([]);
  const [MultiImgPreview, setMultipleImgPreview] = useState([]);
  const [order_title, setOrderTitle] = useState("");
  const [order_desc, setOrder_desc] = useState("");
  const [from_address, setfrom_address] = useState({});
  const [to_address, setto_address] = useState({});
  const [request_vehicle, setRequested_vehicle] = useState("");
  const [weight, setWeight] = useState("");
  const [min_price, setMinPrice] = useState("");
  const [max_price, setMaxPrice] = useState("");
  const [from_time, setFromTime] = useState(new Date());
  const [to_time, setToTime] = useState(new Date());
  const [number, setNumber] = useState("");
  const [person_number, setPersonNumber] = useState("");
  const [item_category, setItemCategory] = useState("");
  const [insreq, setinsReq] = useState(true);
  const history = useHistory();
  const [language, setlanguage] = useState("");
  const [pageContent, setPageContent] = useState({});
  const [from_time_format, setFromTimeFormat] = useState("");
  const [to_time_format, setToTimeFormat] = useState("");
  const [item_unit, setItemUnit] = useState("");
  const [vehile_type, setvehile_type] = useState("");
  const [sub_type, setsub_type] = useState("");
  /* HOOKS */
  useEffect(() => {
    const lang = localStorage.getItem("language");
    setlanguage(lang);
  }, []);

  useEffect(() => {
    if (language === "En") {
      setPageContent({
        main_btn: "Create New Order",
        lab_title: "Order Title",
        place_title: "Order Title",
        place_desc: "Order Description",
        label_desc: "Order Description",
        lab_from_add: "From Address",
        place_from_add: "Enter From Location",
        lab_to_add: "To Address",
        place_to_add: "Enter To Location",
        lab_req_vehi: "Requested Vehicles",
        place_req_vehi: "Requested Vehicles",
        from_time_lab: "From Time",
        to_time_lab: "To Time",
        lab_dd1: "Select Vehicle Type",
        lab_dd2: "Select Vehicle Sub Type",
        dd1: "Item Category",
        dd2: "Home Laguage",
        dd3: "Furniture",
        dd4: "Container",
        dd5: "Trailer",
        d2d1: "kg",
        d2d2: "ltr",
        d2d3: "pcs",
        d2d4: "box",
        d2d5: "Item Weight Unit",
        place_item_weight: "Item Weight",
        lab_item_weight: "Item Weight",
        lab_price_range: "Price Range",
        place_price_1: "Minimum Price",
        place_price_2: "Maximum Price",
        place_from_time: "From Time & Date",
        place_to_time: "To Time & Date",
        place_contact_number: "Contact Number",
        lab_contact_number: "Contact Number",
        place_person_contact_number: "Contact Person Number",
        lab_person_contact_number: "Contact Person Number (Optional)",
        lab_insurance: "Need Insurance?",
        lab_video_upl: "Upload Your Luggage Video",
        lab_pic_upl: "Upload Your Luggage Picture",
        btn_sb: "Place Your Order Now",
        search_btn: "Search Companies/Contractors",
        track_btn: "Track Vehicle",
      });
    } else if (language === "Ur") {
      setPageContent({
        main_btn: "نیا آرڈر بنائیں",
        lab_title: "آرڈر کا عنوان",
        place_title: "آرڈر کا عنوان",
        place_desc: "آرڈر کی تفصیل",
        lab_desc: "آرڈر کی تفصیل",
        lab_from_add: "پتہ سے",
        place_from_add: "مقام داخل کریں",
        lab_to_add: "پتہ تک",
        place_to_add: "مقام داخل کریں",
        lab_req_vehi: "گاڑیوں کی مطلوبہ تعداد",
        place_req_vehi: "گاڑیوں کی مطلوبہ تعداد",
        from_time_lab: "وقت سے",
        to_time_lab: "وقت تک",
        lab_dd1: "گاڑی کی قسم منتخب کریں",
        lab_dd2: "گاڑی کی ذیلی قسم کو منتخب کریں",
        dd1: "آئٹم کیٹیگری",
        dd2: "گھریلوسامان",
        dd3: "فرنیچر",
        dd4: "کنٹینر",
        dd5: "ٹریلر",
        d2d1: "کلوگرام",
        d2d2: "لیٹر",
        d2d3: "نگ",
        d2d4: "ڈبہ",
        d2d5: "آئٹم وزن یونٹ",
        place_item_weight: "چیز کا وزن",
        lab_item_weight: "چیز کا وزن",
        lab_price_range: "قیمت کی حد",
        place_price_1: "کم از کم قیمت",
        place_price_2: "زیادہ سے زیادہ قیمت",
        place_from_time: "وقت اور تاریخ سے",
        place_to_time: "وقت اور تاریخ تک",
        place_contact_number: "رابطہ نمبر",
        lab_contact_number: "رابطہ نمبر",
        place_person_contact_number: "رابطہ شخص نمبر",
        lab_person_contact_number: "رابطہ شخص نمبر (اختیاری)",
        lab_insurance: "انشورنس کی ضرورت ہے؟",
        lab_video_upl: "اپنی سامان کی ویڈیو اپ لوڈ کریں",
        lab_pic_upl: "اپنی سامان کی تصویر اپ لوڈ کریں",
        btn_sb: " اپنے آرڈرکا اندراج کریں",
        search_btn: "کمپنیاں​/کنٹریکٹر تلاش کریں",
        track_btn: "گاڑیوں کی ٹریکنگ",
      });
    }
  }, [language]);

  useEffect(() => {
    api.get("userinfo").then((response) => {
      setdata(response.data.data.user);
      if (response.data.data.user.user_is === "client") {
        setimgurl(clients_url);
      } else if (response.data.data.user.user_is === "company") {
        setimgurl(company_url);
      } else if (response.data.data.user.user_is === "contractor") {
        setimgurl(contractor_url);
      } else if (response.data.data.user.user_is === "driver") {
        setimgurl(driver_url);
      } else if (response.data.data.user.user_is === "partner") {
        setimgurl(partner_url);
      }
    });
  }, []);

  /* FUNCTIONS */

  // Video Upload Function
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setCompanylogofile(event.target.files);
    }
  };
  // image to base64
  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      // Setting Complete Image in state
      setMultipleImageFile((MultipleImageFile) => [
        ...MultipleImageFile,
        reader.result,
      ]);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  // Multiple Image Function
  const onMultipleChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      // Setting Only The Preview URL For image Preview
      setMultipleImgPreview((MultiImg) => [
        ...MultiImg,
        URL.createObjectURL(e.target.files[0]),
      ]);

      // Image To Base64
      const multiimg = e.target.files[0];

      getBase64(multiimg);
    }
  };

  // Form Data Functions
  const handleOrderTitle = (e) => {
    setOrderTitle(e.target.value);
  };
  const handleOrderDesc = (e) => {
    setOrder_desc(e.target.value);
  };
  const handleRequestVehicle = (e) => {
    setRequested_vehicle(e.target.value);
  };
  const handleItemCategory = (e) => {
    setItemCategory(e.target.value);
  };
  const handleWeight = (e) => {
    setWeight(e.target.value);
  };
  const handleItemWeight = (e) => {
    setItemUnit(e.target.value);
  };
  const handleMinPrice = (e) => {
    setMinPrice(e.target.value);
  };
  const handleMaxPrice = (e) => {
    setMaxPrice(e.target.value);
  };
  const handleNumber = (e) => {
    setNumber(e.target.value);
  };
  const handlePersonNumber = (e) => {
    setPersonNumber(e.target.value);
  };
  const handleInsurance = (e) => {
    setinsReq(!insreq);
  };
  const handleSubType = (e) => {
    setsub_type(e.target.value);
  };
  const handleType = (e) => {
    setvehile_type(e.target.value);
  };
  const handleOrder = (e) => {
    e.preventDefault();
    let com_f_time = moment(from_time).format("MM/D/YYYY H:mm");
    let com_t_time = moment(to_time).format("MM/D/YYYY H:mm");

    if (order_title === "") {
      toast.error("Please Enter Order Title");
    } else if (order_desc === "") {
      toast.error("Please Enter Order Description");
    } else if (Object.keys(from_address).length === 0) {
      toast.error("Please Enter From Address");
    } else if (Object.keys(to_address).length === 0) {
      toast.error("Please Enter To Address");
    } else if (request_vehicle === "") {
      toast.error("Please Enter Requested Vehicle");
    } else if (!parseInt(request_vehicle)) {
      toast.error("Please Enter Correct Number Of Vehicle");
    } else if (item_category === "") {
      toast.error("Please Select Item Category");
    } else if (weight === "") {
      toast.error("Please Enter Item Weight");
    } else if (item_unit === "") {
      toast.error("Please Select Item Unit");
    } else if (!parseInt(weight)) {
      toast.error("Please Enter Correct Item Weight");
    } else if (min_price === "") {
      toast.error("Please Enter Minimum Price");
    } else if (!parseInt(min_price)) {
      toast.error("Please Enter Correct Minimum Price");
    } else if (max_price === "") {
      toast.error("Please Enter Maximum Price");
    } else if (!parseInt(max_price)) {
      toast.error("Please Enter Correct Minimum Price");
    } else if (parseInt(min_price) > parseInt(max_price)) {
      toast.error("Minimum Price Should Be Less Than Maximum");
    } else if (from_time_format === "") {
      toast.error("Please Enter From Time");
    } else if (!moment().isBefore(from_time)) {
      toast.error("Please Enter Correct From Time");
    } else if (to_time_format === "") {
      toast.error("Please Enter To Time");
    } else if (!moment().isBefore(to_time)) {
      toast.error("Please Enter Correct To Time");
    } else if (!moment(from_time).isBefore(to_time)) {
      toast.error("To Date Should Be After From Date");
    } else if (number === "") {
      toast.error("Please Enter Contact Number");
    } else if (!parseInt(number)) {
      toast.error("Please Enter Valid Contact Number");
    } else if (number.length !== 11) {
      toast.error("Please Enter Valid Contact Number");
    } 
    // else if (person_number === "") {
    //   toast.error("Please Enter Person Number");
    // } else if (!parseInt(person_number)) {
    //   toast.error("Please Enter Valid Person Number");
    // } else if (person_number.length !== 11) {
    //   toast.error("Please Enter Valid Person Number");
    // } 
    else if (companylogofile.length === 0) {
      toast.error("Please Upload Video Of Laguage");
    } else if (parseInt(companylogofile[0].size) > 15000000) {
      toast.error("Please Upload Video Less Than 15MB");
    } else if (MultipleImageFile.length === 0) {
      toast.error("Please Upload Images Of Laguage");
    } else {
      const formdata = new FormData();
      formdata.append("title", order_title);
      formdata.append("description", order_desc);
      formdata.append("address_from", JSON.stringify(from_address));
      formdata.append("address_to", JSON.stringify(to_address));
      formdata.append("requested_vehicle", request_vehicle);
      formdata.append("category", item_category);
      formdata.append("weight", weight);
      formdata.append("min_price", min_price);
      formdata.append("max_price", max_price);
      formdata.append("time_from", com_f_time);
      formdata.append("time_to", com_t_time);
      formdata.append("number", number);
      formdata.append("person_number", person_number);
      formdata.append("need_insurance", insreq);
      formdata.append("video", companylogofile[0]);

      MultipleImageFile.forEach((data) => {
        formdata.append("pic_gallery[]", data);
      });
      api
        .post("order/store", formdata)
        .then((res) => {
          toast.success("Order Created Successfully");
        })
        .then((res) => history.push("/user/dashboard"))
        .catch((err) => {
          toast.error("Something Went Wrong");
        });
    }
  };
  /* Component */
  const EngComponent = () => {
    return (
      <>
        {/* <p
          className="ml-3"
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            textAlign: "start",
          }}
        >
          {pageContent.lab_title}
        </p> */}
        <p className="mt-3" style={{ textAlign: "left", marginLeft: "20px" }}>
          <b className="mt-4">{pageContent.lab_title}</b> <br />
        </p>
        <input
          type="text"
          className="place-order-screen"
          placeholder={pageContent.place_title}
          value={order_title}
          onChange={handleOrderTitle}
        />
        <p className="mt-3" style={{ textAlign: "left", marginLeft: "20px" }}>
          <b className="mt-4">{pageContent.label_desc}</b> <br />
        </p>
        <textarea
          className="order-description"
          placeholder={pageContent.place_desc}
          value={order_desc}
          onChange={handleOrderDesc}
        ></textarea>
        <p className="mt-3" style={{ textAlign: "left", marginLeft: "20px" }}>
          <b className="mt-4">{pageContent.lab_from_add}</b> <br />
        </p>
        <Autocomplete
          apiKey={"AIzaSyBhY0h2Pn4t_JzwLAg1Q44YlFt5sYdyCxM"}
          className="place-order-screen"
          placeholder={pageContent.place_from_add}
          onPlaceSelected={(place) => {
            setfrom_address({
              address: place.formatted_address,
              add_lat: place.geometry.location.lat(),
              add_lng: place.geometry.location.lng(),
            });
          }}
          options={{
            ComponentRestrictions: { country: ["pk"] },
            types: [],
            offset: 200,
          }}
        />
        <br />
        <p className="mt-3" style={{ textAlign: "left", marginLeft: "20px" }}>
          <b className="mt-4">{pageContent.lab_to_add}</b> <br />
        </p>
        <Autocomplete
          apiKey={"AIzaSyBhY0h2Pn4t_JzwLAg1Q44YlFt5sYdyCxM"}
          className="place-order-screen"
          placeholder={pageContent.place_to_add}
          onPlaceSelected={(place) => {
            setto_address({
              address: place.formatted_address,
              add_lat: place.geometry.location.lat(),
              add_lng: place.geometry.location.lng(),
            });
          }}
          options={{
            ComponentRestrictions: { country: ["pk"] },
            types: [],
            offset: 200,
          }}
        />
        <p className="mt-3" style={{ textAlign: "left", marginLeft: "20px" }}>
          <b className="mt-4">{pageContent.lab_req_vehi}</b> <br />
        </p>
        <input
          type="text"
          className="place-order-screen"
          placeholder={pageContent.place_req_vehi}
          value={request_vehicle}
          onChange={handleRequestVehicle}
        />
        <MuiThemeProvider>
          <React.Fragment>
            <div className="row text-center">
              <div className="col-12 mt-3">
                <p className="text-left" style={{ paddingLeft: "22px" }}>
                  <b className="mt-4">{pageContent.lab_dd1}</b> <br />
                </p>
              </div>
              <div className="col-12" style={{marginTop: "-6%"}}>
                <br />
                <Select
                  labelId="demo-simple-select-placeholder-label-label"
                  id="demo-simple-select-placeholder-label"
                  value={vehile_type}
                  onChange={handleType}
                  displayEmpty
                  className="select-option"
                  style={{ width: "90%", paddingTop: "-30px" }}
                >
                  <MenuItem value="">
                    <em>None</em>{" "}
                  </MenuItem>
                  <MenuItem value="Small">
                    <span>
                      <img src={small} width="25" height="20" alt="" /> Small
                      Vehicle
                    </span>
                  </MenuItem>
                  <MenuItem value="Medium">
                    <span>
                      <img src={medium} width="25" height="20" alt="" /> Medium
                      Vehicle
                    </span>
                  </MenuItem>
                  <MenuItem value="Heavy">
                    <span>
                      <img src={heavy} width="25" height="20" alt="" /> Heavy
                      Vehicle
                    </span>
                  </MenuItem>
                </Select>
                {vehile_type === "" ? (
                  <></>
                ) : vehile_type === "Small" ? (
                  <div className="mt-3">
                    <div className="text-start mt-1">
                      <p className="text-left" style={{ paddingLeft: "22px" }}>
                        <b className="mt-4">{pageContent.lab_dd2}</b> <br />
                      </p>
                    </div>
                    <div className="" style={{marginTop:"-5%"}}>
                      <Select
                        labelId="demo-simple-select-placeholder-label-label"
                        id="demo-simple-select-placeholder-label"
                        value={sub_type}
                        onChange={handleSubType}
                        displayEmpty
                        className="select-option mt-3"
                        style={{ width: "90%" }}
                      >
                        <MenuItem value="">
                          <em>None</em>{" "}
                        </MenuItem>
                        <MenuItem value="Suzuki Closed">
                          <span>
                            <img src={suzuki_c} width="25" height="20" alt="" />{" "}
                            Suzuki Closed
                          </span>
                        </MenuItem>
                        <MenuItem value="Suzuki Open">
                          <span>
                            <img src={suzuki_o} width="25" height="20" alt="" />{" "}
                            Suzuki Open
                          </span>
                        </MenuItem>
                      </Select>
                    </div>
                  </div>
                ) : vehile_type === "Medium" ? (
                  <div className="mt-3">
                    <div className="text-start mt-1">
                    <p className="text-left" style={{ paddingLeft: "22px" }}>
                        <b className="mt-4">{pageContent.lab_dd2}</b> <br />
                      </p>
                    </div>
                    <div className="mt-1">
                      <Select
                        labelId="demo-simple-select-placeholder-label-label"
                        id="demo-simple-select-placeholder-label"
                        value={sub_type}
                        onChange={handleSubType}
                        displayEmpty
                        className="select-option"
                        style={{ width: "90%" }}
                      >
                        <MenuItem value="">
                          <em>None</em>{" "}
                        </MenuItem>
                        <MenuItem value="Shahzor Open">
                          <span>
                            <img
                              src={shahzor_o}
                              width="25"
                              height="20"
                              alt=""
                            />{" "}
                            Shahzor Open
                          </span>
                        </MenuItem>
                        <MenuItem value="Shahzor Closed">
                          <span>
                            <img
                              src={shahzor_c}
                              width="25"
                              height="20"
                              alt=""
                            />{" "}
                            Shahzor Closed
                          </span>
                        </MenuItem>
                        <MenuItem value="Mazda Open">
                          <span>
                            <img src={mazda_o} width="25" height="20" alt="" />{" "}
                            Mazda Open
                          </span>
                        </MenuItem>
                        <MenuItem value="Mazda Flatbed">
                          <span>
                            <img src={mazda_fl} width="25" height="20" alt="" />{" "}
                            Mazda Flatbed
                          </span>
                        </MenuItem>
                        <MenuItem value="Mazda Containerized">
                          <span>
                            <img
                              src={mazda_con}
                              width="25"
                              height="20"
                              alt=""
                            />{" "}
                            Mazda Containerized
                          </span>
                        </MenuItem>
                        <MenuItem value="Mazda Commercial 16ft">
                          <span>
                            <img
                              src={mazda_c_16}
                              width="25"
                              height="20"
                              alt=""
                            />{" "}
                            Mazda Commercial 16ft
                          </span>
                        </MenuItem>
                        <MenuItem value="Mazda Commercial 20ft">
                          <span>
                            <img
                              src={mazda_c_20}
                              width="25"
                              height="20"
                              alt=""
                            />{" "}
                            Mazda Commercial 20ft
                          </span>
                        </MenuItem>
                      </Select>
                    </div>
                  </div>
                ) : vehile_type === "Heavy" ? (
                  <div className="mt-3">
                    <div className="text-start mt-1">
                    <p className="text-left" style={{ paddingLeft: "22px" }}>
                        <b className="mt-4">{pageContent.lab_dd2}</b> <br />
                      </p>
                    </div>
                    <div className="mt-1">
                      <Select
                        labelId="demo-simple-select-placeholder-label-label"
                        id="demo-simple-select-placeholder-label"
                        // value={vehile_type}
                        // onChange={handleType}
                        value={sub_type}
                        onChange={handleSubType}
                        displayEmpty
                        className="select-option"
                        style={{ width: "90%" }}
                      >
                        <MenuItem value="">
                          <em>None</em>{" "}
                        </MenuItem>
                        <MenuItem value="20ft Containerized">
                          <span>
                            <img
                              src={heavy_c_20}
                              width="25"
                              height="20"
                              alt=""
                            />{" "}
                            20ft Containerized
                          </span>
                        </MenuItem>
                        <MenuItem value="20ft Flatbody">
                          <span>
                            <img
                              src={heavy_fb_20}
                              width="25"
                              height="20"
                              alt=""
                            />{" "}
                            20ft Flatbody
                          </span>
                        </MenuItem>
                        <MenuItem value="20ft Halfbed">
                          <span>
                            <img
                              src={heavy_hf_20}
                              width="25"
                              height="20"
                              alt=""
                            />{" "}
                            20ft Halfbed
                          </span>
                        </MenuItem>
                        <MenuItem value="40ft Containerized">
                          <span>
                            <img
                              src={heavy_c_40}
                              width="25"
                              height="20"
                              alt=""
                            />{" "}
                            40ft Containerized
                          </span>
                        </MenuItem>
                        <MenuItem value="40ft Flatbody">
                          <span>
                            <img
                              src={heavy_fb_40}
                              width="25"
                              height="20"
                              alt=""
                            />{" "}
                            40ft Flatbody
                          </span>
                        </MenuItem>
                        <MenuItem value="40ft Halfbed">
                          <span>
                            <img
                              src={heavy_hf_40}
                              width="25"
                              height="20"
                              alt=""
                            />{" "}
                            40ft Halfbed
                          </span>
                        </MenuItem>
                      </Select>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </React.Fragment>
        </MuiThemeProvider>
        <select
          className="Item-category-order"
          value={item_category}
          onChange={handleItemCategory}
        >
          <option>{pageContent.dd1}</option>
          <option>{pageContent.dd2}</option>
          <option>{pageContent.dd3}</option>
          <option>{pageContent.dd4}</option>
          <option>{pageContent.dd5}</option>
        </select>
        <div className="row">
          <div className="col-6 pl-4">
            <p
              className="mt-3"
              style={{ textAlign: "left", marginLeft: "10px" }}
            >
              <b className="mt-4">{pageContent.lab_item_weight}</b> <br />
            </p>
            <input
              type="text"
              className="place-order-screen"
              placeholder={pageContent.place_item_weight}
              value={weight}
              onChange={handleWeight}
            />
          </div>
          <div className="col-6 pr-4">
            <p
              className="mt-3"
              style={{ textAlign: "left", marginLeft: "20px" }}
            >
              <b className="mt-4">{pageContent.d2d5}</b>
            </p>
            <select
              className="Item-category-order"
              value={item_unit}
              style={{ marginTop: "-0%" }}
              onChange={handleItemWeight}
            >
              <option>{pageContent.d2d5}</option>
              <option>{pageContent.d2d1}</option>
              <option>{pageContent.d2d2}</option>
              <option>{pageContent.d2d3}</option>
              <option>{pageContent.d2d4}</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-6 pl-4">
            <p className="mt-3" style={{ textAlign: "left", marginLeft: "10px" }}>
              <b className="mt-4">{pageContent.place_price_1}</b> <br />
            </p>
            {/* <input
              type="text"
              className="place-order-screen-1"
              placeholder={pageContent.place_price_1}
              value={min_price}
              onChange={handleMinPrice}
            /> */}
            <select
          className="Item-category-order text-left"
          value={min_price}
          onChange={handleMinPrice}
          style={{marginTop: "-0%"}}
        >
          <option value="">{pageContent.place_price_1}</option>
          <option>5000</option>
          <option>10000</option>
          <option>15000</option>
          <option>20000</option>
          <option>25000</option>
          <option>30000</option>
          <option>35000</option>
          <option>40000</option>
          <option>45000</option>
          <option>50000</option>
          <option>55000</option>
          <option>60000</option>
          <option>65000</option>
          <option>70000</option>
          <option>75000</option>
          <option>80000</option>
          <option>85000</option>
          <option>90000</option>
          <option>95000</option>
          <option>100000</option>
        </select>
          </div>
          <div className="col-6 pr-4">
          <p className="mt-3" style={{ textAlign: "left", marginLeft: "10px" }}>
              <b className="mt-4">{pageContent.place_price_2}</b> <br />
            </p>
            {/* <input
              type="text"
              className="place-order-screen-1"
              placeholder={pageContent.place_price_2}
              value={max_price}
              onChange={handleMaxPrice}
            /> */}
                        <select
          className="Item-category-order text-left"
          value={max_price}
          onChange={handleMaxPrice}
          style={{marginTop: "-0%"}}
        >
          <option value="">{pageContent.place_price_2}</option>
          <option>5000</option>
          <option>10000</option>
          <option>15000</option>
          <option>20000</option>
          <option>25000</option>
          <option>30000</option>
          <option>35000</option>
          <option>40000</option>
          <option>45000</option>
          <option>50000</option>
          <option>55000</option>
          <option>60000</option>
          <option>65000</option>
          <option>70000</option>
          <option>75000</option>
          <option>80000</option>
          <option>85000</option>
          <option>90000</option>
          <option>95000</option>
          <option>100000</option>
        </select>
          </div>
        </div>
        <p className="mt-3" style={{ textAlign: "left", marginLeft: "20px" }}>
          <b className="mt-4">{pageContent.from_time_lab}</b> <br />
        </p>
        <DateTimePicker
          className="place-order-screen"
          onChange={(date) => {
            setFromTime(date);
            setFromTimeFormat(moment(date).format("MM/D/YYYY H:mm"));
          }}
          value={from_time}
        />
        <p className="mt-3" style={{ textAlign: "left", marginLeft: "20px" }}>
          <b className="mt-4">{pageContent.to_time_lab}</b> <br />
        </p>
        <DateTimePicker
          className="place-order-screen"
          onChange={(date) => {
            setToTime(date);
            setToTimeFormat(moment(date).format("MM/D/YYYY H:mm"));
          }}
          value={to_time}
        />
        <p className="mt-3" style={{ textAlign: "left", marginLeft: "20px" }}>
          <b className="mt-4">{pageContent.lab_contact_number}</b> <br />
        </p>
        <input
          type="text"
          className="place-order-screen"
          placeholder={pageContent.place_contact_number}
          value={number}
          onChange={handleNumber}
        />
        <p className="mt-3" style={{ textAlign: "left", marginLeft: "20px" }}>
          <b className="mt-4">{pageContent.lab_person_contact_number}</b> <br />
        </p>
        <input
          type="text"
          className="place-order-screen"
          placeholder={pageContent.place_person_contact_number}
          value={person_number}
          onChange={handlePersonNumber}
        />
        <div className="row mt-3">
          <div className="col-6 text-left" style={{ paddingLeft: "33px" }}>
            <b>{pageContent.lab_insurance}</b>
          </div>
          <div className="col-6">
            <span style={{ marginLeft: "70px" }}>
              <Toggle defaultChecked={insreq} onChange={handleInsurance} />
            </span>
          </div>
        </div>
        <p className="mt-3" style={{ textAlign: "left", marginLeft: "20px" }}>
          <b className="mt-4">{pageContent.lab_video_upl}</b> <br />
        </p>
        <div className="">
          {companylogofile === "" ? (
            <div className="logo-container-placeorder">
              <label htmlFor="filePicker" className="text-left">
                <img src={upload} alt="Preview" className="upload-img-btn" />
              </label>
              <input
                id="filePicker"
                accept="video/*"
                style={{ visibility: "hidden" }}
                type={"file"}
                onChange={onImageChange}
              ></input>
            </div>
          ) : (
            <div className="">
              <label htmlFor="filePicker">
                <p className="text-start pl-3" style={{ textAlign: "start" }}>
                  <b>{companylogofile[0].name}</b>
                </p>
              </label>
              <input
                id="filePicker"
                accept="video/*"
                style={{ visibility: "hidden" }}
                type={"file"}
                onChange={onImageChange}
              ></input>
            </div>
          )}
        </div>
        <p className="mt-3" style={{ textAlign: "left", marginLeft: "20px" }}>
          <b className="mt-4">{pageContent.lab_pic_upl}</b> <br />
        </p>
        <div className="multiple-image-container p-3 col-12 text-left">
          {MultiImgPreview.map(function (img, index) {
            return (
              <div className="main-content-img" key={index}>
                <img
                  key={index}
                  id="target"
                  src={img}
                  className="multiple-company-image pl-2"
                  alt="lmage"
                />
              </div>
            );
          })}
          <label htmlFor="filePickermultiple" className="col-3">
            <img src={upload} alt="Preview" className="upload-img-btn" />
          </label>
          <input
            id="filePickermultiple"
            accept="image/png, image/gif, image/jpeg"
            style={{ visibility: "hidden" }}
            type={"file"}
            onChange={onMultipleChange}
            className="image-input-btn"
          />
        </div>
      </>
    );
  };
  const UrduComponent = () => {
    return (
      <>
        <p className="text-right pr-4 mt-3">
          <b className="mt-4">{pageContent.lab_title}</b> <br />
        </p>
        <input
          type="text"
          className="place-order-screen"
          placeholder={pageContent.place_title}
          value={order_title}
          onChange={handleOrderTitle}
          dir="rtl"
        />
        <p className="text-right pr-4 mt-3">
          <b className="mt-4">{pageContent.lab_desc}</b> <br />
        </p>
        <textarea
          className="order-description"
          placeholder={pageContent.place_desc}
          value={order_desc}
          onChange={handleOrderDesc}
          dir="rtl"
        />
        <p className="col-12 text-right mt-2 pr-4">
          <b className="text-right">{pageContent.lab_from_add}</b>
        </p>
        <Autocomplete
          apiKey={"AIzaSyBhY0h2Pn4t_JzwLAg1Q44YlFt5sYdyCxM"}
          className="place-order-screen"
          placeholder={pageContent.place_from_add}
          dir="rtl"
          onPlaceSelected={(place) => {
            setfrom_address({
              address: place.formatted_address,
              add_lat: place.geometry.location.lat(),
              add_lng: place.geometry.location.lng(),
            });
          }}
          options={{
            ComponentRestrictions: { country: ["pk"] },
            types: [],
            offset: 200,
          }}
        />
        <br />
        <p className="col-12 text-right mt-3 pr-4">
          <b className="text-right">{pageContent.lab_to_add}</b>
        </p>
        <Autocomplete
          apiKey={"AIzaSyBhY0h2Pn4t_JzwLAg1Q44YlFt5sYdyCxM"}
          className="place-order-screen"
          placeholder={pageContent.place_to_add}
          dir="rtl"
          onPlaceSelected={(place) => {
            setto_address({
              address: place.formatted_address,
              add_lat: place.geometry.location.lat(),
              add_lng: place.geometry.location.lng(),
            });
          }}
          options={{
            ComponentRestrictions: { country: ["pk"] },
            types: [],
            offset: 200,
          }}
        />
        <p className="text-right pr-4 mt-3">
          <b className="mt-4">{pageContent.lab_req_vehi}</b> <br />
        </p>
        <input
          type="text"
          className="place-order-screen"
          placeholder={pageContent.place_req_vehi}
          value={request_vehicle}
          onChange={handleRequestVehicle}
          dir="rtl"
        />
        <MuiThemeProvider>
          <React.Fragment>
            <div className="row text-center">
              <div className="col-12">
                <p className="text-right mr-4 mt-3" style={{ paddingLeft: "22px" }}>
                  <b className="mt-4">{pageContent.lab_dd1}</b> <br />
                </p>
              </div>
              <div className="col-12" style={{marginTop: "-6%"}}>
                <br />
                <Select
                  labelId="demo-simple-select-placeholder-label-label"
                  id="demo-simple-select-placeholder-label"
                  value={vehile_type}
                  onChange={handleType}
                  displayEmpty
                  className="select-option"
                  style={{ width: "90%"}}
                >
                  <MenuItem value="">
                    <em>None</em>{" "}
                  </MenuItem>
                  <MenuItem value="Small">
                    <span>
                      <img src={small} width="25" height="20" alt="" /> Small
                      Vehicle
                    </span>
                  </MenuItem>
                  <MenuItem value="Medium">
                    <span>
                      <img src={medium} width="25" height="20" alt="" /> Medium
                      Vehicle
                    </span>
                  </MenuItem>
                  <MenuItem value="Heavy">
                    <span>
                      <img src={heavy} width="25" height="20" alt="" /> Heavy
                      Vehicle
                    </span>
                  </MenuItem>
                </Select>
                {vehile_type === "" ? (
                  <></>
                ) : vehile_type === "Small" ? (
                  <div className="mt-3">
                    <div className="text-start mt-1">
                      <p className="text-right mr-4 mt-3" style={{ paddingLeft: "22px" }}>
                        <b className="mt-4">{pageContent.lab_dd2}</b> <br />
                      </p>
                    </div>
                    <div className="" style={{marginTop:'-2%'}}>
                      <Select
                        labelId="demo-simple-select-placeholder-label-label"
                        id="demo-simple-select-placeholder-label"
                        value={sub_type}
                        onChange={handleSubType}
                        displayEmpty
                        className="select-option mt-3"
                        style={{ width: "90%" }}
                      >
                        <MenuItem value="">
                          <em>None</em>{" "}
                        </MenuItem>
                        <MenuItem value="Suzuki Closed">
                          <span>
                            <img src={suzuki_c} width="25" height="20" alt="" />{" "}
                            Suzuki Closed
                          </span>
                        </MenuItem>
                        <MenuItem value="Suzuki Open">
                          <span>
                            <img src={suzuki_o} width="25" height="20" alt="" />{" "}
                            Suzuki Open
                          </span>
                        </MenuItem>
                      </Select>
                    </div>
                  </div>
                ) : vehile_type === "Medium" ? (
                  <div className="mt-3">
                    <div className="text-start mt-1">
                    <p className="text-right mr-4 mt-3" style={{ paddingLeft: "22px" }}>
                        <b className="mt-4">{pageContent.lab_dd2}</b> <br />
                      </p>
                    </div>
                    <div className="mt-1">
                      <Select
                        labelId="demo-simple-select-placeholder-label-label"
                        id="demo-simple-select-placeholder-label"
                        value={sub_type}
                        onChange={handleSubType}
                        displayEmpty
                        className="select-option"
                        style={{ width: "90%" }}
                      >
                        <MenuItem value="">
                          <em>None</em>{" "}
                        </MenuItem>
                        <MenuItem value="Shahzor Open">
                          <span>
                            <img
                              src={shahzor_o}
                              width="25"
                              height="20"
                              alt=""
                            />{" "}
                            Shahzor Open
                          </span>
                        </MenuItem>
                        <MenuItem value="Shahzor Closed">
                          <span>
                            <img
                              src={shahzor_c}
                              width="25"
                              height="20"
                              alt=""
                            />{" "}
                            Shahzor Closed
                          </span>
                        </MenuItem>
                        <MenuItem value="Mazda Open">
                          <span>
                            <img src={mazda_o} width="25" height="20" alt="" />{" "}
                            Mazda Open
                          </span>
                        </MenuItem>
                        <MenuItem value="Mazda Flatbed">
                          <span>
                            <img src={mazda_fl} width="25" height="20" alt="" />{" "}
                            Mazda Flatbed
                          </span>
                        </MenuItem>
                        <MenuItem value="Mazda Containerized">
                          <span>
                            <img
                              src={mazda_con}
                              width="25"
                              height="20"
                              alt=""
                            />{" "}
                            Mazda Containerized
                          </span>
                        </MenuItem>
                        <MenuItem value="Mazda Commercial 16ft">
                          <span>
                            <img
                              src={mazda_c_16}
                              width="25"
                              height="20"
                              alt=""
                            />{" "}
                            Mazda Commercial 16ft
                          </span>
                        </MenuItem>
                        <MenuItem value="Mazda Commercial 20ft">
                          <span>
                            <img
                              src={mazda_c_20}
                              width="25"
                              height="20"
                              alt=""
                            />{" "}
                            Mazda Commercial 20ft
                          </span>
                        </MenuItem>
                      </Select>
                    </div>
                  </div>
                ) : vehile_type === "Heavy" ? (
                  <div className="mt-3">
                    <div className="text-start mt-1">
                    <p className="text-right mr-4 mt-3" style={{ paddingLeft: "22px" }}>
                        <b className="mt-4">{pageContent.lab_dd2}</b> <br />
                      </p>
                    </div>
                    <div className="mt-1">
                      <Select
                        labelId="demo-simple-select-placeholder-label-label"
                        id="demo-simple-select-placeholder-label"
                        // value={vehile_type}
                        // onChange={handleType}
                        value={sub_type}
                        onChange={handleSubType}
                        displayEmpty
                        className="select-option"
                        style={{ width: "90%" }}
                      >
                        <MenuItem value="">
                          <em>None</em>{" "}
                        </MenuItem>
                        <MenuItem value="20ft Containerized">
                          <span>
                            <img
                              src={heavy_c_20}
                              width="25"
                              height="20"
                              alt=""
                            />{" "}
                            20ft Containerized
                          </span>
                        </MenuItem>
                        <MenuItem value="20ft Flatbody">
                          <span>
                            <img
                              src={heavy_fb_20}
                              width="25"
                              height="20"
                              alt=""
                            />{" "}
                            20ft Flatbody
                          </span>
                        </MenuItem>
                        <MenuItem value="20ft Halfbed">
                          <span>
                            <img
                              src={heavy_hf_20}
                              width="25"
                              height="20"
                              alt=""
                            />{" "}
                            20ft Halfbed
                          </span>
                        </MenuItem>
                        <MenuItem value="40ft Containerized">
                          <span>
                            <img
                              src={heavy_c_40}
                              width="25"
                              height="20"
                              alt=""
                            />{" "}
                            40ft Containerized
                          </span>
                        </MenuItem>
                        <MenuItem value="40ft Flatbody">
                          <span>
                            <img
                              src={heavy_fb_40}
                              width="25"
                              height="20"
                              alt=""
                            />{" "}
                            40ft Flatbody
                          </span>
                        </MenuItem>
                        <MenuItem value="40ft Halfbed">
                          <span>
                            <img
                              src={heavy_hf_40}
                              width="25"
                              height="20"
                              alt=""
                            />{" "}
                            40ft Halfbed
                          </span>
                        </MenuItem>
                      </Select>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </React.Fragment>
        </MuiThemeProvider>
        <select
          className="Item-category-order text-right"
          value={item_category}
          onChange={handleItemCategory}
        >
          <option>{pageContent.dd1}</option>
          <option>{pageContent.dd2}</option>
          <option>{pageContent.dd3}</option>
          <option>{pageContent.dd4}</option>
          <option>{pageContent.dd5}</option>
        </select>
        <div className="row">
          <div className="col-6">
            <p className="text-right pr-4 mt-3">
              <b className="mt-4">{pageContent.lab_item_weight}</b> <br />
            </p>
            <input
              type="text"
              className="place-order-screen"
              placeholder={pageContent.place_item_weight}
              value={weight}
              onChange={handleWeight}
              dir="rtl"
            />
          </div>
          <div className="col-6">
            <p className="text-right pr-4 mt-3">
              <b className="mt-4">{pageContent.d2d5}</b>
            </p>
            <select
              className="Item-category-order text-right"
              value={item_unit}
              onChange={handleItemWeight}
              style={{ marginTop: "-0%" }}
            >
              <option>{pageContent.d2d5}</option>
              <option>{pageContent.d2d1}</option>
              <option>{pageContent.d2d2}</option>
              <option>{pageContent.d2d3}</option>
              <option>{pageContent.d2d4}</option>
            </select>
          </div>
        </div>
        {/* <p className="text-start mt-3 pr-4" style={{ textAlign: "right" }}>
          <b>{pageContent.lab_price_range}</b>
        </p> */}
        {/* <div className="row">
          <div className="col-5">
            <input
              type="text"
              className="place-order-screen-1"
              placeholder={pageContent.place_price_1}
              value={min_price}
              onChange={handleMinPrice}
              dir="rtl"
            />
          </div>
          <div className="col-5">
            <input
              type="text"
              className="place-order-screen-1"
              placeholder={pageContent.place_price_2}
              value={max_price}
              onChange={handleMaxPrice}
              dir="rtl"
              style={{ marginLeft: "50px" }}
            />
          </div>
        </div> */}
                <div className="row">
          <div className="col-6 pl-4">
            <p className="mt-3" style={{ textAlign: "left", marginLeft: "20px" }}>
              <b className="mt-4">{pageContent.place_price_1}</b> <br />
            </p>
            {/* <input
              type="text"
              className="place-order-screen-1"
              placeholder={pageContent.place_price_1}
              value={min_price}
              onChange={handleMinPrice}
            /> */}
            <select
          className="Item-category-order text-left"
          value={min_price}
          onChange={handleMinPrice}
          style={{marginTop: "-0%"}}
        >
          <option value="">{pageContent.place_price_1}</option>
          <option>5000</option>
          <option>10000</option>
          <option>15000</option>
          <option>20000</option>
          <option>25000</option>
          <option>30000</option>
          <option>35000</option>
          <option>40000</option>
          <option>45000</option>
          <option>50000</option>
          <option>55000</option>
          <option>60000</option>
          <option>65000</option>
          <option>70000</option>
          <option>75000</option>
          <option>80000</option>
          <option>85000</option>
          <option>90000</option>
          <option>95000</option>
          <option>100000</option>
        </select>
          </div>
          <div className="col-6 pr-4">
          <p className="mt-3" style={{ textAlign: "left", marginLeft: "20px" }}>
              <b className="mt-4">{pageContent.place_price_2}</b> <br />
            </p>
            {/* <input
              type="text"
              className="place-order-screen-1"
              placeholder={pageContent.place_price_2}
              value={max_price}
              onChange={handleMaxPrice}
            /> */}
                        <select
          className="Item-category-order text-left"
          value={max_price}
          onChange={handleMaxPrice}
          style={{marginTop: "-0%"}}
        >
          <option value="">{pageContent.place_price_2}</option>
          <option>5000</option>
          <option>10000</option>
          <option>15000</option>
          <option>20000</option>
          <option>25000</option>
          <option>30000</option>
          <option>35000</option>
          <option>40000</option>
          <option>45000</option>
          <option>50000</option>
          <option>55000</option>
          <option>60000</option>
          <option>65000</option>
          <option>70000</option>
          <option>75000</option>
          <option>80000</option>
          <option>85000</option>
          <option>90000</option>
          <option>95000</option>
          <option>100000</option>
        </select>
          </div>
        </div>
        <p className="text-start mt-3 pr-4" style={{ textAlign: "right" }}>
          <b>{pageContent.from_time_lab}</b>
        </p>
        <DateTimePicker
          className="place-order-screen"
          onChange={(date) => {
            setFromTime(date);
            setFromTimeFormat(moment(date).format("MM/D/YYYY H:mm"));
          }}
          value={from_time}
        />
        <p className="text-start mt-3 pr-4" style={{ textAlign: "right" }}>
          <b>{pageContent.to_time_lab}</b>
        </p>
        <DateTimePicker
          className="place-order-screen"
          onChange={(date) => {
            setToTime(date);
            setToTimeFormat(moment(date).format("MM/D/YYYY H:mm"));
          }}
          value={to_time}
        />
        <p className="text-start mt-3 pr-4" style={{ textAlign: "right" }}>
          <b>{pageContent.lab_contact_number}</b>
        </p>
        <input
          type="text"
          className="place-order-screen"
          placeholder={pageContent.place_contact_number}
          value={number}
          onChange={handleNumber}
          dir="rtl"
        />
        <p className="text-start mt-3 pr-4" style={{ textAlign: "right" }}>
          <b>{pageContent.lab_person_contact_number}</b>
        </p>
        <input
          type="text"
          className="place-order-screen"
          placeholder={pageContent.place_person_contact_number}
          value={person_number}
          onChange={handlePersonNumber}
          dir="rtl"
        />
        <div className="row mt-3">
          <div className="col-6 text-left pl-5">
            <span>
              <Toggle defaultChecked={insreq} onChange={handleInsurance} />
            </span>
          </div>
          <div className="col-6">
            <b className="pr-2" style={{ fontSize: "14px" }}>
              {pageContent.lab_insurance}
            </b>
          </div>
        </div>
        <p className="text-start mt-3 pr-4" style={{ textAlign: "right" }}>
          <b>{pageContent.lab_video_upl}</b>
        </p>
        <div className="">
          {companylogofile === "" ? (
            <div className="logo-container-placeorder">
              <label htmlFor="filePicker" className="text-left">
                <img src={upload} alt="Preview" className="upload-img-btn" />
              </label>
              <input
                id="filePicker"
                accept="video/*"
                style={{ visibility: "hidden" }}
                type={"file"}
                onChange={onImageChange}
              ></input>
            </div>
          ) : (
            <div className="">
              <label htmlFor="filePicker">
                <p className="text-right pr-3" style={{ textAlign: "start" }}>
                  <b>{companylogofile[0].name}</b>
                </p>
              </label>
              <input
                id="filePicker"
                accept="video/*"
                style={{ visibility: "hidden" }}
                type={"file"}
                onChange={onImageChange}
              ></input>
            </div>
          )}
        </div>
        <p className="text-start mt-3 pr-4" style={{ textAlign: "right" }}>
          <b>{pageContent.lab_pic_upl}</b>
        </p>
        <div className="multiple-image-container p-3 col-12 text-left">
          {MultiImgPreview.map(function (img, index) {
            return (
              <div className="main-content-img" key={index}>
                <img
                  key={index}
                  id="target"
                  src={img}
                  className="multiple-company-image pl-2"
                  alt="lmage"
                />
              </div>
            );
          })}
          <label htmlFor="filePickermultiple" className="col-3">
            <img src={upload} alt="Preview" className="upload-img-btn" />
          </label>
          <input
            id="filePickermultiple"
            accept="image/png, image/gif, image/jpeg"
            style={{ visibility: "hidden" }}
            type={"file"}
            onChange={onMultipleChange}
            className="image-input-btn"
          />
        </div>
      </>
    );
  };
  return (
    <>
      {data === "" ? (
        <PreLoader text="Loading..." />
      ) : (
        <div
          className="container"
          style={{ position: "relative", minHeight: "100vh" }}
        >
          <div className="row main-div-dashboard">
            <div className="col-12" style={{ zIndex: "20" }}>
              <span className="lan-dropdown">
                <Language value={setlanguage} curr={language} />
              </span>
              <span className="back-btn">
                <IoIcons.IoIosArrowRoundBack
                  fill="#fff"
                  style={{ width: "89px", height: "60px" }}
                  onClick={() => history.push("/user/dashboard")}
                />
              </span>
              <HiIcons.HiDotsVertical
                fill="#fff"
                style={{ height: "27px", width: "70px" }}
                className="menu-btn"
              />
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-12 text-center">
                  <img
                    src={`${imgurl}${data.picture}`}
                    alt=""
                    className="dash-img mt-3"
                  />
                  <div className="">
                    <span className="h6 font-weight-bold text-white">
                      {data.name}
                    </span>
                    <br />
                    <br />
                    <span
                      className="place-order-btn"
                      style={{ backgroundColor: "#13b004", color: "#fff" }}
                    >
                      {pageContent.main_btn}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row " style={{ marginTop: "25%" }}>
                <div className="col-12">
                  <div>
                    <div className="text-center">
                      {language === "Ur" ? (
                        <>{UrduComponent()}</>
                      ) : (
                        <>{EngComponent()}</>
                      )}
                    </div>

                    <div className="text-center">
                      <button
                        className="mt-3 place-order-now-btn"
                        onClick={handleOrder}
                      >
                        {pageContent.btn_sb}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ height: "100px", border: "2px solid white" }}></div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card bottom-search-car">
                <div className="row mt-3">
                  <div
                    className="col-7"
                    style={{ marginLeft: "8px" }}
                    onClick={() => {
                      // history.push("/user/offer");
                    }}
                  >
                    <img
                      src={search}
                      alt=""
                      style={{ height: "27px", width: "28px" }}
                    />
                    <span style={{ fontSize: "13px", marginLeft: "10px" }}>
                      {pageContent.search_btn}
                    </span>
                  </div>
                  <div
                    className="col-5"
                    style={{ textAlign: "end", marginLeft: "-18px" }}
                    onClick={() => history.push("/user/tracking/")}
                  >
                    <img
                      src={car}
                      alt=""
                      style={{ height: "27px", width: "28px" }}
                    />
                    <span
                      style={{
                        fontSize: "13px",
                        marginLeft: "8px",
                        marginRight: "8px",
                      }}
                    >
                      {pageContent.track_btn}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default PlaceOrder;
