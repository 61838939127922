import React, { useState, useEffect } from "react";
import "./Digit.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OtpInput from "react-otp-input";
import { useHistory } from "react-router-dom";
import * as IoIcons from "react-icons/io";
import * as HiIcons from "react-icons/hi";
import Login from "../Form/Login";
import Language from "../Language/Language";

const DigitCode = (props) => {
  // console.log(props.serverotp, 'printing');
  const [displogin, setDispLogin] = useState(false);
  const [language, setlanguage] = useState("");
  const [pageContent, setPageContent] = useState({});
  const history = useHistory();

  // State
  const [otp, setotp] = useState(null);

  /* HOOKS */
  useEffect(() => {
    const lang = localStorage.getItem("language");
    setlanguage(lang);
  }, []);

  useEffect(() => {
    if (language === "En") {
      setPageContent({
        mainHeading: "Sendit",
        subHeading: "We move you",
        EnterMob: "Please enter 6 Digit Code",
        btn_txt: "Resend Code",
      });
    } else if (language === "Ur") {
      setPageContent({
        mainHeading: "سینڈاِٹ",
        subHeading: "وی موویو",
        EnterMob: " چھ ہندسوں کوڈ درج کریں",
        btn_txt: "کوڈپھرسے حاصل کرنے کیلئے یہاں کلک کریں",
      });
    }
  }, [language]);

  //  handle resend OTP
  const handleClick = (e) => {
    e.preventDefault();
  };

  // OTP Handler
  const handleChange = (otp) => {
    setotp(otp);
    // console.log(otp);
    // console.log(props.serverotp);
    if (otp.length === 6) {
      localStorage.setItem("otp", otp);
      if (parseInt(props.serverotp) === parseInt(otp)) {
        history.push("/login/");
      } else {
        toast.error("OTP Does Not Match");
      }
    }
    // Validation For OTP Here
  };

  return displogin ? (
    <Login />
  ) : (
    <>
      <div className="main-div">
        <span className="lan-dropdown">
          <Language value={setlanguage} curr={language} />
        </span>
        <span className="back-btn">
          <IoIcons.IoIosArrowRoundBack
            fill="#fff"
            style={{ width: "89px", height: "60px" }}
            onClick={() => setDispLogin(true)}
          />
        </span>
        <HiIcons.HiDotsVertical
          fill="#fff"
          style={{ height: "27px", width: "70px" }}
          className="menu-btn"
        />
        <h2 className="mainheading">{pageContent.mainHeading}</h2>
        <h4 className="subheading">{pageContent.subHeading}</h4>

        <div className="card col-11 ml-3 mt-5 login-card">
          <p className="mt-4 mblhead">{pageContent.EnterMob}</p>

          <div className="card col-11 ml-3 mt-5 opt-container">
            <OtpInput
              value={otp}
              onChange={handleChange}
              numInputs={6}
              isInputNum={true}
              separator={<span>-</span>}
              inputStyle="otp-input"
              containerStyle="otp-container-style"
              shouldAutoFocus={true}
            />
          </div>
          <br />

          <input
            type="button"
            className="resend-btn"
            value={pageContent.btn_txt}
            onClick={handleClick}
          />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default DigitCode;
