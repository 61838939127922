import React, { useEffect, useState } from "react";
import "./UserDashboard.css";
import DriverDashboard from "./Driver/DriverDashboard";
import UserDashboard from "./User/UserDashboard";
import CompanyDashboard from "./Company/CompanyDashboard";
import ContractorDashboard from "./Contractor/ContractorDashboard";
import api from "../../library/api";

const Dashboard = () => {
  const [data, setdata] = useState("");

  useEffect(() => {
    api.get("userinfo").then((response) => {
      // console.log(response);
      setdata(response.data.data.user);
    });
  }, []);
  if (data.user_is === "client") {
    return (
      <>
        <UserDashboard />
      </>
    );
  }
  if (data.user_is === "contractor") {
    return (
      <>
        <ContractorDashboard />
      </>
    );
  }
  if (data.user_is === "driver") {
    return <DriverDashboard />;
  } else {
    return <CompanyDashboard />;
  }
};

export default Dashboard;
