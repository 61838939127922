const liveUrl = "https://sendit.enigmapvtltd.com/assets/uploads";
// const localUrl = "http://127.0.0.1:8000/assets/uploads";
// const localUrl = "http://192.168.0.99:8000/assets/uploads";
// const videoUrlLocal = "http://192.168.0.99:8000/";
// const videoUrlLocal = "http://127.0.0.1:8000";
// const ImgUrlLocal = "http://127.0.0.1:8000";
// const ImgUrlLocal = "http://192.168.0.99:8000/";
const videoUrlLive = "https://sendit.enigmapvtltd.com/";
const ImgUrlLive = "https://sendit.enigmapvtltd.com/";

const server = liveUrl;
const video = videoUrlLive;
const img = ImgUrlLive;

module.exports = {
    driver_url: `${server}/drivers/`,
    clients_url: `${server}/clients/`,
    contractor_url: `${server}/contractor/`,
    company_url: `${server}/company/`,
    partner_url: `${server}/partner/`,
    order_video: `${video}`,
    order_img: `${img}`
}