import React, {useEffect} from "react";
import "./language.css";

const Language = (props) => {
  // function
  useEffect(() => {
    const lang = localStorage.getItem('language');
    if(lang === undefined || lang === null || lang === ""){
      localStorage.setItem('language', 'En');
    }
  }, [])
  const handleClick = (e) => {
    props.value(e.target.value);
    localStorage.setItem("language", e.target.value);
  };
  //Reutrn
  return (
    <>
      <select className="select-lan" onChange={handleClick}>
        <option selected={props.curr === "En" ? true : false} value="En">En</option>
        <option selected={props.curr === "Ur" ? true : false} value="Ur">Ur</option>
      </select>
    </>
  );
};

export default Language;
