import React,{useState, useEffect} from "react";
import car from "../../../assets/car.png";
import search from "../../../assets/search.png";
import * as IoIcons from "react-icons/io";
import * as HiIcons from "react-icons/hi";
import { useHistory } from "react-router-dom";
import license from "../../../assets/haider.jpg";
import "./Order.css";
import Language from "../../Language/Language";

const AcceptOrder = () => {
  const history = useHistory();
  const [language, setlanguage] = useState("");
  const [pageContent, setPageContent] = useState({});

  /* HOOKS */
  useEffect(() => {
    const lang = localStorage.getItem("language");
    setlanguage(lang);
  }, []);

  useEffect(() => {
    if (language === "En") {
      setPageContent({
        btn_1: "Accept",
        btn_2: "Reject",
        search_order: "Search Orders",
        track_vehicle: "Track Vehicles",
      });
    } else if (language === "Ur") {
      setPageContent({
        btn_1: "قبول کریں",
        btn_2: "رد کریں",
        search_order: "آرڈرز تلاش کریں",
        track_vehicle: "گاڑیوں کی ٹریکنگ",
      });
    }
  }, [language]);

  return (
    <>
      <div className="container">
        <div className="row main-div-dashboard-accept">
          <div className="col-12" style={{ zIndex: "20" }}>
          <span className="lan-dropdown" style={{ zIndex: "30" }}>
              <Language value={setlanguage} curr={language} />
            </span>
            <span className="back-btn">
              <IoIcons.IoIosArrowRoundBack
                fill="#fff"
                style={{ width: "89px", height: "60px", zIndex: "10" }}
                onClick={() => history.push("/user/dashboard/")}
              />
            </span>
            <HiIcons.HiDotsVertical
              fill="#fff"
              style={{ height: "27px", width: "70px" }}
              className="menu-btn"
            />
          </div>
          <div className="col-12" style={{ marginTop: "-60px" }}>
            <div className="row">
              <div className="col-12 text-center">
                <div className="row">
                  <div className="col-12 text-center">
                    <img src={license} alt="" className="dash-img" />
                    <div className="row">
                      <div className="col-12 text-center text-white">
                        <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                          Welcome Uber Freight Pvt Ltd
                        </span>
                        <br />
                        <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                          #7796760004
                        </span>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card col-12 mt-4 accept-order-desc">
          <p className="mt-4">
            We are a company that need its shipment to be delivered Islamabad to
            Karachi port within 2 days. Mostly seeds and furniture to be
            transported. We need a contractor or company to fulfills our need as
            soon as possible. Please contact us for more details. Pardon for the
            typos.
            <br />
            Vehicles Required
            <br /> Trucks = 8<br /> Containers for seed =12
            <br /> Bred ford for food items= 20
            <br /> Note: All drivers are required to use send-it app for
            tracking
            <br />
            <br />
            Vehicles Required: 08 26 July 8:00 am to 12:00 pm
          </p>
          <div className="row">
          <div className="col-6"><button className="accept-order-btn">{pageContent.btn_1}</button></div>
          <div className="col-6"><button className="reject-order-btn">{pageContent.btn_2}</button></div>
          </div>
        </div>

        <div style={{ height: "100px", border: "2px solid white" }}></div>

        <div className="row">
          <div className="col-11">
            <div className="card bottom-search-car">
              <div className="row show-pointer mt-3">
                <div
                  className="col-7"
                  style={{ marginLeft: "8px" }}
                  // onClick={() => history.push("/user/offer/")}
                >
                  <img
                    src={search}
                    alt=""
                    style={{ height: "27px", width: "28px" }}
                  />
                  <span style={{ fontSize: "13px", marginLeft: "10px" }}>
                    {pageContent.search_order}
                  </span>
                </div>
                <div
                  className="col-5"
                  style={{ textAlign: "end", marginLeft: "-18px" }}
                  onClick={() => history.push("/user/tracking/")}
                >
                  <img
                    src={car}
                    alt=""
                    style={{ height: "27px", width: "28px" }}
                  />
                  <span
                    style={{
                      fontSize: "13px",
                      marginLeft: "8px",
                      marginRight: "8px",
                    }}
                  >
                    {pageContent.track_vehicle}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AcceptOrder;
