import React, { useState, useEffect } from "react";
import * as IoIcons from "react-icons/io";
import * as HiIcons from "react-icons/hi";
import { useHistory } from "react-router-dom";
import upload from "../../../assets/upload_image.jpeg";
import car from "../../../assets/car.png";
import search from "../../../assets/search.png";
import "./Register_truck.css";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import MenuItem from "material-ui/MenuItem";
import Select from "@material-ui/core/Select";
import small from "../../../assets/Vehicle_Type/Small Pickups/suzuki_closed.png";
import medium from "../../../assets/Vehicle_Type/Medium Pickups/mazda_containerized.png";
import heavy from "../../../assets/Vehicle_Type/Heavy Pickups/40ft_containerized.png";
import suzuki_c from "../../../assets/Vehicle_Type/Small Pickups/suzuki_closed.png";
import suzuki_o from "../../../assets/Vehicle_Type/Small Pickups/suzuki_open.png";
import shahzor_o from "../../../assets/Vehicle_Type/Medium Pickups/shahzor_open.png";
import shahzor_c from "../../../assets/Vehicle_Type/Medium Pickups/shahzor_closed.png";
import mazda_o from "../../../assets/Vehicle_Type/Medium Pickups/mazda_open.png";
import mazda_fl from "../../../assets/Vehicle_Type/Medium Pickups/mazda_flatbed.png";
import mazda_con from "../../../assets/Vehicle_Type/Medium Pickups/mazda_containerized.png";
import mazda_c_20 from "../../../assets/Vehicle_Type/Medium Pickups/mazda_commercial_20ft.png";
import mazda_c_16 from "../../../assets/Vehicle_Type/Medium Pickups/mazda_commercial_16ft.png";
import heavy_c_20 from "../../../assets/Vehicle_Type/Heavy Pickups/20ft_containerized.png";
import heavy_fb_20 from "../../../assets/Vehicle_Type/Heavy Pickups/20ft_flat_body.png";
import heavy_hf_20 from "../../../assets/Vehicle_Type/Heavy Pickups/20ft_halfbed.png";
import heavy_c_40 from "../../../assets/Vehicle_Type/Heavy Pickups/40ft_containerized.png";
import heavy_fb_40 from "../../../assets/Vehicle_Type/Heavy Pickups/40ft_flatbed.png";
import heavy_hf_40 from "../../../assets/Vehicle_Type/Heavy Pickups/40ft_half_body.png";
import Language from "../../Language/Language";
import PreLoader from "../../PreLoader/PreLoader";
import api from "../../../library/api";
import {
  clients_url,
  driver_url,
  company_url,
  partner_url,
  contractor_url,
} from "../../../library/Url";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RegisterTruck = () => {
  /* VARIABLES */
  const history = useHistory();
  /* STATES */
  const [data, setdata] = useState("");
  const [imgurl, setimgurl] = useState("");
  const [vehile_type, setvehile_type] = useState("");
  const [sub_type, setsub_type] = useState("");
  const [MultipleImageFile, setMultipleImageFile] = useState([]);
  const [MultiImgPreview, setMultipleImgPreview] = useState([]);
  const [MultipleImageCardFile, setMultipleImageCardFile] = useState([]);
  const [MultiImgCardPreview, setMultipleImgCardPreview] = useState([]);
  const [language, setlanguage] = useState("");
  const [pageContent, setPageContent] = useState({});
  const [vehicle_registration_number, SetReNum] = useState("");
  const [vehicle_registration_company, SetReCom] = useState("");
  const [vehicle_registration_model, SetReMod] = useState("");
  const [vehicle_manufacturing_year, SetManYear] = useState("");

  /* HOOKS */
  useEffect(() => {
    const lang = localStorage.getItem("language");
    setlanguage(lang);
  }, []);

  useEffect(() => {
    if (language === "En") {
      setPageContent({
        main_heading: "Vehicle Registration Form",
        lab_1st: "Vehicle Registration Number",
        lab_2nd: "Vehicle Manufacturing Company",
        lab_3rd: "Vehicle Model",
        lab_4th: "Vehicle Manufacturing Year",
        place_1st: "Vehicle Registration Number",
        place_2nd: "Vehicle Manufacturing Company",
        place_3rd: "Vehicle Model",
        place_4th: "Vehicle Manufacturing Year",
        lab_upload_img: "Upload Images Of Vehicle",
        lab_upload_reg_img: "Upload Vehicle Registration Images",
        lab_dd1: "Select Vehicle Type",
        dd1: "None",
        dd2: "Small Vehicle",
        dd3: "Medium Vehicle",
        dd4: "Heavy Vehicle",
        lab_dd2: "Select Vehicle Sub Type",
        dd2_s_1: "None",
        dd2_s_2: "Suzuki Closed",
        dd2_s_3: "Suzuki Open",
        dd2_m_1: "None",
        dd2_m_2: "Shahzor Open",
        dd2_m_3: "Shahzor Closed",
        dd2_m_4: "Mazda Open",
        dd2_m_5: "Mazda Flatbed",
        dd2_m_6: "Mazda Containerized",
        dd2_m_7: "Mazda Commercial 16ft",
        dd2_m_8: "Mazda Commercial 20ft",
        dd2_h_1: "None",
        dd2_h_2: "20ft Containerized",
        dd2_h_3: "20ft Flatbody",
        dd2_h_4: "20ft Halfbody",
        dd2_h_5: "40ft Containerized",
        dd2_h_6: "40ft Flatbody",
        dd2_h_7: "40ft Halfbody",
        btn_sb: "Register Now",
        search_btn: "Search Orders",
        track_btn: "Track Vehicle",
      });
    } else if (language === "Ur") {
      setPageContent({
        main_heading: "گاڑی کا رجسٹریشن فارم",
        lab_1st: "گاڑی کا رجسٹریشن نمبر",
        lab_2nd: "گاڑیاں بنانے والی کمپنی کا نام",
        lab_3rd: "گاڑی کا ماڈل",
        lab_4th: "گاڑی کی تیاری کا سال",
        place_1st: "گاڑی کا رجسٹریشن نمبر",
        place_2nd: "گاڑیاں بنانے والی کمپنی کا نام",
        place_3rd: "گاڑی کا ماڈل",
        place_4th: "گاڑی کی تیاری کا سال",
        lab_upload_img: "گاڑی کی تصاویر اپ لوڈ کریں",
        lab_upload_reg_img: "گاڑیوں کی رجسٹریشن کی تصاویر اپ لوڈ کریں",
        lab_dd1: "گاڑی کی قسم منتخب کریں",
        dd1: "کوئی نہیں",
        dd2: "چھوٹی گاڑی",
        dd3: "درمیانی گاڑی",
        dd4: "بڑی گاڑی",
        lab_dd2: "گاڑی کی ذیلی قسم کو منتخب کریں",
        dd2_s_1: "کوئی نہیں",
        dd2_s_2: "سوزوکی بند",
        dd2_s_3: "سوزوکی اوپن",
        dd2_m_1: "کوئی نہیں",
        dd2_m_2: "شہزور اوپن",
        dd2_m_3: "شہزور بند",
        dd2_m_4: "مزدا اوپن",
        dd2_m_5: "مزدا فلیٹ بیڈ",
        dd2_m_6: "مزدا کنٹینرائزڈ",
        dd2_m_7: "مزدا کمرشل 16 فٹ",
        dd2_m_8: "مزدا کمرشل 20 فٹ",
        dd2_h_1: "کوئی نہیں",
        dd2_h_2: "20کنٹینرائزڈفٹ",
        dd2_h_3: "20 فلیٹ باڈی فٹ",
        dd2_h_4: "20 ہاف باڈی فٹ",
        dd2_h_5: "40کنٹینرائزڈفٹ",
        dd2_h_6: "40 فلیٹ باڈی فٹ",
        dd2_h_7: "40 ہاف باڈی فٹ",
        btn_sb: "ابھی رجسٹر کریں",
        search_btn: "آرڈرز تلاش کریں",
        track_btn: "گاڑیوں کو ٹریک کریں",
      });
    }
  }, [language]);

  useEffect(() => {
    api.get("userinfo").then((response) => {
      setdata(response.data.data.user);
      if (response.data.data.user.user_is === "client") {
        setimgurl(clients_url);
      } else if (response.data.data.user.user_is === "company") {
        setimgurl(company_url);
      } else if (response.data.data.user.user_is === "contractor") {
        setimgurl(contractor_url);
      } else if (response.data.data.user.user_is === "driver") {
        setimgurl(driver_url);
      } else if (response.data.data.user.user_is === "partner") {
        setimgurl(partner_url);
      }
    });
  }, []);

  /* FUNCTIONS */
  const handleRegister = (e) => {
    e.preventDefault();
    if (vehicle_registration_number === "") {
      toast.error("Vehicle Registration Number Required");
    } else if (vehicle_registration_company === "") {
      toast.error("Vehicle Registration Company Required");
    } else if (vehicle_registration_model === "") {
      toast.error("Vehicle Registration Model Required");
    } else if (vehicle_manufacturing_year === "") {
      toast.error("Vehicle Manufacturing Year Required");
    } else if (MultipleImageFile.length === 0) {
      toast.error("Vehicle Images Required");
    } else if (MultipleImageCardFile.length === 0) {
      toast.error("Vehicle Registration Images Required");
    } else if (vehile_type === "") {
      toast.error("Vehicle Type Required");
    } else if (sub_type === "") {
      toast.error("Vehicle Sub Type Empty");
    } else {
      const payload = {
        registration_number: vehicle_registration_number,
        manufacture_company: vehicle_registration_company,
        vehicle_model: vehicle_registration_model,
        vehicle_manufacture_year: vehicle_manufacturing_year,
        type: vehile_type.toLowerCase(),
        sub_type: sub_type.toLowerCase(),
        vehicle_gallery: MultipleImageFile,
        vehicle_gallery_registration: MultipleImageCardFile,
      };
      api.post("/vehicle/store", payload).then((response) => {
        if(response.status === 200){
          history.push('/user/dashboard');
        }
      }).catch((err) => {
        toast.error("Registration Number Is Already Taken");
      });
    }
  };

  const handleSubType = (e) => {
    setsub_type(e.target.value);
  };
  const handleType = (e) => {
    setvehile_type(e.target.value);
  };
  // image to base64
  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      // Setting Complete Image in state
      setMultipleImageFile((MultipleImageFile) => [
        ...MultipleImageFile,
        reader.result,
      ]);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  // Multiple Image Function
  const onMultipleChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      // Setting Only The Preview URL For image Preview
      setMultipleImgPreview((MultiImg) => [
        ...MultiImg,
        URL.createObjectURL(e.target.files[0]),
      ]);

      // Image To Base64
      const multiimg = e.target.files[0];

      getBase64(multiimg);
    }
  };
  // image to base64
  const getBaseCard64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      // Setting Complete Image in state
      setMultipleImageCardFile((MultipleImageFile) => [
        ...MultipleImageFile,
        reader.result,
      ]);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  // Multiple Image Function
  const onMultipleChangeCard = (e) => {
    if (e.target.files && e.target.files[0]) {
      // Setting Only The Preview URL For image Preview
      setMultipleImgCardPreview((MultiImg) => [
        ...MultiImg,
        URL.createObjectURL(e.target.files[0]),
      ]);

      // Image To Base64
      const multiimg = e.target.files[0];

      getBaseCard64(multiimg);
    }
  };

  return (
    <>
      {data === "" ? (
        <PreLoader text="Loading..." />
      ) : (
        <div
          className="container"
          style={{ position: "relative", minHeight: "100vh" }}
        >
          <div className="row main-div-dashboard">
            <span className="lan-dropdown" style={{ zIndex: "200" }}>
              <Language value={setlanguage} curr={language} />
            </span>
            <div className="col-12" style={{ zIndex: "20" }}>
              <span className="back-btn">
                <IoIcons.IoIosArrowRoundBack
                  fill="#fff"
                  style={{ width: "89px", height: "60px" }}
                  onClick={() => history.push("/user/dashboard")}
                />
              </span>
              <HiIcons.HiDotsVertical
                fill="#fff"
                style={{ height: "27px", width: "70px" }}
                className="menu-btn"
              />
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-12 text-center">
                  <img
                    src={`${imgurl}${data.picture}`}
                    alt=""
                    className="dash-img"
                  />
                  <div className="">
                    <span className="h6 font-weight-bold text-white">
                      {data.name}
                    </span>
                    <br />
                    <br />
                    <h4 className="text-white">
                      <b>{pageContent.main_heading}</b>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {language === "En" ? (
            <>
              <div className="row">
                <div className="col-12 mt-3 text-center">
                  <p className="mt-3 text-left" style={{ paddingLeft: "22px" }}>
                    <b className="mt-4">{pageContent.place_1st}</b> <br />
                  </p>
                  <input
                    type="text"
                    placeholder="Vehicle Registration Number"
                    className="truck-login-input"
                    value={vehicle_registration_number}
                    onChange={(e) => SetReNum(e.target.value)}
                  />
                  <p className="text-left" style={{ paddingLeft: "22px" }}>
                    <b className="mt-4">{pageContent.place_2nd}</b> <br />
                  </p>
                  <input
                    type="text"
                    placeholder="Vehicle Manufacturing Comapny"
                    className="truck-login-input"
                    value={vehicle_registration_company}
                    onChange={(e) => SetReCom(e.target.value)}
                  />
                  <p className="text-left" style={{ paddingLeft: "22px" }}>
                    <b className="mt-4">{pageContent.place_3rd}</b> <br />
                  </p>
                  <input
                    type="text"
                    placeholder="Vehicle Model"
                    className="truck-login-input"
                    value={vehicle_registration_model}
                    onChange={(e) => SetReMod(e.target.value)}
                  />
                  <p className="text-left" style={{ paddingLeft: "22px" }}>
                    <b className="mt-4">{pageContent.place_4th}</b> <br />
                  </p>
                  <input
                    type="text"
                    placeholder="Vehicle Manufacturing Year"
                    className="truck-login-input"
                    value={vehicle_manufacturing_year}
                    onChange={(e) => SetManYear(e.target.value)}
                  />
                  <br />
                  <p className="text-left" style={{ paddingLeft: "22px" }}>
                    <b className="mt-4">{pageContent.lab_upload_img}</b> <br />
                  </p>
                  <div className="multiple-image-container text-left pl-3">
                    {MultiImgPreview.map(function (img, index) {
                      return (
                        <div className="main-content-img" key={index}>
                          <img
                            key={index}
                            id="target"
                            src={img}
                            className="multiple-company-image"
                            alt="lmage"
                          />
                        </div>
                      );
                    })}
                    {/* <div className=""> */}
                    <label
                      htmlFor="filePickermultiple"
                      style={{ width: "100px" }}
                    >
                      <img
                        src={upload}
                        alt="Preview"
                        className="upload-img-btn"
                      />
                    </label>
                    <input
                      id="filePickermultiple"
                      accept="image/png, image/gif, image/jpeg"
                      style={{ visibility: "hidden" }}
                      type={"file"}
                      onChange={onMultipleChange}
                      className="image-input-btn"
                      // multiple={true}
                    />
                    {/* </div> */}
                  </div>

                  <p className="text-left" style={{ paddingLeft: "22px" }}>
                    <b className="mt-4">{pageContent.lab_upload_reg_img}</b>{" "}
                    <br />
                  </p>
                  <div className="multiple-image-container text-left pl-3">
                    {MultiImgCardPreview.map(function (img, index) {
                      return (
                        <div className="main-content-img" key={index}>
                          <img
                            key={index}
                            id="target"
                            src={img}
                            className="multiple-company-image"
                            alt="lmage"
                          />
                        </div>
                      );
                    })}
                    {/* <div className=""> */}
                    <label
                      htmlFor="filePickermultiplecard"
                      style={{ width: "100px" }}
                    >
                      <img
                        src={upload}
                        alt="Preview"
                        className="upload-img-btn"
                      />
                    </label>
                    <input
                      id="filePickermultiplecard"
                      accept="image/png, image/gif, image/jpeg"
                      style={{ visibility: "hidden" }}
                      type={"file"}
                      onChange={onMultipleChangeCard}
                      className="image-input-btn"
                      // multiple={true}
                    />
                    {/* </div> */}
                  </div>

                  <MuiThemeProvider>
                    <React.Fragment>
                      <div className="row text-center">
                        <div className="col-12">
                          <p
                            className="text-left"
                            style={{ paddingLeft: "22px" }}
                          >
                            <b className="mt-4">{pageContent.lab_dd1}</b> <br />
                          </p>
                        </div>
                        <div className="col-12">
                          <br />
                          <Select
                            labelId="demo-simple-select-placeholder-label-label"
                            id="demo-simple-select-placeholder-label"
                            value={vehile_type}
                            onChange={handleType}
                            displayEmpty
                            className="select-option"
                            style={{ width: "90%", paddingTop: "-30px" }}
                          >
                            <MenuItem value="">
                              <em>None</em>{" "}
                            </MenuItem>
                            <MenuItem value="Small">
                              <span>
                                <img
                                  src={small}
                                  width="25"
                                  height="20"
                                  alt=""
                                />{" "}
                                Small Vehicle
                              </span>
                            </MenuItem>
                            <MenuItem value="Medium">
                              <span>
                                <img
                                  src={medium}
                                  width="25"
                                  height="20"
                                  alt=""
                                />{" "}
                                Medium Vehicle
                              </span>
                            </MenuItem>
                            <MenuItem value="Heavy">
                              <span>
                                <img
                                  src={heavy}
                                  width="25"
                                  height="20"
                                  alt=""
                                />{" "}
                                Heavy Vehicle
                              </span>
                            </MenuItem>
                          </Select>
                          {vehile_type === "" ? (
                            <></>
                          ) : vehile_type === "Small" ? (
                            <div className="mt-3">
                              <div className="text-start mt-1">
                                <p
                                  className="text-left"
                                  style={{ paddingLeft: "22px" }}
                                >
                                  <b className="mt-4">{pageContent.lab_dd2}</b>{" "}
                                  <br />
                                </p>
                              </div>
                              <div className="mt-1">
                                <Select
                                  labelId="demo-simple-select-placeholder-label-label"
                                  id="demo-simple-select-placeholder-label"
                                  value={sub_type}
                                  onChange={handleSubType}
                                  displayEmpty
                                  className="select-option mt-3"
                                  style={{ width: "90%" }}
                                >
                                  <MenuItem value="">
                                    <em>None</em>{" "}
                                  </MenuItem>
                                  <MenuItem value="Suzuki Closed">
                                    <span>
                                      <img
                                        src={suzuki_c}
                                        width="25"
                                        height="20"
                                        alt=""
                                      />{" "}
                                      Suzuki Closed
                                    </span>
                                  </MenuItem>
                                  <MenuItem value="Suzuki Open">
                                    <span>
                                      <img
                                        src={suzuki_o}
                                        width="25"
                                        height="20"
                                        alt=""
                                      />{" "}
                                      Suzuki Open
                                    </span>
                                  </MenuItem>
                                </Select>
                              </div>
                            </div>
                          ) : vehile_type === "Medium" ? (
                            <div className="mt-3">
                              <div className="text-start mt-1">
                                <b>Select Vehicle Sub Type</b>
                              </div>
                              <div className="mt-1">
                                <Select
                                  labelId="demo-simple-select-placeholder-label-label"
                                  id="demo-simple-select-placeholder-label"
                                  value={sub_type}
                                  onChange={handleSubType}
                                  displayEmpty
                                  className="select-option"
                                  style={{ width: "90%" }}
                                >
                                  <MenuItem value="">
                                    <em>None</em>{" "}
                                  </MenuItem>
                                  <MenuItem value="Shahzor Open">
                                    <span>
                                      <img
                                        src={shahzor_o}
                                        width="25"
                                        height="20"
                                        alt=""
                                      />{" "}
                                      Shahzor Open
                                    </span>
                                  </MenuItem>
                                  <MenuItem value="Shahzor Closed">
                                    <span>
                                      <img
                                        src={shahzor_c}
                                        width="25"
                                        height="20"
                                        alt=""
                                      />{" "}
                                      Shahzor Closed
                                    </span>
                                  </MenuItem>
                                  <MenuItem value="Mazda Open">
                                    <span>
                                      <img
                                        src={mazda_o}
                                        width="25"
                                        height="20"
                                        alt=""
                                      />{" "}
                                      Mazda Open
                                    </span>
                                  </MenuItem>
                                  <MenuItem value="Mazda Flatbed">
                                    <span>
                                      <img
                                        src={mazda_fl}
                                        width="25"
                                        height="20"
                                        alt=""
                                      />{" "}
                                      Mazda Flatbed
                                    </span>
                                  </MenuItem>
                                  <MenuItem value="Mazda Containerized">
                                    <span>
                                      <img
                                        src={mazda_con}
                                        width="25"
                                        height="20"
                                        alt=""
                                      />{" "}
                                      Mazda Containerized
                                    </span>
                                  </MenuItem>
                                  <MenuItem value="Mazda Commercial 16ft">
                                    <span>
                                      <img
                                        src={mazda_c_16}
                                        width="25"
                                        height="20"
                                        alt=""
                                      />{" "}
                                      Mazda Commercial 16ft
                                    </span>
                                  </MenuItem>
                                  <MenuItem value="Mazda Commercial 20ft">
                                    <span>
                                      <img
                                        src={mazda_c_20}
                                        width="25"
                                        height="20"
                                        alt=""
                                      />{" "}
                                      Mazda Commercial 20ft
                                    </span>
                                  </MenuItem>
                                </Select>
                              </div>
                            </div>
                          ) : vehile_type === "Heavy" ? (
                            <div className="mt-3">
                              <div className="text-start mt-1">
                                <b>Select Vehicle Sub Type</b>
                              </div>
                              <div className="mt-1">
                                <Select
                                  labelId="demo-simple-select-placeholder-label-label"
                                  id="demo-simple-select-placeholder-label"
                                  // value={vehile_type}
                                  // onChange={handleType}
                                  value={sub_type}
                                  onChange={handleSubType}
                                  displayEmpty
                                  className="select-option"
                                  style={{ width: "90%" }}
                                >
                                  <MenuItem value="">
                                    <em>None</em>{" "}
                                  </MenuItem>
                                  <MenuItem value="20ft Containerized">
                                    <span>
                                      <img
                                        src={heavy_c_20}
                                        width="25"
                                        height="20"
                                        alt=""
                                      />{" "}
                                      20ft Containerized
                                    </span>
                                  </MenuItem>
                                  <MenuItem value="20ft Flatbody">
                                    <span>
                                      <img
                                        src={heavy_fb_20}
                                        width="25"
                                        height="20"
                                        alt=""
                                      />{" "}
                                      20ft Flatbody
                                    </span>
                                  </MenuItem>
                                  <MenuItem value="20ft Halfbed">
                                    <span>
                                      <img
                                        src={heavy_hf_20}
                                        width="25"
                                        height="20"
                                        alt=""
                                      />{" "}
                                      20ft Halfbed
                                    </span>
                                  </MenuItem>
                                  <MenuItem value="40ft Containerized">
                                    <span>
                                      <img
                                        src={heavy_c_40}
                                        width="25"
                                        height="20"
                                        alt=""
                                      />{" "}
                                      40ft Containerized
                                    </span>
                                  </MenuItem>
                                  <MenuItem value="40ft Flatbody">
                                    <span>
                                      <img
                                        src={heavy_fb_40}
                                        width="25"
                                        height="20"
                                        alt=""
                                      />{" "}
                                      40ft Flatbody
                                    </span>
                                  </MenuItem>
                                  <MenuItem value="40ft Halfbed">
                                    <span>
                                      <img
                                        src={heavy_hf_40}
                                        width="25"
                                        height="20"
                                        alt=""
                                      />{" "}
                                      40ft Halfbed
                                    </span>
                                  </MenuItem>
                                </Select>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  </MuiThemeProvider>
                  <div className="text-center mt-3">
                    <button
                      className="mt-3 truck-order-now-btn"
                      onClick={handleRegister}
                    >
                      Register Now
                    </button>
                  </div>
                  <div
                    style={{ height: "100px", border: "2px solid white" }}
                  ></div>
                  <div className="row">
                    <div className="col-12">
                      <div className="card bottom-search-car">
                        <div className="row mt-3">
                          <div
                            className="col-7 text-left"
                            style={{ marginLeft: "8px" }}
                            onClick={() => {
                              history.push("/user/offer");
                            }}
                          >
                            <img
                              src={search}
                              alt=""
                              style={{ height: "27px", width: "28px" }}
                            />
                            <span
                              style={{ fontSize: "13px", marginLeft: "10px" }}
                            >
                              Search Orders
                            </span>
                          </div>
                          <div
                            className="col-5"
                            style={{ textAlign: "end", marginLeft: "-18px" }}
                            onClick={() => history.push("/user/tracking/")}
                          >
                            <img
                              src={car}
                              alt=""
                              style={{ height: "27px", width: "28px" }}
                            />
                            <span
                              style={{
                                fontSize: "13px",
                                marginLeft: "8px",
                                marginRight: "8px",
                              }}
                            >
                              Track Vehicles
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="col-12 mt-3 text-center">
                  <p className="text-right pr-4 mt-3">
                    <b className="mt-4">{pageContent.place_1st}</b> <br />
                  </p>
                  <input
                    type="text"
                    placeholder={pageContent.place_1st}
                    className="truck-login-input"
                    dir="rtl"
                  />
                  <p className="text-right pr-4">
                    <b className="mt-4">{pageContent.place_2nd}</b> <br />
                  </p>
                  <input
                    type="text"
                    placeholder={pageContent.place_2nd}
                    className="truck-login-input"
                    dir="rtl"
                  />
                  <p className="text-right pr-4">
                    <b className="mt-4">{pageContent.place_3rd}</b> <br />
                  </p>
                  <input
                    type="text"
                    placeholder={pageContent.place_3rd}
                    className="truck-login-input"
                    dir="rtl"
                  />
                  <p className="text-right pr-4">
                    <b className="mt-4">{pageContent.place_4th}</b> <br />
                  </p>
                  <input
                    type="text"
                    placeholder={pageContent.place_4th}
                    className="truck-login-input"
                    dir="rtl"
                  />
                  <br />
                  <p className="text-right pr-4">
                    <b className="mt-4">{pageContent.lab_upload_img}</b> <br />
                  </p>
                  <div className="multiple-image-container text-left">
                    {MultiImgPreview.map(function (img, index) {
                      return (
                        <div className="main-content-img" key={index}>
                          <img
                            key={index}
                            id="target"
                            src={img}
                            className="multiple-company-image"
                            alt="lmage"
                          />
                        </div>
                      );
                    })}
                    {/* <div className=""> */}
                    <label htmlFor="filePickermultiple">
                      <img
                        src={upload}
                        alt="Preview"
                        className="upload-img-btn"
                      />
                    </label>
                    <input
                      id="filePickermultiple"
                      accept="image/png, image/gif, image/jpeg"
                      style={{ visibility: "hidden" }}
                      type={"file"}
                      onChange={onMultipleChange}
                      className="image-input-btn"
                      // multiple={true}
                    />
                    {/* </div> */}
                  </div>
                  <p className="text-right pr-4">
                    <b>{pageContent.lab_upload_reg_img}</b>
                  </p>
                  <div className="multiple-image-container text-left">
                    {MultiImgCardPreview.map(function (img, index) {
                      return (
                        <div className="main-content-img" key={index}>
                          <img
                            key={index}
                            id="target"
                            src={img}
                            className="multiple-company-image"
                            alt="lmage"
                          />
                        </div>
                      );
                    })}
                    {/* <div className=""> */}
                    <label htmlFor="filePickermultiplecard">
                      <img
                        src={upload}
                        alt="Preview"
                        className="upload-img-btn"
                        // style={{border:'2px solid red'}}
                      />
                    </label>
                    <input
                      id="filePickermultiplecard"
                      accept="image/png, image/gif, image/jpeg"
                      style={{ visibility: "hidden" }}
                      type={"file"}
                      onChange={onMultipleChangeCard}
                      className="image-input-btn"
                      // multiple={true}
                    />
                    {/* </div> */}
                  </div>

                  <MuiThemeProvider>
                    <React.Fragment>
                      <div className="row text-center">
                        <div className="col-12">
                          <p className="text-right pr-4">
                            <b className="mt-4">{pageContent.lab_dd1}</b> <br />
                          </p>
                        </div>
                        <div className="col-12">
                          <br />
                          <Select
                            labelId="demo-simple-select-placeholder-label-label"
                            id="demo-simple-select-placeholder-label"
                            value={vehile_type}
                            onChange={handleType}
                            displayEmpty
                            className="select-option"
                            style={{ width: "90%" }}
                          >
                            <MenuItem value="">
                              <em>{pageContent.dd1}</em>{" "}
                            </MenuItem>
                            <MenuItem value="Small">
                              <span>
                                <img
                                  src={small}
                                  width="25"
                                  height="20"
                                  alt=""
                                />{" "}
                                {pageContent.dd2}
                              </span>
                            </MenuItem>
                            <MenuItem value="Medium">
                              <span>
                                <img
                                  src={medium}
                                  width="25"
                                  height="20"
                                  alt=""
                                />{" "}
                                {pageContent.dd3}
                              </span>
                            </MenuItem>
                            <MenuItem value="Heavy">
                              <span>
                                <img
                                  src={heavy}
                                  width="25"
                                  height="20"
                                  alt=""
                                />{" "}
                                {pageContent.dd4}
                              </span>
                            </MenuItem>
                          </Select>
                          {vehile_type === "" ? (
                            <></>
                          ) : vehile_type === "Small" ? (
                            <div className="mt-3">
                              <div className="text-start mt-1">
                                <p className="text-right pr-4">
                                  <b className="mt-4">{pageContent.lab_dd2}</b>{" "}
                                  <br />
                                </p>
                              </div>
                              <div className="mt-1">
                                <Select
                                  labelId="demo-simple-select-placeholder-label-label"
                                  id="demo-simple-select-placeholder-label"
                                  // value={vehile_type}
                                  // onChange={handleType}
                                  displayEmpty
                                  className="select-option"
                                  style={{ width: "90%" }}
                                >
                                  <MenuItem value="">
                                    <em>{pageContent.dd2_s_1}</em>{" "}
                                  </MenuItem>
                                  <MenuItem value="Suzuki Closed">
                                    <span>
                                      <img
                                        src={suzuki_c}
                                        width="25"
                                        height="20"
                                        alt=""
                                      />{" "}
                                      {pageContent.dd2_s_3}
                                    </span>
                                  </MenuItem>
                                  <MenuItem value="Suzuki Open">
                                    <span>
                                      <img
                                        src={suzuki_o}
                                        width="25"
                                        height="20"
                                        alt=""
                                      />{" "}
                                      {pageContent.dd2_s_3}
                                    </span>
                                  </MenuItem>
                                </Select>
                              </div>
                            </div>
                          ) : vehile_type === "Medium" ? (
                            <div className="mt-3">
                              <div className="text-start mt-1">
                              <p className="text-right pr-4">
                                  <b className="mt-4">{pageContent.lab_dd2}</b>{" "}
                                  <br />
                                </p>
                              </div>
                              <div className="mt-1">
                                <Select
                                  labelId="demo-simple-select-placeholder-label-label"
                                  id="demo-simple-select-placeholder-label"
                                  // value={vehile_type}
                                  // onChange={handleType}
                                  displayEmpty
                                  className="select-option"
                                  style={{ width: "90%" }}
                                >
                                  <MenuItem value="">
                                    <em>{pageContent.dd2_m_1}</em>{" "}
                                  </MenuItem>
                                  <MenuItem value="Shahzor Open">
                                    <span>
                                      <img
                                        src={shahzor_o}
                                        width="25"
                                        height="20"
                                        alt=""
                                      />{" "}
                                      {pageContent.dd2_m_2}
                                    </span>
                                  </MenuItem>
                                  <MenuItem value="Shahzor Closed">
                                    <span>
                                      <img
                                        src={shahzor_c}
                                        width="25"
                                        height="20"
                                        alt=""
                                      />{" "}
                                      {pageContent.dd2_m_3}
                                    </span>
                                  </MenuItem>
                                  <MenuItem value="Mazda Open">
                                    <span>
                                      <img
                                        src={mazda_o}
                                        width="25"
                                        height="20"
                                        alt=""
                                      />{" "}
                                      {pageContent.dd2_m_4}
                                    </span>
                                  </MenuItem>
                                  <MenuItem value="Mazda Flatbed">
                                    <span>
                                      <img
                                        src={mazda_fl}
                                        width="25"
                                        height="20"
                                        alt=""
                                      />{" "}
                                      {pageContent.dd2_m_5}
                                    </span>
                                  </MenuItem>
                                  <MenuItem value="Mazda Containerized">
                                    <span>
                                      <img
                                        src={mazda_con}
                                        width="25"
                                        height="20"
                                        alt=""
                                      />{" "}
                                      {pageContent.dd2_m_6}
                                    </span>
                                  </MenuItem>
                                  <MenuItem value="Mazda Commercial 16ft">
                                    <span>
                                      <img
                                        src={mazda_c_16}
                                        width="25"
                                        height="20"
                                        alt=""
                                      />{" "}
                                      {pageContent.dd2_m_7}
                                    </span>
                                  </MenuItem>
                                  <MenuItem value="Mazda Commercial 20ft">
                                    <span>
                                      <img
                                        src={mazda_c_20}
                                        width="25"
                                        height="20"
                                        alt=""
                                      />{" "}
                                      {pageContent.dd2_m_8}
                                    </span>
                                  </MenuItem>
                                </Select>
                              </div>
                            </div>
                          ) : vehile_type === "Heavy" ? (
                            <div className="mt-3">
                              <div className="text-start mt-1">
                              <p className="text-right pr-4">
                                  <b className="mt-4">{pageContent.lab_dd2}</b>{" "}
                                  <br />
                                </p>
                              </div>
                              <div className="mt-1">
                                <Select
                                  labelId="demo-simple-select-placeholder-label-label"
                                  id="demo-simple-select-placeholder-label"
                                  // value={vehile_type}
                                  // onChange={handleType}
                                  displayEmpty
                                  className="select-option"
                                  style={{ width: "90%" }}
                                >
                                  <MenuItem value="">
                                    <em>{pageContent.dd2_h_1}</em>{" "}
                                  </MenuItem>
                                  <MenuItem value="20ft Containerized">
                                    <span>
                                      <img
                                        src={heavy_c_20}
                                        width="25"
                                        height="20"
                                        alt=""
                                      />{" "}
                                      {pageContent.dd2_h_2}
                                    </span>
                                  </MenuItem>
                                  <MenuItem value="20ft Flatbody">
                                    <span>
                                      <img
                                        src={heavy_fb_20}
                                        width="25"
                                        height="20"
                                        alt=""
                                      />{" "}
                                      {pageContent.dd2_h_3}
                                    </span>
                                  </MenuItem>
                                  <MenuItem value="20ft Halfbed">
                                    <span>
                                      <img
                                        src={heavy_hf_20}
                                        width="25"
                                        height="20"
                                        alt=""
                                      />{" "}
                                      {pageContent.dd2_h_4}
                                    </span>
                                  </MenuItem>
                                  <MenuItem value="40ft Containerized">
                                    <span>
                                      <img
                                        src={heavy_c_40}
                                        width="25"
                                        height="20"
                                        alt=""
                                      />{" "}
                                      {pageContent.dd2_h_5}
                                    </span>
                                  </MenuItem>
                                  <MenuItem value="40ft Flatbody">
                                    <span>
                                      <img
                                        src={heavy_fb_40}
                                        width="25"
                                        height="20"
                                        alt=""
                                      />{" "}
                                      {pageContent.dd2_h_6}
                                    </span>
                                  </MenuItem>
                                  <MenuItem value="40ft Halfbed">
                                    <span>
                                      <img
                                        src={heavy_hf_40}
                                        width="25"
                                        height="20"
                                        alt=""
                                      />{" "}
                                      {pageContent.dd2_h_7}
                                    </span>
                                  </MenuItem>
                                </Select>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  </MuiThemeProvider>
                  <div className="text-center mt-3">
                    <button
                      className="mt-3 truck-order-now-btn"
                      //   onClick={handleOrder}
                    >
                      {pageContent.btn_sb}
                    </button>
                  </div>
                  <div
                    style={{ height: "100px", border: "2px solid white" }}
                  ></div>
                  <div className="row">
                    <div className="col-12">
                      <div className="card bottom-search-car">
                        <div className="row mt-3">
                          <div
                            className="col-7 text-left"
                            style={{ marginLeft: "8px" }}
                            onClick={() => {
                              history.push("/user/offer");
                            }}
                          >
                            <img
                              src={search}
                              alt=""
                              style={{ height: "27px", width: "28px" }}
                            />
                            <span
                              style={{ fontSize: "13px", marginLeft: "10px" }}
                            >
                              {pageContent.search_btn}
                            </span>
                          </div>
                          <div
                            className="col-5"
                            style={{ textAlign: "end", marginLeft: "-18px" }}
                            onClick={() => history.push("/user/tracking/")}
                          >
                            <img
                              src={car}
                              alt=""
                              style={{ height: "27px", width: "28px" }}
                            />
                            <span
                              style={{
                                fontSize: "13px",
                                marginLeft: "8px",
                                marginRight: "8px",
                              }}
                            >
                              {pageContent.track_btn}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default RegisterTruck;
